import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/layout";
import HomeView from "@/views/home/index.vue";
import Operation from "@/views/operation/index.vue"; // 新窗口打开
import Course from "@/views/course/index.vue";
import Share from "@/views/share/index.vue";
import axios from "@/service/axios";
import CourseList from "@/views/course/list.vue";
import CourseDetail from "@/views/course/detail.vue";
import OperationDetail from "@/views/operation/detail.vue";
import store from "@/store";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
    component: Layout,
    children: [
      {
        path: "/home",
        name: "home",
        component: HomeView,
        requiresAuth: false,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/classification",
        name: "classification",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/classification/index.vue"),
      },
      {
        path: "/Personal",
        name: "personal",
        component: () => import("@/views/personal/index.vue"),
        children: [
          {
            path: "info",
            name: "info",
            meta: {
              requiresAuth: true,
            },
            component: () => import("@/views/personal/info.vue"),
          },
          {
            path: "share",
            name: "share",
            meta: {
              requiresAuth: true,
            },
            component: () => import("@/views/personal/share.vue"),
          },
          {
            path: "collection",
            name: "collection",
            meta: {
              requiresAuth: true,
            },
            component: () => import("@/views/personal/collection.vue"),
          },
          {
            path: "editRecords",
            name: "editRecords",
            meta: {
              requiresAuth: true,
            },
            component: () => import("@/views/personal/editRecords.vue"),
          },
        ],
      },
      {
        path: "/course",
        name: "course",
        component: Course,
        meta: {
          requiresAuth: false,
        },
        children: [
          {
            path: "list",
            name: "courseList",
            meta: {
              requiresAuth: false,
            },
            component: CourseList,
          },
          {
            path: "detail",
            name: "courseDetail",
            meta: {
              requiresAuth: false,
            },
            component: CourseDetail,
          },
        ],
      },
      {
        path: "/operation",
        name: "operation",
        meta: {
          requiresAuth: true,
        },
        // component: () => import("@/views/operation/index.vue"),
        component: Operation,
      },

      {
        path: "/operation/detail",
        name: "operationDetail",
        meta: {
          requiresAuth: false,
        },
        // component: () => import("@/views/operation/detail.vue"),
        component: OperationDetail,
      },
      {
        path: "/search",
        name: "search",
        meta: {
          requiresAuth: true,
          keepAlive:true,
        },
        component: () => import("@/views/search/index.vue"),
      },
      {
        path: "/share",
        name: "share",
        meta: {
          requiresAuth: false,

        },
        component: Share,
      },
    ],
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  if (to.query.token) {
    localStorage.setItem("token", to.query.token);
    const res = await axios.post("/admin/login", {}, true);
    localStorage.setItem("token", res.data.token);
    localStorage.setItem("showWindow", 1);
    localStorage.setItem("userInfo", JSON.stringify(res.data.user));
    try {
      const res = await axios.get('/user/getLoginUser')
      store.commit('updateUserInfo', res.data)
    } catch (err) {
      console.log(err)
    }
    store.commit("onShowLogindialog", false);
  }
  console.log(to);
  // 如果即将进入登录路由，则直接放行
  if (to.path === "/home") {
    // 未登录情况下进入首页
    if (!localStorage.getItem("token")) {
      next();
      window.location.replace(
        `${process.env.VUE_APP_LOGINIP}?backUrl=${window.location.href}`
      );
    } else {
      console.log(111)
      next();
    }
  } else if (to.meta.requiresAuth && !localStorage.getItem("token")) {
    next({ path: "/home" });
  } else if (!to.meta.requiresAuth && !localStorage.getItem("token")) {
    // 开放装修教程页 不显示登录弹窗
    store.commit("onShowLogindialog", false);
    next();
  } else {
    console.log(232332)
    next();
  }
});

export default router;
