<template>
    <div class="item-framean" ref="framean" :style="styles">
        <div class="content" :style="contentStyles">
            <img v-for="(item, index) in detail.banner" :key="index" :src="item.pic" alt="">
        </div>
    </div>
</template>

<script>
// 帧动画
export default {
    name:'Framean',
    components: {

    },
    props: {
        detail: {
            type: Object,
            default: {},
            required: true,
        }
    },

    data() {
        return {
            startTrans: '',
            endTrans: '',
            styles: {
                position: 'absolute',
                width: `${this.detail.width}px`,
                height: `${this.detail.height}px`,
                left: `${this.detail.left}px`,
                top: `${this.detail.top}px`,
                opacity: `${this.detail.opacity}`,
                borderRadius: `${this.detail.bR}px`,
                transform: '',
                // rotate:'',
            },
            contentStyles: {
                animationName: `gmzdhstep`,
                animationDuration: `${(this.detail.config.td || 0.03) * this.detail.config.num}s`,
                animationDelay: `${0}s`,
                animationIterationCount: `${this.detail.num ? this.detail.num : 'infinite'}`,
                animationFillMode: `forwards`,
                animationTimingFunction: `steps(${this.detail.config.num})`,
                // animationDirection: `normal`,
            },
        }
    },
    created() {
        // console.log(this.detail, 'this.detail.framean')
    },
    mounted() {
 
    },
    methods: {
    
    },
}
</script>



<style lang="scss" scoped>
.item-framean {
    overflow: hidden;
}
</style>
<style>
@keyframes gmzdhstep {
    0% {
        transform: translateY(0%);
    }

    100% {
        transform: translateY(-100%);
    }
}
</style>