<template>
    <div class="container-course">
        <template v-if="caseList[0]">
            <div class="case-list">
                <CourseItem v-for="item in caseList" :detail="item" :key="item.decorateId"></CourseItem>
            </div>
            <Pagination :pagination.sync="pagination"></Pagination>
        </template>
        <el-empty class="empty" v-else description="暂无数据！"></el-empty>
    </div>


</template>

<script>
import Pagination from '@/components/pagination'
import CourseItem from './courseItem.vue'
export default {
    components: {
        CourseItem,
        Pagination
    },
    props: {
        caseList: {
            type: Array,
            default: [],
            required: true,
        },
        pagination: {
            type: Object,
            default: () => { return {} },
            required: true
        },
    },
    data() {
        return {
            caseModelAdialogVisible: false,
            caseModelBdialogVisible: false,
        }
    },
}
</script>

<style lang="scss" scoped>
.case-list {
    padding: 20px 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, 340px);
    grid-column-gap: 26px;
    grid-row-gap: 40px;
}

.empty {
    height: 600px;
}
</style>