<template>
    <div class="container-search">
        <div class="content">
            <div class="info">
                 <h2 class="title">{{info.title}}</h2>
                 <p class="desc">{{ info.content }}</p>
                 <div class="time">{{ info.updateDate }} 更新</div>
            </div>
            <ul class="tab">
                <li class="item" :class="[params.type === 1 ? 'item-active' : '']" @click="onTabChange(1)">
                    案例
                </li>
                <li class="item" :class="[params.type === 2 ? 'item-active' : '']" @click="onTabChange(2)">
                    教程
                </li>
                <li class="item" :class="[params.type === 3 ? 'item-active' : '']" @click="onTabChange(3)">
                    文章
                </li>
            </ul>
            <el-button  style="position: absolute;right: 0;top: 20px;" type="primary" size="small" @click="onShare($event)">分享</el-button>

            <CaseList v-if="params.type === 1" :caseList="list" :pagination.sync="pagination"></CaseList>
            <CourseList v-else-if="params.type === 2" :caseList="list" :pagination.sync="pagination"></CourseList>
            <CustomCaseList v-else :list="list" :pagination.sync="pagination"></CustomCaseList>
        </div>
        <div v-if="!$store.state.userInfo" class="tip" @click="onLogin">注册或<p style="color:blue;">登录</p>，查看更多优质内容</div>
    </div>
</template>
<script>
import CourseList from '@/components/course/courseList.vue'
import CaseList from '@/components/case/caseList.vue'
import CustomCaseList from '@/components/customCase/customCaseList'
import { getShareInfoAll,getShareWrapInfo } from '@/api/share'
import { copy } from "@/utils/clipboard";

export default {
    components: {
        CourseList,
        CaseList,
        CustomCaseList,
    },  
    data() {
        return {
            active: 1,
            list: [],
            params: {
                id:0,
                type: 1, //0案例1装修
                name: "", //模糊
            },
            pagination: {
                total: 0,
                current: 1,
                size: 10,
            },
            id:null,
            info:{},
        }
    },
    provide() {
        return {
            //这个api设定主动通知 将组件实例绑定在根组件上
            setChildrenRef: (name, ref) => {
                this[name] = ref;
            },
            //这个api是主动获取绑定的组件
            getChildrenRef: (name) => {
                return this[name];
            },
            // 这个是获取根组件
            getRef: () => {
                return this;
            },
        };
    },
    created() {
        console.log(this.$route.query,"this.$route.query")
        this.params.id = this.$route.query.id
        if(this.params.id){
            this.getInfo()
            this.getList()
        }
    },
    watch: {    
        'pagination.current': {
            deep: true,
            handler(newVal, oldVal) {
                this.getList()
            }
        },

    },
    methods: {
        onShare(e){
        let url = this.$router.resolve({
        path: "/share",
        query: { id: this.params.id },
      });
      var fullHttpUrl = window.location.protocol + "//" + window.location.host+url.href
      copy(fullHttpUrl,e)
      // window.open(url.href, "_blank");
      console.log(id, "id");
    },
        // 登录
        onLogin(){
            this.signInDialogVisible = true
            this.$store.commit('onShowLogindialog', true)
        },
        init() {
            this.params.name = this.$store.state.search
            
            this.getList()
        },

        onTabChange(type) {
            this.pagination.current = 1
            this.params.type = type
            this.getList()
        },
        async getList() {
            const params = {
                ...this.params,
                ...this.pagination
            }
            try {
                const res = await getShareInfoAll(params)
                this.list = res.data.records
                this.pagination.total = res.data.total
                this.$forceUpdate()
            } catch (e) {
                this.$message.error(e.message)
            }
        },
        async getInfo() {
     
            try {
                const res = await getShareWrapInfo({
                    id:this.params.id
                })
                this.info = res.data
            } catch (e) {
                this.$message.error(e.message)
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.container-search {
    min-height: calc(100vh - 70px);
    padding-top: 30px;
    background: var(--BG);

    .tip{
        font-size: 20px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:hover{
            opacity: 0.5;
            transition: all .5s linear;
        }
    }
    .content {
        width: 100%;
        max-width: 1440px;
        margin: 0 auto;
        position: relative;

        .info{
            margin-bottom: 40px;
            .title{
                font-size: 36px;
            }
            .desc{
                font-size: 16px;
                color: #333;
                margin: 15px 0;
            }
            .time{
                font-size: 14px;
                color: #666;
            }
        }

        .tab {
            display: flex;
            // justify-content: center;

            .item {
                width: 60px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                font-weight: 500;
                margin-right: 20px;
                cursor: pointer;

                &:hover {
                    opacity: .7;
                }
            }

            .item-active {
                background: var(--colorB);
                border-radius: 10px;
                color: #fff;
            }
        }
    }
}
</style>