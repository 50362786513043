<template>
    <div class="course-item ">
        <div class="check" v-if="$store.state.showShare">
      <el-checkbox  v-model="checked" size="small" @change="onCheckChange"></el-checkbox>
    </div>
        <div class="cover" @click="viewCourseDetail(detail)">
            <img :src="detail.imgUrl" alt="">
        </div>

        <div class="info-A">
            <p class="title">
                {{ detail.title }}
            </p>
        </div>

        <div class="info-b">
            <div class="data">
                <div class="eyes">
                    <i class="el-icon el-icon-view"></i>
                    <p class="text">{{ detail.browseNum }}</p>  
                </div>
                <div class="collection" v-if="$store.state.identity!==0">
                    <i class="el-icon el-icon-star-on"></i>
                    <p class="text">{{ detail.collectionNum }}</p>
                </div>
            </div>

            <div class="right">
                <div v-if="$store.state.identity!==0" class="collection collection-active" @click="onCollection">
                    <i class="el-icon-star-off" v-if="!detail.collectionId"></i>
                    <i class="el-icon-star-on" v-else style="color:#df583a"></i>
                </div>
                <button class="button-detail" @click="viewCourseDetail(detail)">
                    <i class="el-icon-view"></i>
                    查看详情
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { saveBrowse } from '@/api/common'
export default {
    props: {
        detail: {
            type: Object,
            default: {},
            require: true
        }
    },
    inject: {
        getParentRef: {
            from: 'getRef',
            default: () => {
            }
        },
    },
    data() {
        return {
            controls: false
        }
    },
    methods: {

        onCollection() {
            if (this.$store.state.identity === 0) {
                this.$store.commit('onShowLogindialog', true)
                return false
            }
            if (this.detail.collectionId) {
                this.onCancelCollection()
            } else {
                this.onSaveCollection()
            }
        },
        onCheckChange(e){
      console.log(e,"e")
      let list  = this.$store.state.shareData.decorateList
      console.log(list)
      if(e){
        list.push(this.detail.decorateId)
        console.log(list,"list1")
      }else{
        list = list.filter(item=>item!==this.detail.decorateId)
        console.log(list,"list2")
      }
      console.log(list,"12")
      this.$store.commit("onUpdateDecorateList",list)
    },
        // 查看详情
        viewCourseDetail(detail) {
            this.onSaveBrowse()
            this.$router.push({
                name: 'courseDetail', query: {
                    id: detail.decorateId,
                    typeId: detail.typeId
                }
            })
        },
        // 咨询使用
        onConsult() {
            this.$parent.caseModelBdialogVisible = true
        },

        //保存浏览记录
        async onSaveBrowse() {
            try {
                const params = {
                    type: 1,
                    "id": this.detail.decorateId
                }
                const res = await saveBrowse(params)
                this.getParentRef().getList()

            } catch (e) {
                console.log(e)
            }
        },
        // 收藏案例
        async onSaveCollection() {
            const data = {
                "type": 1,
                "id": this.detail.decorateId
            }
            try {
                const res = await this.$http.post('/collection/collectionByType', data)
                this.getParentRef().getList()
            } catch (e) {

            }
        },
        // 取消收藏案例
        async onCancelCollection() {
            const data = {
                "type": 1,
                "id": this.detail.decorateId
            }
            try {
                const res = await this.$http.post('/collection/removeCollection', data)
                this.getParentRef().getList()
            } catch (e) {

            }
        }
    }
}
</script>

<style lang="scss" scoped>
.course-item {
    position: relative;
    &:hover{
        .title{
            color: var(--colorB) !important;
        }
    }
    .check{
    position: absolute;
    z-index: 999;
    top: 20px;
    left: 20px;
  }
    .cover {
        width: 100%;
        height: 180px;
        border: 1px solid rgb(244, 244, 244);
        border-radius: 10px;
        overflow: hidden;
        position: relative;

        img {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            transition: all .3s linear;
            &:hover{
                transform: scale(1.1);
            }
        }

    }

    .info-A {
        display: flex;
        align-items: center;
        margin: 10px 0;

        .tags {
            display: flex;
            align-items: center;
            color: rgb(255, 255, 255);
            font-size: 12px;

            .tags-item {
                padding: 1px 3px;
                border-radius: 2px;
                min-width: 30px;

                &:nth-child(1) {
                    background: linear-gradient(rgba(253, 85, 144, 1) 0%, rgba(247, 109, 102, 1) 100%);
                    margin-right: 3px;
                }

                &:nth-child(2) {
                    background: linear-gradient(rgba(59, 210, 115, 1) 0%, rgba(51, 222, 183, 1) 100%);
                }
            }
        }

        .title {
            color: rgb(0, 0, 0);
            font-size: 12px;
            margin-left: 6px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            transition: all .3s linear;
        }
    }

    .info-b {

        display: flex;
        align-items: center;
        justify-content: space-between;

        .data {
            display: flex;
            align-items: center;
            color: var(--colorA);
            font-size: 12px;

            .el-icon {
                font-size: 16px;
            }

            .eyes {
                width: 63px;
                display: flex;
                align-items: center;

                p {
                    margin-left: 2px;
                }
            }

            .collection {
                width: 63px;
                display: flex;
                align-items: center;

                p {
                    margin-left: 2px;
                }
            }
        }

        .collection-active {
            // background: #eaeaea;
        }

        .right {
            display: flex;
            align-items: center;
            
            .collection {
                width: 25px;
                height: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 10px;
                border: 1px solid var(--colorD);
                border-radius: 4px;
                color: var(--colorA);
                font-size: 16px;
                cursor: pointer;
                
                &:hover {
                    opacity: .8;
                }
            }

            button {
                width: 90px;
                height: 25px;
                background: rgb(255, 255, 255);
                border: 1px solid var(--colorD);
                border-radius: 4px;
                color: var(--colorA);

                font-size: 12px;
                cursor: pointer;

                &:hover {
                    border: none;
                    color: var(--colorC);
                    background: var(--colorB);
                }
            }

            // .button-detail {
            //     &:hover {
            //         color: #fff;
            //         background: var(--colorB);
            //         ;
            //     }
            // }


        }

    }
}
</style>