<template>
    <div class="course-item" @click="viewCourseDetail">
        <div class="check" v-if="$store.state.showShare" @click.stop> 
      <el-checkbox  v-model="checked" size="small" @change="onCheckChange"></el-checkbox>
    </div>
        <div class="cover">
            <img :src="detail.imgurl" alt="">
        </div>
        <div class="info">
            <div class="info-A">
                <div class="title">
                    {{ detail.primaryTitle }}
                </div>
                <div class="title-b">
                    {{ detail.secondaryTitle }}
                </div>
            </div>
            <div class="info-b">
                <div class="data">
                    <div class="eyes">
                        <svg t="1664267202193" class="icon" viewBox="0 0 1024 1024" version="1.1"
                            xmlns="http://www.w3.org/2000/svg" p-id="4369" width="22" height="22">
                            <path d="M419.143 512a92.857 92.857 0 1 0 185.714 0 92.857 92.857 0 1 0-185.714 0z"
                                fill="#7F7F7F" p-id="4370"></path>
                            <path
                                d="M512 257.238C231.048 257.238 62 512 62 512s169.048 254.762 450 254.762C792.159 766.762 962 512 962 512S792.159 257.238 512 257.238z m0 433.333c-98.413 0-178.571-80.158-178.571-178.571S413.587 333.429 512 333.429 690.571 413.587 690.571 512 610.413 690.571 512 690.571z"
                                fill="#7F7F7F" p-id="4371"></path>
                        </svg>
                        <p class="text">{{ detail.browseNum }}</p>
                    </div>
                    <div class="eyes">
                        <svg t="1664266760214" class="icon" viewBox="0 0 1024 1024" version="1.1"
                            xmlns="http://www.w3.org/2000/svg" p-id="2579" width="16" height="16">
                            <path
                                d="M64 483.04V872c0 37.216 30.144 67.36 67.36 67.36H192V416.32l-60.64-0.64A67.36 67.36 0 0 0 64 483.04zM857.28 344.992l-267.808 1.696c12.576-44.256 18.944-83.584 18.944-118.208 0-78.56-68.832-155.488-137.568-145.504-60.608 8.8-67.264 61.184-67.264 126.816v59.264c0 76.064-63.84 140.864-137.856 148L256 416.96v522.4h527.552a102.72 102.72 0 0 0 100.928-83.584l73.728-388.96a102.72 102.72 0 0 0-100.928-121.824z"
                                p-id="2580" fill="#7F7F7F"></path>
                        </svg>
                        <p class="text">{{ detail.likeNum }}</p>
                    </div>
                </div>
                <div class="right">
                    <p>{{detail.createTime}}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { saveCustomCaseRecord } from '@/api/case'
export default {
    props: {
        detail: {
            type: Object,
            default: {},
            require: true
        }
    },
    data() {
        return {
            controls: false
        }
    },
    methods: {
        onCollection() {
            if (this.$store.state.identity === 0) {
                this.$store.commit('onShowLogindialog', true)
                return false
            }

            if (this.detail.collectionId) {
                this.onCancelCollection()
            } else {
                this.onSaveCollection()
            }
        },
        onCheckChange(e){
      console.log(e,"e")
      let list  = this.$store.state.shareData.customList
      console.log(list)
      if(e){
        list.push(this.detail.customId)
        // console.log(list,"list1")
      }else{
        list = list.filter(item=>item!==this.detail.customId)
        // console.log(list,"list2")
      }
      console.log(list,"12")
      this.$store.commit("onUpdateCustomList",list)
    },
        async onSaveCustomCaseRecord() {
            try {
                const res = await saveCustomCaseRecord(this.detail.customId)
            } catch (e) { }
        },
        // 查看详情
        viewCourseDetail() {
            this.onSaveCustomCaseRecord()
            this.$router.push({
                name: 'operationDetail', query: {
                    id: this.detail.customId,
                    // typeId: this.detail.bannerId
                }
            })
        },


    }
}
</script>

<style lang="scss" scoped>
@keyframes itemUp {
    0% {
        opacity: 0;
        transform: translateY(10px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

.course-item {
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid rgb(244, 244, 244);
    box-shadow: 0px 2px 30px rgba(163, 163, 163, 0.05);
    padding: 20px;
    border-radius: 10px;
    transition: all 5s linear;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-name: itemUp;
    .check{
    position: absolute;
    z-index: 999;
    top: 40px;
    left: 40px;
    }
    .cover {
        width: 280px;
        height: 180px;
        border-radius: 8px;
        overflow: hidden;
        position: relative;

        img {
            width: 100%;
            height: 100%;
            transition: all .3s linear;
            &:hover{
                transform: scale(1.1);
            }
        }
    }

    .info {
        flex: 1;
        height: 100%;
        margin-left: 20px;
        padding: 8px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &:hover{
            .title{
                color: var(--colorB);
            }
        }
    }

    .info-A {
        margin: 5px 0;
        color: rgb(0, 0, 0);

        .title {
            font-size: 24px;
            font-weight: 500;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .title-b {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.5);
            word-break: break-all; //在恰当的断字点进行换行 
            overflow: hidden; //文字超出的进行隐藏
            text-overflow: ellipsis; //超出的文字用省略号表示
            display: -webkit-box; //将元素设为盒子伸缩模型显示
            text-overflow: ellipsis; //利用盒子模型 
            -webkit-box-orient: vertical; //伸缩方向设为垂直方向
            -webkit-line-clamp: 3;


        }
    }

    .info-b {

        display: flex;
        align-items: center;
        justify-content: space-between;

        .data {
            display: flex;
            align-items: center;
            color: #747474;
            font-size: 12px;

            .el-icon {
                font-size: 16px;
            }

            .eyes {
                width: 63px;
                display: flex;
                align-items: center;

                p {
                    margin-left: 2px;
                }
            }

            .collection {
                width: 63px;
                display: flex;
                align-items: center;

                p {
                    margin-left: 2px;
                }
            }
        }

        .collection-active {
            // background: #eaeaea;
        }

        .right {
            display: flex;
            align-items: center;

            button {
                width: 90px;
                height: 25px;
                background: rgb(255, 255, 255);
                border: 1px solid var(--colorD);
                border-radius: 4px;
                color: var(--colorA);

                font-size: 12px;
                cursor: pointer;

                &:hover {
                    border: none;
                    color: var(--colorC);
                    background: var(--colorB);
                }
            }


        }

    }
}
</style>