import axios from '@/service/axios'


// 
export function signInByPhone(params) {
    return axios.post(`/admin/logInByMobile`, params)
}

// 获取案例分类
export function getClassificationData(params) {
    return axios.get(`/first-type/getAll`)
}

// 提交反馈
export function feedback(params) {
    return axios.post(`/opinion/addOpinion`, params,false)
}


// 获取最近上新内容
export function getRecentContent(params) {
    return axios.get(`/case/notBrowse`)
}



// 获取最近上新内容
export function getBanner(params) {
    return axios.get(`/app-config/getConfigByTypeId/6`)
}

// 获取最近上新内容
export function getClassification(params) {
    return axios.get(`/first-type/getAll`)
}

// 获取公告
export function getNoticeList(params) {
    return axios.get(`/notice/getNoticeByUserId`)
}

// 获取未读数量
export function getUnReadNumber(params) {
    return axios.get(`/notice/HavenRead`)
}


// 获取公告
export function saveReadRecord(params) {
    return axios.get(`/notice-user/record/${params}`)
}

// 获取公告
export function addHeatMap(params) {
    return axios.post(`/heatmap/addHeatMap`,params,false)
}


// 查询案例(为你推荐)
export function getRecommendCase(params) {
    return axios.post(`/case/getRecommendCase`,params,false)
}

// 查询案例(为你推荐)
export function logOut(params) {
    return axios.post(`/user/logOut`,params)
}