<template>
    <div class="container-pagination">
        <el-pagination :total="pagination.total" :page-size.sync="pagination.size" :current-page.sync="pagination.current"
            :class="$store.state.identity === 2 ? 'pagination-vip' : 'pagination-common'" background
            layout="prev, pager, next">
        </el-pagination>
    </div>
</template>

<script>
export default {
    props: {
        pagination: {
            type: Object,
            default: () => { return {} },
            required: true
        },
    },
    data() {
        return {

        }
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.container-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
}

.pagination-vip {
    :deep(.active) {
        background-color: rgb(213, 188, 154) !important;
    }
}

.pagination-common {
    :deep(.active) {
        background-color: rgb(69, 79, 255) !important;
    }
}
</style>