<template>
    <div class="item-area" :style="styles" @click="onNavigation"></div>
</template>

<script>
// 图片
export default {
    name: 'Area',
    props: {
        detail: {
            type: Object,
            default: {},
            required: true,
        }
    },
    data() {
        return {
            styles: {
                position: 'absolute',
                width: `${this.detail.width}px`,
                height: `${this.detail.height}px`,
                left: `${this.detail.left}px`,
                top: `${this.detail.top}px`,
                opacity: `${this.detail.opacity}`,
                borderRadius: `${this.detail.bR}px`,
            }
        }
    },
    methods: {
        onNavigation() {
            if (this.detail.url) {
                window.open(this.detail.url, '_blank')
            }
        },
    },
}
</script>

<style lang="scss" scoped>

</style>