<template>
    <div class="container-code-item container-manyan" ref="manyan" :style="styles">
        <template v-for="(item,index) in detail.banner">
            <img v-if="active===index" :src="item.img" alt="" :key="index" />
        </template>
    </div>
</template>

<script>
import anime from 'animejs/lib/anime.js';
export default {
    props: {
        detail: {
            type: Object,
            default: {},
            required: true,
        }
    },
    data() {
        return {
            timer: null,
            active: 0,
            startTrans: '',
            endTrans: '',
            styles: {
                width: `${this.detail.width}px`,
                height: `${this.detail.height}px`,
                left: `${this.detail.left}px`,
                top: `${this.detail.top}px`,
                opacity: `${this.detail.opacity}`,
                borderRadius: `${this.detail.bR}px`,
                transform: '',
            }
        }
    },
    created() {
        this.init()
    },
    mounted() {
        this.createdAnimation()
    },
    methods: {
        createdAnimation() {
            const data = this.detail
            let params = {
                loop: data.times || true,
                delay: data.delay ? Number(data.delay) * 1000 : 0,
                duration: data.td ? Number(data.td) * 1000 : 1000,
                easing: data.ttf || 'linear',
                direction: 'normal',
            }
            let { tx = 0, ty = 0, tss = 1, tse = 1, ops = 1, ope = 1, ttr = 0, ttf = 0, antype = 0, transxs = 0, transxe = 0, transys = 0, transye = 0, num = 0 } = data;
            const keys = Object.keys(data.an) // 'txy', 'ts', 'opa', 'tr', 'tx3d', 'ty3d'
            keys.forEach(key => {
                switch (key) {
                    case 'ts':
                        if (data.an[key]) {
                            params.scale = tse
                        }
                        break;
                    case 'opa':
                        if (data.an[key]) {
                            params.opacity = ope
                        }
                        break;
                }
            })
            console.log(params, data.an.ts, 'params')
            const myAnimation = anime({
                targets: [this.$refs.manyan,],
                ...params,
                changeBegin: (anim) => {

                    if (this.active === this.detail.banner.length - 1) {
                        this.active = 0
                    } else {
                        this.active = this.active + 1
                    }
                    // console.log('anim', this.active)
                    // completeLogEl.value = 'completed : ' + anim.completed;
                }
            });
        },
        init() {
            const data = this.detail
            let styles = this.styles
            let { tx = 0, ty = 0, tss = 1, tse = 1, ops = 1, ope = 1, ttr = 0, ttf = 0, antype = 0, transxs = 0, transxe = 0, transys = 0, transye = 0, num = 0 } = data;
            const keys = Object.keys(data.an) // 'txy', 'ts', 'opa', 'tr', 'tx3d', 'ty3d'
            keys.forEach(key => {
                switch (key) {
                    case 'ts':
                        if (data.an[key]) {
                            styles.scale = tss
                        }
                        break;
                    case 'opa':
                        if (data.an[key]) {
                            styles.opacity = ops
                        }
                        break;
                }
            })
            console.log(styles, 'styles')
            this.styles = styles

        },
    },
    destroyed() {
        clearInterval(this.timer)
    }
}
</script>

<style lang="scss" scoped>
.container-code-item {
    position: absolute;

}

.container-manyan {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        // width: 100%;
        // height: 100%;
    }
}
</style>