import axios from '@/service/axios'

// 获取分享包数据
export function getShareWrapAll() {
    return axios.post(`/share-type/getShareWrapAll`)
}

// 获取分享包数据
export function addShareWrap(data) {
    return axios.post(`/share-type/addShareWrap`,data)
}

// 加入分享包数据
export function addShareContent(data) {
    return axios.post(`/share/addShareContent`,data)
}


// 加入分享包数据
export function deleteShareContent(data) {
    return axios.post(`/share-type/deleteShareWrap`,data)
}


// 加入分享包数据
export function updateShareContent(data) {
    return axios.post(`/share-type/updateShareWrap`,data)
}

// 获取分享包里的数据
export function getShareInfoAll(data) {
    return axios.post(`/share/getShareInfoAll`,data)
}

// 获取分享包信息
export function getShareWrapInfo(data) {
    return axios.post(`/share-type/getShareWrapInfo`,data)
}