<template>
    <div class="item-scroll" ref="scroll" :style="styles">
        <div class="content scroll-content">
            <DecorationModuleCode :content="detail.config"></DecorationModuleCode>
            <div data-v-0e188430="" :style="{ width: `${item.width}px` }"
                v-for="(item, index) in detail.config.components" :key="index" class="scroll-item"></div>
        </div>
        <div class="custom-horizontal-scrollbar" v-if="detail.showScrollIndicator === 'true'" :style="scrollStyles"
            ref="horizontal">
            <div class="custom-horizontal-indicator" :style="activedScrollStyles"></div>
        </div>
    </div>
</template>

<script>
const DecorationModuleCode = () => import('@/components/decorationModuleCode/index.vue')
import BetterScroll from 'better-scroll'
export default {
    name: 'Scroll',
    components: {
        DecorationModuleCode
    },
    props: {
        detail: {
            type: Object,
            default: {},
            required: true,
        }
    },

    data() {
        return {
            styles: {
                position: 'absolute',
                width: `${this.detail.width}px`,
                height: `${this.detail.height}px`,
                left: `${this.detail.left}px`,
                top: `${this.detail.top}px`,
                opacity: `${this.detail.opacity}`,
                borderRadius: `${this.detail.bR}px`,
            },
            scrollStyles: {
                left: `${this.detail.sCLeft ? this.detail.sCLeft : 10}px`,
                bottom: `${this.detail.sCBottom ? this.detail.sCBottom : 20}px`,
                background: `${this.detail.sDColor?this.detail.sDColor:'#666'}`,
                width: `${this.detail.sCWidth}px`,
                height: `${this.detail.sDHeight}px`
            },
            activedScrollStyles: {
                background: `${this.detail.sDAColor?this.detail.sDAColor:'#fff'}`,
                width: `${this.detail.sDWidth}px`,
                height: `${this.detail.sDHeight}px`
            },
            scroll: null
        }

    },


    mounted() {
        this.$nextTick(() => {
            this.scroll = new BetterScroll(this.$refs.scroll, {
                // eventPassthrough: 'horizontal',
                scrollX: true,
                scrollY: false,
                scrollbar: {
                    customElements: [this.$refs.horizontal],
                    fade: false,
                    interactive: true,
                    scrollbarTrackClickable: true
                }
            })
            this.$forceUpdate()

        })


    },
    methods: {
        onNavigation() {
            if (this.detail.url) {
                window.open(this.detail.url, '_blank')
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.custom-horizontal-scrollbar {
    position: absolute;
}

.item-scroll {
    white-space: nowrap;
    overflow: hidden;
}

.scroll-content {
    display: inline-block;
}

.scroll-item {
    height: 50px;
    line-height: 50px;
    font-size: 24px;
    display: inline-block;
    text-align: center;
    padding: 0 10px;
}
</style>