<template>
    <div class="item-video" :style="styles">
        <video class="video" ref="myVideo" :autoplay="detail.auto" :loop="detail.loop" :muted="detail.muted"  :controls="true" controlslist="nodownload">
            <source :src="detail.content" type="video/mp4">
        </video>
        <img class="cover" v-if="detail.poster&&!detail.auto" :src="detail.poster" alt="">
    </div>
</template>

<script>
// 图片
export default {
    name: 'VideoA',
    props: {
        detail: {
            type: Object,
            default: {},
            required: true,
        }
    },
    data() {
        return {
            styles: {
                position: 'absolute',
                width: `${this.detail.width}px`,
                height: `${this.detail.height}px`,
                left: `${this.detail.left}px`,
                top: `${this.detail.top}px`,
                opacity: `${this.detail.opacity}`,
                borderRadius: `${this.detail.bR}px`,
                transform: `rotate(${this.detail.rotate}deg)`,
            }
        }
    },
    methods: {
        onNavigation() {
            if (this.detail.url) {
                window.open(this.detail.url, '_blank')
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.item-video {
    .video {
        width: 100%;
        height: 100%;
    }

    .cover {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }
}
</style>