<template>
    <img class="item-close" @click="onClose" :style="styles" :src="detail.src" alt="">
</template>

<script>
// 图片
export default {
    name: 'PopupClosed',
    props: {
        detail: {
            type: Object,
            default: {},
            required: true,
        },
        visible: {
            type: Boolean,
            default: false,
            required: true,
        }
    },
    data() {
        return {
            styles: {
                position: 'absolute',
                width: `${this.detail.width}px`,
                height: `${this.detail.height}px`,
                left: `${this.detail.left}px`,
                top: `${this.detail.top}px`,
                zIndex: 99,
            }
        }
    },
    created() {
        console.log(this.visible, 'visible')
    },
    methods: {
        onClose() {
            this.$emit('update:visible',false)
        },
    },
}
</script>

<style lang="scss" scoped>

</style>