import axios from "axios";
// import qs from "qs";
// import message from "./message";
import { Message } from "element-ui";

import router from "@/router";
import store from "@/store";

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 20000,
});

// 请求拦截
service.interceptors.request.use(
  (config) => {
    // 请求添加token
    const token = localStorage.getItem("token") || "";
    if (token) {
      config.headers["Authorization"] = token;
    }

    // 取消请求
    config.cancelToken = new axios.CancelToken((cancel) => {
      store.dispatch("pushCancel", { cancelToken: cancel });
    });

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//响应拦截
service.interceptors.response.use(
  (response) => {
    try {
      if (response.data.code == -1) {
        Message.warning(response.data.message);
      } else if (response.data.code == 401) {
        Message.warning("登录过期，请重新登录!");
        store.dispatch("clearCancel");
        setTimeout(() => {
          localStorage.removeItem("token");
          localStorage.removeItem("userInfo");
          window.location.replace(
            `${process.env.VUE_APP_LOGINIP}?backUrl=${window.location.href}`
          );
        }, 2000);
      }
    } catch (error) {}

    return Promise.resolve(response);
  },
  (error) => {
    if (error.response.status == 401) {
      Message.warning("登录过期，请重新登录!");
      store.dispatch("clearCancel");
      setTimeout(() => {
        localStorage.removeItem("token");
        localStorage.removeItem("userInfo");
        window.location.replace(
          `${process.env.VUE_APP_LOGINIP}?backUrl=${window.location.href}`
        );
      }, 2000);
      return Promise.reject(error.response);
    } else {
      return Promise.reject(error.response);
    }
  }
);

export default {
  /**
   * @param {String} url
   * @param {Object} data
   * @returns Promise
   */
  service,
  post(url, data, loading = false) {
    return new Promise(async (resolve, reject) => {
      if (loading) {
        store.commit("OPEN_LOADING");
      }

      try {
        const res = await service({ method: "post", url, data: data });
        if (res.data.success) {
          resolve(res.data);
        } else {
          reject(res.data);
        }
      } catch (err) {
        reject(err);
      }
      if (loading) {
        console.log(loading, "loading");
        setTimeout(() => {
          store.commit("CLOSE_LOADING");
        }, 2000);
      }
    });
  },
  get(url, data, loading = false) {
    return new Promise(async (resolve, reject) => {
      if (loading) {
        store.commit("OPEN_LOADING");
      }

      try {
        const res = await service({ method: "get", url, params: data });
        if (res.data.success) {
          resolve(res.data);
        } else {
          reject(res.data);
        }
      } catch (err) {
        reject(err);
      }
      if (loading) {
        setTimeout(() => {
          store.commit("CLOSE_LOADING");
        }, 2000);
      }
    });
  },
};
