<template>
    <div style="border: 1px solid #ccc;">
        <Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig" :mode="mode" />
        <Editor style="height:300px; overflow-y: hidden;" :value="content" :defaultConfig="editorConfig" :mode="mode"
            @onCreated="onCreated" @onChange="onChange" />
    </div>
</template>

<script>
import Vue from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import onUploadFile from '@/utils/ossUpload'
export default {
    components: { Editor, Toolbar },
    props: {
        content: {
            type: String,
            default: '',
            required: true
        },
        disabled: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    data() {
        return {
            editor: null,
            toolbarConfig: {
                excludeKeys: [
                    'group-video',
                    'italic',
                    'insertTable',
                    'fullScreen',
                    'group-more-style',
                    'codeBlock'
                ]

            },
            editorConfig: {
                placeholder: '请输入内容...',
                MENU_CONF: {
                    //配置上传图片
                    uploadImage: {
                        customUpload: this.uploadImg
                    },
                    uploadVideo: {
                        customUpload: this.uploadVideo
                    }
                }
            },
            mode: 'default', // or 'simple'
        }
    },

    created() {

    },
    methods: {
        onChange(e) {
            const value = e.getHtml()
            console.log(value)

            this.$emit('update:content', value)
        },
        // 上传图片
        async uploadImg(e, insertFn) {
            try {
                const res = await onUploadFile(e)
                console.log(res)
                insertFn(res.url, res.name)
            } catch (e) {
                this.$message.error(e)
            }
        },

        // 上传图片
        async uploadVideo(e, insertFn) {
            try {
                const res = await onUploadFile(e)
                console.log(res)
                insertFn(res.url, res.name)
            } catch (e) {
                this.$message.error(e)
            }
        },


        onCreated(editor) {
            this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
            if (this.disabled) {
                console.log()
                this.editor.disable()
            }
        },

    },
    beforeDestroy() {
        const editor = this.editor
        if (editor == null) return
        editor.destroy() // 组件销毁时，及时销毁编辑器
    }
}
</script>
<style src="@wangeditor/editor/dist/css/style.css">

</style>
<style lang="less" scoped>

</style>