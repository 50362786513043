<template>

    <div class="container-window" v-if="$store.state.showWindow">
        <div class="content" :class="isShow ? 'content-active' : 'content-default'">
            <i class="el-icon el-icon-error" @click="onClose"></i>
            <img :src="$store.state.userInfo.window.imgurl" @click="onView" alt="" />
        </div>
    </div>

</template>

<script>
import { saveUserBrowseData } from '@/api/common'
export default {
    data() {
        return {
            visible: false,
            isShow: true,
        }
    },
    created() {
        if (this.$store.state.userInfo.window && localStorage.getItem('showWindow') == 1) {
            this.isShow = true
            this.$store.commit('onChangeShowWindow', true)
        }
        // console.log(this.$store.state.userInfo, 'this.$store.userInfo')
    },
    methods: {
        onClose() {
            localStorage.setItem('showWindow', 0)
            this.isShow = false
            setTimeout(() => {
                this.isShow = true
                this.$store.commit('onChangeShowWindow', false)
            }, 500)
        },
        onView() {
            saveUserBrowseData({
                typeId: 15,
                spmId: this.$store.state.userInfo.window.windowId,
            })
            this.onClose()
            window.open(this.$store.state.userInfo.window.link, '_blank');
        },

    }
}
</script>

<style lang="scss" scoped>
@keyframes showWindow {
    0% {
        transform: scale(0);

    }

    100% {
        transform: scale(1);
    }
}

@keyframes hiddenWindow {
    from {
        transform: scale(1);
    }

    to {
        transform: scale(0);
    }
}



.float {
    margin-top: 10px;
    // position: fixed;
    // right: 0;
    // // height: 60px;
    // // width: 60px;
    // bottom: 100px;
    // z-index: 999;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
        transform: scale(1.05);
    }
}

.container-window {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    // opacity: .5;
    background: rgba($color: #000000, $alpha: .5);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;

    // width: auto !important;
    // height: 0 !important;
    .content-active {
        animation: showWindow 0.5s;
    }

    .content-default {
        animation: hiddenWindow 0.5s;
    }

    .content {
        animation-fill-mode: forwards;
        position: relative;
        max-width: 90%;

        .el-icon {
            position: absolute;
            font-size: 28px;
            top: -40px;
            right: -40px;
            color: #fff;

            &:hover {
                scale: 1.2;
            }
        }

        img {
            max-width: 100%;
        }
    }
}
</style>