<template>
    <header class="container-header"
        :class="$store.state.identity === 2 ? 'container-header-vip' : 'container-header-common'">
        <div class="content">
            <div class="logo" @click="onGoHomePage">
                <img v-if="$store.state.identity === 2" src="../assets/images/header/header-logo-vip.png" alt="">
                <img v-else src="../assets/images/header/header-logo-common.png" alt="">
                <P>鹿宾</p>
                <div class="border"></div>
                <p>一路并肩精彩</p>
            </div>
            <div class="center">
                <nav class="nav">
                    <div class="nav-item" @click="onGoHomePage">首页</div>  
                    <!-- <div class="nav-item" @click="onFind">发现我们</div> -->
                    <div class="nav-item" @click="onViewOperation">最新资讯</div   >
                    <div class="nav-item" @click="onViewCourse">装修教程</div   >
                    <div class="nav-item" @click="onViewCase">案例</div   >
                    <el-dropdown class="nav-item nav-course">
                        <span class="el-dropdown-link">
                            装修工具
                            <i class="el-icon-arrow-down"></i>
                        </span>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <ul class="list-course"
                                    :class="$store.state.identity === 2 ? 'list-course-vip' : 'list-course-common'">
                                    <!-- <li class="item item-1" @click="onViewCourse">装修教程</li> -->
                                    <li class="item item-2"
                                        @click="openNewWebsite('http://container.open.taobao.com/container?appkey=23082561&encode=utf-8')">
                                        天猫装修</li>
                                    <li class="item item-3"
                                        @click="openNewWebsite('https://sanfang.guanmeikj.com/index.php?c=editer&m=index')">
                                        京东&抖店装修</li>
                                </ul>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </nav>
            
                <div class="search">
                    <el-input v-model="search" placeholder="输入关键字搜索" @input="onSearchChange" :debounce="2000"
                        size="mini" @keyup.enter.native="onSearch()">
                        <i class="el-icon-search el-input__icon" slot="suffix" @click="onSearch()">
                        </i>
                    </el-input>
                </div>
                

                <div class="share" v-if="$store.state.userInfo&&$store.state.userInfo.commerce===1">
                    <el-button v-if="!$store.state.showShare" type="primary" size="small" @click="onCreateShare">创建分享</el-button>
                    <el-button v-else type="primary" size="small" @click="onConfirmChoose">确认选择</el-button>
                    <el-button  v-if="$store.state.showShare" type="primary" @click="onCancel"  size="small">取消</el-button>
                    <el-button type="success" size="small" @click="onManageShare">管理分享</el-button>
                </div>
            </div>
            <div class="info">
                <div class="info-none" @click="onSignIn()" v-if="!$store.state.userInfo">
                    <img src="../assets/images/header/none.png" alt="">
                    <p>登录</p>
                </div>
                <div class="info-online" v-else>
                    <el-dropdown trigger="click">
                        <div class="news" @click="viewNotice">
                            <el-badge :value="unReadNumber" :hidden="unReadNumber === 0" :max="99" class="item">
                                <i class="el-icon-bell">
                                </i>
                            </el-badge>
                        </div>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <div class="notice">
                                    <div class="title">
                                        <img class="icon" src="../assets/images/header/icon-notice.png" alt="">
                                        <p>最新公告</p>
                                    </div>
                                    <ul class="notice-list" v-if="noticeList[0]">
                                        <li class="item" v-for="(item, index)  in noticeList" :key="item.notice_id"
                                            @click="onViewNoticeItem(index)">
                                            <div class="item-header">
                                                <p class="item-title" :class="[!item.userId ? 'item-title-new' : '']">
                                                    {{ item.title }}
                                                </p>
                                                <p class="i tem-time">{{ item.releaseTime }}</p>
                                            </div>
                                            <div class="item-text" v-html="item.content">

                                            </div>
                                        </li>
                                    </ul>
                                    <el-empty v-else description="暂无最新公告"></el-empty>
                                </div>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>

                    <div class="head">
                        <img src="../assets/images/personal/head.png" alt="">
                    </div>
                    <el-dropdown>
                        <div class="name">
                            <p>{{ $store.state.userInfo.name }}</p>
                            <i class="el-icon-arrow-down"></i>
                        </div>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <div class="user-info"
                                    :class="$store.state.identity === 2 ? 'user-info-vip' : 'user-info-common'">
                                    <div class="user-header">
                                        <div class="name">
                                            {{$store.state.userInfo.name}}
                                        </div>
                                        <div class="id">
                                            ID:{{$store.state.userInfo.account}}
                                        </div>
                                    </div>
                                    <div class="list">
                                        <div class="item item-1" :class="$route.name==='info'?'item-active-1':''"
                                            @click="$router.push({ name: 'info' })">
                                            <p>个人中心</p>
                                        </div>
                                        <div class="item item-2" :class="$route.name==='collection'?'item-active-2':''"
                                            @click="$router.push({ name: 'collection' })">
                                            <p>收藏中心</p>
                                        </div>
                                        <div class="item item-3" :class="$route.name==='editRecords'?'item-active-3':''"
                                            @click="$router.push({ name: 'editRecords' })">
                                            <p>编辑记录</p>
                                        </div>
                                        <div class="item item-4" @click="onSignOut">
                                            <p>退出登录</p>
                                        </div>
                                    </div>
                                </div>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </div>
            </div>
            
        </div>
        <Notice v-if="noticeDialogVisible" :dialogVisible.sync="noticeDialogVisible" :noticeList="noticeList"
            :activeNoticeIndex.sync="activeNoticeIndex"></Notice>
            
    </header>
    
</template>

<script>
import Notice from '@/components/notice'
import { saveUserBrowseData,getUserInfo } from '@/api/common'
import { getNoticeList, getUnReadNumber, saveReadRecord } from '@/api/home'

export default {
    name: 'headerView',
    components: {
        Notice
    },
    data() {
        return {
            search: '',
            signInDialogVisible: false,
            noticeDialogVisible: false,
            unReadNumber: 0,
            noticeList: [],
            activeNoticeIndex: 0,
        }
    },
    created() {
        if (this.$store.state.userInfo) {
            this.getUserInfo()
            this.getUnReadNumber()
            this.getNoticeList()
        }
    },
    methods: {
        onCreateShare(){
      this.$store.commit("onUpdateCustomList",[])
      this.$store.commit("onUpdateCaseList",[])
      this.$store.commit("onUpdateDecorateList",[])
            this.$store.commit("onShowShare",true)
        },  
        onSearch() {
            if (!this.search) {
                this.$message({
                    message: '请输入搜索内容',
                    type: 'warning',
                    duration: 1000,
                });
                return false
            }
            this.$store.commit('onSearch', this.search)
            if (this.$route.name !== 'search') {
                this.$router.push({
                    name: "search"
                })
            }
        },

        // 管理分享
        onManageShare(){
            this.$router.push({
                path:'/Personal/share'
            })
        },      

        // 确认选择
        onConfirmChoose(){
            this.$store.commit("onShowSharePackage",true)
        },  

        onCancel(){
            this.$store.commit("onShowShare",false)

        },
        // 发现我们
        onFind() {
            this.openNewWebsite('http://www.guanmeikj.com/women/')
            saveUserBrowseData({
                typeId: 44
            })
        },
        // 跳转外部链接
        openNewWebsite(url) {
            window.open(url, '_blank');
        },
        onSearchChange(e) {
            console.log(e)
        },
        // 浏览装修教程
        onViewCourse(type) {
            this.$router.push({ name: 'courseList' })
        },
           // 浏览案例
        onViewCase(type) {
            this.$router.push({ name: 'classification' })
        },
          // 浏览案例文章
          onViewOperation(index) {
                saveUserBrowseData({
                    typeId: 14,
                    spmId: 999,
                })
                window.open(`https://lubin.guanmeikj.com/#/operation?type=9`);
        },
        onGoHomePage() {
            if (this.$route.name === 'home') {
                return false
            }
            this.$router.push({ path: '/home' })
        },
        onSignIn() {
            this.signInDialogVisible = true
            this.$store.commit('onShowLogindialog', true)
        },
        onSignOut() {
            this.$store.commit('signOut')
        },

        // 获取未读数量
        async getUnReadNumber() {
            try {
                const res = await getUnReadNumber()
                this.unReadNumber = res.data
            } catch (e) {
            }
        },
        // 获取用户信息
        async getUserInfo() {
            try {
                const res = await getUserInfo()
                this.$store.commit('updateUserInfo', res.data)
                this.$store.commit('onShowLogindialog', false)
            } catch (err) {
                console.log(err)
            }
        },
        // 获取公告
        async getNoticeList() {
            try {
                const res = await getNoticeList()
                this.noticeList = res.data
            } catch (e) {

            }
        },
        async saveReadRecord() {
            console.log('saveReadRecord')
            try {
                const res = await saveReadRecord(this.noticeList[this.activeNoticeIndex].noticeId)
                this.getUnReadNumber()
                this.getNoticeList()
            } catch (e) {
                console.log(e)
            }
        },
        // 查看公告
        viewNotice() {
            // this.saveReadRecord()
        },
        // 查看公告详情
        onViewNoticeItem(index) {
            this.activeNoticeIndex = index
            this.saveReadRecord()
            saveUserBrowseData({
                typeId: 16,
                spmId: this.noticeList[index].noticeId
            })
            this.noticeDialogVisible = true
        }
    }
}
</script>

<style>
.el-dropdown-menu {
    padding: 0 !important;
}
</style>

<style lang="scss" scoped>
.container-header-vip {
    .search {
        :deep(.el-input__inner) {
            color: #fff;
            background: rgb(53, 53, 56);
        }

        .el-icon-search {
            color: #fff;
        }
    }

    .content {
        .border {
            background: rgba(252, 246, 231, .2);
        }
    }
  
}

.container-header-common {

    .search {
        :deep(.el-input__inner) {
            color: rgba(0, 0, 0, 0.5);
            background: rgb(247, 247, 247);
        }

        .el-icon-search {
            color: rgba(0, 0, 0, 0.5);
        }
    }

    .content {
        .border {
            background: rgba(0, 0, 0, 0.3);
        }
    }
}


.user-info-vip {
    .list {
        .item {
            &:hover {
                color: rgb(185, 162, 125) !important;
            }
        }


        .item-1 {

            &:hover {
                background: rgb(247, 247, 247) url('../assets/images/header/user-v-1.png') 30px center no-repeat !important;

            }
        }

        .item-active-1 {
            color: rgb(185, 162, 125) !important;
            background: rgb(247, 247, 247) url('../assets/images/header/user-v-1.png') 30px center no-repeat !important;

        }


        .item-2 {


            &:hover {
                background: rgb(247, 247, 247) url('../assets/images/header/user-v-2.png') 30px center no-repeat !important;

            }
        }

        .item-active-2 {
            color: rgb(185, 162, 125) !important;
            background: rgb(247, 247, 247) url('../assets/images/header/user-v-2.png') 30px center no-repeat !important;
        }

        .item-3 {


            &:hover {
                background: rgb(247, 247, 247) url('../assets/images/header/user-v-3.png') 30px center no-repeat !important;

            }
        }

        .item-active-3 {
            color: rgb(185, 162, 125) !important;
            background: rgb(247, 247, 247) url('../assets/images/header/user-v-3.png') 30px center no-repeat !important;
        }

        .item-4 {


            &:hover {
                background: rgb(247, 247, 247) url('../assets/images/header/user-v-4.png') 30px center no-repeat !important;
            }
        }
    }
}

.user-info-common {
    .list {


        .item {
            &:hover {
                color: rgb(69, 79, 255);
            }
        }

        .item-1 {


            &:hover {
                background: rgb(247, 247, 247) url('../assets/images/header/user-active-1.png') 30px center no-repeat !important;

            }
        }

        .item-active-1 {
            color: rgb(69, 79, 255) !important;
            background: rgb(247, 247, 247) url('../assets/images/header/user-active-1.png') 30px center no-repeat !important;
        }

        .item-2 {


            &:hover {
                background: rgb(247, 247, 247) url('../assets/images/header/user-active-2.png') 30px center no-repeat !important;

            }
        }

        .item-active-2 {
            color: rgb(69, 79, 255) !important;
            background: rgb(247, 247, 247) url('../assets/images/header/user-active-2.png') 30px center no-repeat !important;
        }

        .item-3 {


            &:hover {
                background: rgb(247, 247, 247) url('../assets/images/header/user-active-3.png') 30px center no-repeat !important;

            }
        }

        .item-active-3 {
            color: rgb(69, 79, 255) !important;
            background: rgb(247, 247, 247) url('../assets/images/header/user-active-3.png') 30px center no-repeat !important;

        }

        .item-4 {


            &:hover {
                background: rgb(247, 247, 247) url('../assets/images/header/user-active-4.png') 30px center no-repeat !important;
            }
        }
    }
}

.container-header {
    display: flex;
    justify-content: center;
    height: 70px;
    background: var(--colorWB);
}

.user-info {
    width: 230px;
    // height: 215px;
    background: rgb(255, 255, 255);
    /* 按钮灰色 */
    border: 0px solid rgb(244, 244, 244);
    box-shadow: 1px 1px 8px rgba(51, 70, 114, 0.1);
    border-radius: 8px;
    padding: 13px 10px;

    .user-header {
        padding-left: 29px;
        text-align: left;

        .name {
            color: rgb(0, 0, 0);
            font-family: Noto Sans SC;
            font-size: 14px;
        }

        .id {
            color: rgba(0, 0, 0, 0.3);
            font-family: Noto Sans SC;
            font-size: 12px;
            font-weight: 400;
        }
    }

    .list {
        .item {
            display: flex;
            align-items: center;
            height: 35px;
            padding-left: 48px;
            color: rgba(0, 0, 0, 0.5);
            font-family: Noto Sans SC;
            font-size: 12px;
            font-weight: 400;
            cursor: pointer;

            .icon {
                height: 14px;
                width: 14px;
                background-size: contain;
            }

        }

        .item-1 {
            background: url('../assets/images/header/user-1.png') 30px center no-repeat;


        }

        .item-2 {
            background: url('../assets/images/header/user-2.png') 30px center no-repeat;


        }

        .item-3 {
            background: url('../assets/images/header/user-3.png') 30px center no-repeat;


        }

        .item-4 {
            background: url('../assets/images/header/user-4.png') 30px center no-repeat;


        }

    }
}

.notice {
    width: 290px;
    background: rgba(255, 255, 255, 0.9);
    border: 0.5px solid rgb(255, 255, 255);
    border-radius: 10px;
    display: flex;
    flex-direction: column;

    .item {
        cursor: pointer;
    }

    .title {
        height: 40px;
        display: flex;
        align-items: center;
        padding: 0 16px;
        font-size: 14px;
        font-weight: 500;
        height: 50px;
        border-bottom: 1px solid rgb(229, 229, 229);
        font-family: Noto Sans SC;
        font-weight: 500;

        .icon {
            width: 22px;
            margin-right: 8px;
        }
    }
}

.notice-list {
    flex: 1;
    max-height: 400px;
    overflow-y: auto;
    padding: 10px;

    &::-webkit-scrollbar {
        width: 4px;
        background: #fff;
    }

    &::-webkit-scrollbar-thumb {
        background: rgb(229, 229, 229);
        border-radius: 6px;
    }

    .item {
        height: 85px;
        padding: 0 14px;
        font-size: 12px;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &:hover {
            background: rgb(230, 236, 255);
        }


        .item-header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .item-title {
                color: rgb(0, 0, 0);
                position: relative;
                padding-left: 8px;
            }

            .item-title-new::after {
                content: '';
                position: absolute;
                width: 7px;
                height: 7px;
                background: rgb(255, 60, 57);
                border-radius: 10px;
                border-radius: 50%;
                left: -4px;
                top: 50%;
                margin-top: -3.5px;
            }

            .item-time {
                color: rgb(106, 106, 106);
            }
        }

        .item-text {
            text-align: left;
            color: rgb(139, 139, 139);
            padding-left: 8px;
            margin-top: 8px;
            display: -webkit-box;
            /**对象作为伸缩盒子模型展示**/
            -webkit-box-orient: vertical;
            /**设置或检索伸缩盒子对象的子元素的排列方式**/
            -webkit-line-clamp: 2;
            /**显示的行数**/
            overflow: hidden;
            /**隐藏超出的内容**/
        }
    }
}

.list-course-vip {
    .item {
        &:hover {
            color: rgb(185, 162, 125);
        }
    }

    .item-1 {
        &:hover {
            background: rgb(247, 247, 247) url('../assets/images/header/course-v-1.png') 30px center no-repeat !important;
        }
    }

    .item-2 {


        &:hover {
            background: rgb(247, 247, 247) url('../assets/images/header/course-v-2.png') 30px center no-repeat !important;
        }
    }

    .item-3 {

        &:hover {
            background: url('../assets/images/header/course-v-3.png') 30px center, url('../assets/images/header/course-v-4.png') 50px center, rgb(247, 247, 247);
            background-repeat: no-repeat;
        }
    }
}

.list-course-common {
    .item {
        &:hover {
            color: rgb(69, 79, 255)
        }
    }

    .item-1 {
        &:hover {
            background: rgb(247, 247, 247) url('../assets/images/header/item-active-1.png') 30px center no-repeat !important;
        }
    }

    .item-2 {
        &:hover {
            background: rgb(247, 247, 247) url('../assets/images/header/item-active-2.png') 30px center no-repeat !important;
        }
    }

    .item-3 {

        &:hover {
            background: url('../assets/images/header/item-active-3.png') 30px center, url('../assets/images/header/item-active-4.png') 50px center, rgb(247, 247, 247);
            background-repeat: no-repeat;
        }
    }
}

.list-course {
    padding: 15px 10px;
    width: 230px;
    // height: 135px;
    box-sizing: border-box;
    background: rgb(255, 255, 255);
    /* 按钮灰色 */
    box-shadow: 1px 1px 8px rgba(51, 70, 114, 0.1);
    border-radius: 8px;

    .item {
        height: 35px;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.5);
        padding-left: 48px;
        display: flex;
        align-items: center;
        cursor: pointer;
        border-radius: 8px;


    }

    .item-1 {
        background: url('../assets/images/header/item-1.png') 30px center no-repeat;


    }

    .item-2 {
        background: url('../assets/images/header/item-2.png') 30px center no-repeat;

    }

    .item-3 {
        padding-left: 65px;
        background: url('../assets/images/header/item-3.png') 30px center, url('../assets/images/header/item-4.png') 50px center;
        background-repeat: no-repeat;

    }
}



.content {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .share{
        margin-left: 20px;
    }
    .logo {
        display: flex;
        align-items: center;
        cursor: pointer;
        color: var(--colorBW);

        img {
            width: 37px;
            // height: 45px;
            margin-right: 5px;
        }

        &:hover {
            opacity: .8;
        }

        .border {
            width: 1px;
            height: 40px;
            // background: rgba(252, 246, 231, .2);
            margin: 0 14px 0 10px;
        }

        .item2 {
            margin-left: 6px;
        }
    }

    .center {
        display: flex;
        align-items: center;

        .search {
            width: 240px;
            height: 26px;

            :deep(.el-input__inner) {
                border-radius: 20px;
                border: none;
            }

            .el-icon-search {
                font-size: 15px;
                cursor: pointer;

                &:hover {
                    color: var(--colorB);
                }
            }
        }

        .nav {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .nav-item {
                color: var(--colorBW);
                font-weight: 500;
                font-size: 14px;
                cursor: pointer;
                margin: 0 20px;

                &:hover {
                    opacity: .8;
                }
            }
        }
    }


    .info {
        .info-none {
            display: flex;
            align-items: center;
            width: 80px;
            height: 28px;
            justify-content: center;
            /* 浅灰 */
            border: 1px solid rgba(0, 0, 0, 0.3);
            border-radius: 50px;
            font-size: 12px;
            color: rgb(0, 0, 0);
            cursor: pointer;

            &:hover {
                opacity: 0.7;
            }

            img {
                margin-right: 6px;
                width: 10px;
            }
        }

        .info-online {
            display: flex;
            align-items: center;

            :deep(.el-badge) {
                height: 18px;
            }

            .news {
                cursor: pointer;

                .el-icon-bell {
                    // color: #fff;
                    font-size: 20px;
                    line-height: 0;
                    color: var(--colorBW);
                }
            }

            .head {
                margin: 0 8px 0 20px;
                height: 35px;
                width: 35px;
                border: 50%;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .name {
                display: flex;
                align-items: center;
                font-size: 12px;
                color: var(--colorBW);
                cursor: pointer;

                p {
                    padding-right: 4px;
                }
            }
        }
    }


}
</style>