import axios from '@/service/axios'

// 获取案例
export function getCases(params) {
    return axios.post(`/case/getCase`,params)
}

// 搜索
export function saveEditRecord(params) {
    return axios.get(`/compile/saveComoile/${params}`)
}

//  记录定制案例数据
export function saveCustomCaseRecord(params) {
    return axios.get(`/custom-spm/spm/${params}`)
}


//  获取案例标签 使用场景
export function getCaseInfo(params) {
    return axios.get(`/case/getCaseById/${params}`,{},false)
}



// 收藏案例
export function collectionByType(params) {
    return axios.post(`/collection/collectionByType`,params,false)
}


//  取消收藏
export function removeCollection(params) {
    return axios.post(`/collection/removeCollection`,params,false)
}


