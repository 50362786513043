<template>
    <div class="silder-v">
        <div class="tip" v-if="$store.state.userInfo.expirationTime>-1&&$store.state.userInfo.expirationTime<=30">
            <div class="text">
                <img class="icon" src="../../assets/images/silder/bell.png" alt="">
                <p>亲！您的年框服务将于{{$store.state.userInfo.expirationTime}}日后到期，记得联系我们续费哦！</p>
            </div>
            <button class="button-read" @click="onCloseTip">我知道啦</button>
        </div>

        <div class="content-main" :style="{ 'height': fold ? '170px' : '333px' }">
            <img class="content-icon" src="../../assets/images/silder/silder-icon.png" alt="" />
            <div class="content">
                <p class="word">
                    嗨,我是您的线上管家小鹿 。
                    以下是您的专属服务团队~
                </p>
            </div>
            <ul class="silder-list">
                <li class="silder-item">
                    <p class="item-title">专属服务人员：{{ $store.state.userInfo.servicePersonnelName }}</p>
                    <div class="item-content" v-if="$store.state.userInfo.servicePersonnelName">
                        <img class="icon" src="../../assets/images/silder/icon-1.png" alt="">
                        <img class="icon" src="../../assets/images/silder/icon-2.png" alt="">
                        <p class="text">{{ $store.state.userInfo.servicePersonnelPhone }}</p>
                        <button class="button-copy"
                            @click="handleCopy($store.state.userInfo.servicePersonnelPhone, $event,56)">复制</button>
                    </div>
                    <div class="item-content" v-else>
                        <div></div>
                        <p class="text">暂无服务人员~</p>
                        <div></div>
                    </div>
                </li>
                <li class="silder-item">
                    <p class="item-title">商务对接：{{ $store.state.userInfo.businessName }}</p>
                    <div class="item-content" v-if="$store.state.userInfo.businessName">
                        <img class="icon" src="../../assets/images/silder/icon-1.png" alt="">
                        <img class="icon" src="../../assets/images/silder/icon-2.png" alt="">
                        <p class="text">{{ $store.state.userInfo.businessPhone }}</p>
                        <button class="button-copy"
                            @click="handleCopy($store.state.userInfo.businessPhone, $event,57)">复制</button>
                    </div>
                    <div class="item-content" v-else>
                        <div></div>
                        <p class="text">暂无商务对接~</p>
                        <div></div>
                    </div>
                </li>
                <li class="silder-item">
                    <p class="item-title">服务督查：静远</p>
                    <div class="item-content">
                        <img class="icon" src="../../assets/images/silder/icon-1.png" alt="">
                        <img class="icon" src="../../assets/images/silder/icon-2.png" alt="">
                        <p class="text">15223386057</p>
                        <button class="button-copy" @click="handleCopy('15223386057', $event,58)">复制</button>
                    </div>
                </li>
            </ul>
            <div class="change" @click="onFoldChange()">
                <template v-if="fold">
                    <p>查看我的服务团队</p>
                    <i class="el-icon-arrow-down"></i>
                </template>
                <template v-else>
                    <i class="el-icon-arrow-up"></i>
                    <p>点击收起</p>
                </template>
            </div>
        </div>

    </div>
</template>
<script>
import { copy } from "@/utils/clipboard";
import { closeTip } from '@/api/common'
import { saveUserBrowseData } from '@/api/common'

export default {
    data() {
        return {
            fold: true,
        }
    },
    mounted() {
    },
    methods: {
        handleCopy(text, e,type) {
            copy(text, e);
            saveUserBrowseData({
                typeId: 35,
            })
            saveUserBrowseData({
                typeId: type,
            })
        },

        onFoldChange() {
            this.fold = !this.fold
        },

        // 关闭通知 
        async onCloseTip() {
            try {
                const res = await closeTip()
                this.$store.commit('onUpdateUserInfo')
            } catch (e) {
                console.log(e)
            }
        },
    }
}

</script>

<style lang="scss" scoped>
.silder-v {}

.content-main {
    margin-top: 100px;
    width: 215px;
    height: 175px;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(244, 244, 244);
    box-shadow: 1px 1px 8px rgba(51, 70, 114, 0.07);
    border-radius: 8px;
    z-index: 9999;
    padding: 10px 0px 5px 0;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    transition: all .3s;
}

.tip {
    // position: absolute;
    width: 215px;
    // height: 130px;
    // right: 0;
    // top: -220px;
    border-radius: 8px;
    padding: 15px;
    background-size: cover;
    background: rgb(255, 255, 255);
    /* 按钮灰色 */
    border: 1px solid rgb(244, 244, 244);
    border-radius: 8px;

    // &::after {
    //     content: '';
    //     position: absolute;
    //     width: 35px;
    //     height: 32px;
    //     background:#000 url('../../assets/images/silder/bg.png') 0 0 no-repeat;
    //     bottom: -20px;
    //     left: 50%;
    //     transform: translateX(-50%);
    //     background-position: 15px 97px;
    // }

    .text {
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgb(185, 162, 125);
        font-size: 12px;

        p {
            line-height: 18px;
        }

        .icon {
            width: 20px;
            height: 26px;
            margin-right: 15px;
        }
    }

    .button-read {
        width: 105px;
        height: 25px;
        /* 金色描边 */
        background: rgb(213, 188, 154);
        /* 金色描边 */
        border: 1px solid rgb(213, 188, 154);
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgb(255, 255, 255);
        font-size: 12px;
        margin: 0 auto;
        margin-top: 15px;
        cursor: pointer;

        &:hover {
            opacity: 0.8;
        }
    }
}

.content-icon {
    position: absolute;
    top: -95px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
}

.content {
    .word {
        color: rgb(185, 162, 125);
        font-size: 12px;
        padding: 0 25px;
    }
}

.change {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: rgba(0, 0, 0, 0.3);
    font-size: 12px;
    line-height: 18px;
    cursor: pointer;
}

.silder-list {
    overflow: hidden;
    padding: 0 15px;
    margin-top: 10px;

    .silder-item {
        height: 75px;
        background: rgba(252, 246, 231, 0.5);
        border: 0px solid rgb(0, 0, 0);
        border-radius: 8px;
        padding: 10px 0;
        box-sizing: border-box;
        margin-bottom: 5px;

        &:last-child {
            margin-top: 0;
        }

        .item-title {
            text-align: center;
            color: rgb(64, 34, 0);
            font-size: 14px;
        }

        .item-content {
            height: 35px;
            border: 0px solid rgb(0, 0, 0);
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 8px;

            .icon {
                width: 20px;
                height: 20px;
            }

            .text {
                color: rgba(0, 0, 0, 0.5);
                font-size: 12px;
            }

            .button-copy {
                width: 44px;
                height: 20px;
                font-size: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                /* 浅灰 */
                border: 1px solid rgb(185, 162, 125);
                border-radius: 29px;
                cursor: pointer;
                color: rgb(185, 162, 125);
                background: rgba(252, 246, 231, 0.5);

                &:hover {
                    border: none;
                    color: rgb(64, 34, 0);
                    background: linear-gradient(rgba(244, 218, 174, 1) 0%, rgba(234, 189, 109, 1) 100%);
                }
            }
        }
    }
}
</style>