<template>
    <div class="container-classification">
        <div class="swiper-classification">
            <swiper class="swiper-wrapper" :options="swiperOption">
                <swiper-slide class="swiper-slide" @click.prevent.native="viewClassification(item)"
                    v-for="(item, index) in list" :key="index">
                    <div class="info">
                        <p class="name">{{ item.name }}</p>
                        <div class="view" @click="viewClassification(item)">
                            <p>去看看</p>
                            <i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                    <img class="img" :src="item.imgUrl" alt="" />
                </swiper-slide>
            </swiper>
        </div>
        <div class="swiper-button-item swiper-button-prev">
            <i class="el-icon-arrow-left"></i>
        </div>
        <div class="swiper-button-item swiper-button-next">
            <i class="el-icon-arrow-right"></i>
        </div>
    </div>
</template>

<script>
import { getClassificationData } from '@/api/home'
export default {
    data() {
        return {
            swiper: null,
            list: [
                {
                    name: '会员拉新',
                    firstTypeId: 1,
                    secondTypeId: 1,
                    imgUrl: require('@/assets/images/vip/c1.png'),
                },
                {
                    name: '成交转化',
                    firstTypeId: 1,
                    secondTypeId: 2,
                    imgUrl: require('@/assets/images/vip/c2.png'),
                },
                {
                    name: '提升客单价',
                    firstTypeId: 1,
                    secondTypeId: 3,
                    imgUrl: require('@/assets/images/vip/c3.png'),
                },
                {
                    name: '店铺活动',
                    firstTypeId: 1,
                    secondTypeId: 4,
                    imgUrl: require('@/assets/images/vip/c4.png'),
                },
                {
                    name: '权益发放',
                    firstTypeId: 1,
                    secondTypeId: 5,
                    imgUrl: require('@/assets/images/vip/c5.png'),
                },
                {
                    name: '个性化推荐',
                    firstTypeId: 1,
                    secondTypeId: 6,
                    imgUrl: require('@/assets/images/vip/c6.png'),
                },
                {
                    name: '轮播类型',
                    firstTypeId: 3,
                    secondTypeId: 13,
                    imgUrl: require('@/assets/images/vip/c7.png'),
                },
                {
                    name: '卡盘类型',
                    firstTypeId: 1,
                    secondTypeId: 4,
                    imgUrl: require('@/assets/images/vip/c8.png'),
                },
                {
                    name: '加购类型',
                    firstTypeId: 3,
                    secondTypeId: 15,
                    imgUrl: require('@/assets/images/vip/c9.png'),
                },
                {
                    name: '动效动画',
                    firstTypeId: 3,
                    secondTypeId: 16,
                    imgUrl: require('@/assets/images/vip/c10.png'),
                },
                {
                    name: '滑动类型',
                    firstTypeId: 3,
                    secondTypeId: 16,
                    imgUrl: require('@/assets/images/vip/c11.png'),
                },
                {
                    name: '弹窗类型',
                    firstTypeId: 3,
                    secondTypeId: 17,
                    imgUrl: require('@/assets/images/vip/c12.png'),
                },
            ],
            swiperOption: {
                autoplay: { //自动开始
                    delay: 2500, //时间间隔
                    // disableOnInteraction: false, //*手动操作轮播图后不会暂停*
                },
                slidesPerView: 'auto',
                spaceBetween: 15,
                loop: true, // 循环模式选项
                // 如果需要前进后退按钮
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            }

        }
    },
    created() {
        this.getClassificationData()
    },
    methods: {
        // 获取所有分类 分配ID
        async getClassificationData() {
            try {
                const res = await getClassificationData()
                let list = this.list
                res.data.forEach((item, index) => {
                    item.secondTypes.forEach(node => {
                        list.forEach(card => {
                            if (node.title === card.name) {
                                // console.log(node.title)
                                card.firstTypeId = node.firstTypeId
                                card.secondTypeId = node.secondTypeId,
                                    card.index = index
                            }
                        })
                    })
                })
                this.list = list
                console.log(this.list, 'this.list')
            } catch (e) {
                // console.log(e)
            }
        },
        viewClassification(item) {
            this.$router.push({
                path: '/classification', query: {
                    type: item.firstTypeId,
                    nodeType: item.secondTypeId,
                    index: item.index,
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.container-classification {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
    padding: 0 50px 0 50px;
    position: relative;

    .swiper-wrapper {
        padding: 20px 0;
    }

    .swiper-classification {
        width: 100%;
        overflow: hidden;
        position: relative;
    }

    .swiper-button-item {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid #efefef;
        background: none;
        font-size: 32px;
        color: rgba(0, 0, 0, 0.5);
        transition: all .5s;

        &:hover {
            color: rgba(234, 189, 110, 1);
            border: 1px solid rgba(234, 189, 110, 1);
            transform: scale(1.1);
        }

        &::after {
            display: none !important;
        }
    }

    .swiper-button-prev {
        left: 0px;
    }

    .swiper-button-next {
        background: rgba(255, 255, 255, 0.9);
        right: 0;
    }

    .swiper-slide {
        width: 170px;
        height: 70px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: rgb(53, 53, 56);
        border-radius: 10px;
        padding: 0 15px;
        transition: transform .5s;

        &:hover {
            transform: translateY(-10px);
        }

        .name {
            color: rgb(228, 181, 98);
            font-family: Noto Sans SC;
            font-size: 18px;
            font-weight: 700;
        }

        .view {
            margin-top: 5px;
            width: 64px;
            height: 16px;
            /* 金色渐变2 */
            background: linear-gradient(to right, rgba(243, 217, 172, 1) 11.647%, rgba(234, 189, 110, 1) 100%);
            border-radius: 40px;
            /* 自动布局 */
            display: flex;
            justify-content: center;
            align-items: center;
            // padding: 0px 5px 1px 8px;
            color: rgba(0, 0, 0, 0.5);

            &:hover {
                opacity: 0.8;
            }

            p {
                font-size: 12px;
                line-height: 0;
            }

            .el-icon-arrow-right {
                margin-top: 1px;
                font-size: 10px;
                font-weight: bold;
            }
        }

        .img {
            width: 40px;
            height: 40px;
        }
    }
}
</style>