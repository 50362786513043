<template>
    <div class="container-service">
        <el-popover placement="top">
            <div class="popover-service-common" v-if="$store.state.identity!==2">
                <div class="item">
                    <img class="icon" src="../../assets/images/case/icon-dingding.png" alt="" />
                    <p class="text">15223386057</p>
                    <button class="button-copy" @click="handleCopy('15223386057', $event)">复制</button>
                </div>
                <div class="item">
                    <img class="icon" src="../../assets/images/case/icon-weChat.png" alt="" />
                    <p class="text">15223386057</p>
                    <button class="button-copy" @click="handleCopy('15223386057', $event)">复制</button>
                </div>
                <div class="item">
                    <img class="icon" src="../../assets/images/case/icon-phone.png" alt="" />
                    <p class="text">15223386057</p>
                    <button class="button-copy" @click="handleCopy('15223386057', $event)">复制</button>
                </div>
            </div>
            <div class="popover-service-vip" v-else>
                <p class="title">商务对接：{{$store.state.userInfo.businessName}}</p>
                <div class="item">
                    <div>
                        <img class="icon" src="../../assets/images/case/icon-weChat.png" alt="" />
                        <img class="icon" src="../../assets/images/case/icon-dingding.png" alt="" />
                    </div>
                    <p class="text">{{$store.state.userInfo.businessPhone}}</p>
                    <button class="button-copy"
                        @click="handleCopy($store.state.userInfo.businessPhone, $event)">复制</button>
                </div>
            </div>
            <div slot="reference">
                <slot></slot>
            </div>
        </el-popover>
    </div>
</template>

<script>
import { copy } from "@/utils/clipboard";
import { saveUserBrowseData } from '@/api/common'
export default {
    props: {
        saveCopyType: {
            type: Number,
            default: 0,
            required: false,
        },
    },
    data() {
        return {

        }
    },
    methods: {
        handleCopy(text, e) {
            copy(text, e);
            saveUserBrowseData({ typeId: this.saveCopyType })
        }
    }
}
</script>

<style lang="scss" scoped>
.popover-service-common {
    .item {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 220px;
        height: 35px;
        box-sizing: border-box;
        border-radius: 8px;

        &:hover {
            background: rgb(247, 247, 247);
        }

        .icon {
            width: 26px;
            height: 26px;
        }

        .text {
            color: rgba(0, 0, 0, 0.5);
            font-family: Noto Sans SC;
            font-size: 14px;
            font-weight: 400;

        }

        .button-copy {
            width: 44px;
            height: 22px;
            border: 1px solid rgba(0, 0, 0, 0.3);
            color: rgb(0, 0, 0);
            font-size: 12px;
            border-radius: 29px;
            background: none;
            cursor: pointer;

            &:hover {
                background: rgb(69, 79, 255);
                color: #fff;
            }
        }
    }
}

.popover-service-vip {
    text-align: center;

    .item {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 220px;
        // height: 35px;
        padding: 2px 0;
        box-sizing: border-box;
        border-radius: 8px;
        background: rgb(247, 247, 247);
        margin-top: 10px;

        .icon {
            width: 26px;
            height: 26px;
        }

        .button-copy {
            width: 44px;
            height: 22px;
            border: 1px solid rgb(185, 162, 125);
            color: rgb(185, 162, 125);
            font-size: 12px;
            border-radius: 29px;
            background: none;
            cursor: pointer;

            &:hover {
                background: rgb(185, 162, 125);
                color: #000;
            }
        }
    }
}
</style>