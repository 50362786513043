<template>
    <div class="item-swipe" :style="styles">
        <swiper class="swiper-wrapper" :options="swiperOption" @slideChange="slideChange" ref="mySwiper">
            <template v-for="(item, index) in detail.banner">
                <swiper-slide v-if="item.components && item.components[0]" :key="index"
                    class="swiper-slider swiper-item banner1">
                    <DecorationModuleCode :content="item"></DecorationModuleCode>
                </swiper-slide>
                <swiper-slide v-else :key="index" class="swiper-slider swiper-item banner1">
                    <img :src="item.img" alt="">
                </swiper-slide>
            </template>
        </swiper>
        <div class="switch" v-if="detail.arrow.switch === 'true'">
            <div class="item diyswipe-prev"
                :style="{ width: `${detail.arrow.width}px`, height: `${detail.arrow.height}px`, left: `${detail.arrow.l.left}px`, top: `${detail.arrow.l.top}px` }">
                <img :src="detail.arrow.l.img" alt="">
            </div>
            <div class="item diyswipe-next"
                :style="{ width: `${detail.arrow.width}px`, height: `${detail.arrow.height}px`, left: `${detail.arrow.l.left}px`, left: `${detail.arrow.r.left}px`, top: `${detail.arrow.r.top}px` }">
                <img :src="detail.arrow.r.img" alt="">
            </div>
        </div>
        <div class="pagination-diy" v-if="detail.banner[0].tr_img">
            <div class="item" v-for="(item, index) in detail.banner" @click="onPaginationChange(index)"
                :style="{ left: `${item.left}px`, top: `${item.top}px` }" :key="index">
                <DecorationModuleCode v-if="item.tr_config.components && item.tr_config.components[0]">
                </DecorationModuleCode>
                <img v-else-if="activeIndex === index" :src="item.tr_active_img" alt="">
                <img v-else :src="item.tr_img" alt="">
            </div>
        </div>

        <div class="pagination swiper-pagination"
            v-else
            :style="{ justifyContent: detail.pagination.position === 'br' ? 'flex-end' : detail.pagination.position === 'bl' ? 'flex-start' : 'center' }">
            <div class="item" @click="onPaginationChange(index)"
                :style="activeIndex === index ? itemActivedStyles : itemStyles" v-for="(item, index) in detail.banner"
                :key="index"></div>
        </div>

    </div>
</template>
<script>
const DecorationModuleCode = () => import('@/components/decorationModuleCode/index.vue')
// 创意轮播
export default {
    name: 'Diyswipe',
    components: {
        DecorationModuleCode
    },
    props: {
        detail: {
            type: Object,
            default: {},
            required: true,
        }
    },

    data() {
        return {
            styles: {
                // position: 'absolute',
                width: `${this.detail.width}px`,
                // height: `${this.detail.height}px`,
                left: `${this.detail.left}px`,
                top: `${this.detail.top}px`,
                opacity: `${this.detail.opacity}`,
                borderRadius: `${this.detail.bR}px`,
            },
            itemStyles: {
                width: `${this.detail.pagination.old.w}px`,
                height: `${this.detail.pagination.old.h}px`,
                borderRadius: `${this.detail.pagination.old.br}px`,
                backgroundColor: `${this.detail.pagination.old.color}`,
                marginRight: `${this.detail.pagination.spacing}px`
            },
            itemActivedStyles: {
                width: `${this.detail.pagination.active.w}px`,
                height: `${this.detail.pagination.active.h}px`,
                borderRadius: `${this.detail.pagination.active.br}px`,
                backgroundColor: `${this.detail.pagination.active.color}`,
                marginRight: `${this.detail.pagination.spacing}px`
            },
            activeIndex: 0,
            swiperOption: {
                // effect: 'fade',
                loop: true,
                direction: this.detail.ver === '0' ? 'horizontal' : 'vertical',
                autoplay: {
                    autoplay: this.detail.auto === 'true' ? true : false,
                    delay: this.detail.playtime ? parseInt(this.detail.playtime) * 1000 : 7000
                },
                navigation: {
                    nextEl: ".diyswipe-next",
                    prevEl: ".diyswipe-prev"
                },
                pagination: {
                    el: ".swiper-pagination",
                    dynamicBullets: true,
                    clickable: true,
                },
            },
        }
    },

    created() {
        console.log(this.detail, 'this.detail---diyswipe')
    },
    computed: {
        swiper() {
            return this.$refs.mySwiper.$swiper;
        }
    },
    methods: {
        // 跳转链接
        onNavigation() {
            if (this.detail.url) {
                window.open(this.detail.url, '_blank')
            }
        },
        onPaginationChange(index) {
            // console.log(index)
            this.activeIndex = index
            this.swiper.slideToLoop(index, 0, true)
        },
        slideChange(e) {
            console.log(this.swiper.realIndex, 'this.swiper.realIndex')
            const realIndex = this.swiper.realIndex;
            this.activeIndex = realIndex
        },
    },
}
</script>

<style lang="scss" scoped>
.item-swipe {
    position: relative;

    .pagination {
        width: 100% !important;
        position: absolute;
        bottom: 20px;
        z-index: 999;
        display: flex;
        padding: 0 20%;
        z-index: 99;

        .item {
            position: absolute;
        }
    }

    .pagination-diy {
        .item {
            position: absolute;
        }
    }

    .switch {
        position: absolute;
        top: 0;
        z-index: 999;

        .item {
            position: absolute;
        }
    }
}


.swiper-wrapper {
    width: 100%;
    height: 100%;

    img {
        width: 100%;
        height: 100%;
    }
}
</style>