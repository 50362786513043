<template>
    <div class="container-course-detail"
        :class="$store.state.identity === 2 ? 'container-course-detail-vip' : 'container-course-detail-common'">
        <div class="box-content">
            <div class="top">
                <div class="title">
                    {{ detail.primaryTitle }}
                </div>
                <div class="title-b">
                    {{ detail.secondaryTitle }}
                </div>
            </div>
            <div class="info">
                <!-- <div class="back" @click="onBack()">
                    <i class=" el-icon-back"></i>
                    <p>返回</p>
                </div> -->
                <div class="data">
                    <div class="eyes">
                        <svg t="1664267202193" class="icon" viewBox="0 0 1024 1024" version="1.1"
                            xmlns="http://www.w3.org/2000/svg" p-id="4369" width="22" height="22">
                            <path d="M419.143 512a92.857 92.857 0 1 0 185.714 0 92.857 92.857 0 1 0-185.714 0z"
                                fill="#7F7F7F" p-id="4370"></path>
                            <path
                                d="M512 257.238C231.048 257.238 62 512 62 512s169.048 254.762 450 254.762C792.159 766.762 962 512 962 512S792.159 257.238 512 257.238z m0 433.333c-98.413 0-178.571-80.158-178.571-178.571S413.587 333.429 512 333.429 690.571 413.587 690.571 512 610.413 690.571 512 690.571z"
                                fill="#7F7F7F" p-id="4371"></path>
                        </svg>
                        <p class="text">{{ detail.browseNum }}</p>
                    </div>
                    <div v-if="$store.state.userInfo" class="eyes" :class="detail.islike?'like':''" @click="onLike">
                        <svg t="1664266760214" class="icon" viewBox="0 0 1024 1024" version="1.1"
                            xmlns="http://www.w3.org/2000/svg" p-id="2579" width="16" height="16">
                            <path
                                d="M64 483.04V872c0 37.216 30.144 67.36 67.36 67.36H192V416.32l-60.64-0.64A67.36 67.36 0 0 0 64 483.04zM857.28 344.992l-267.808 1.696c12.576-44.256 18.944-83.584 18.944-118.208 0-78.56-68.832-155.488-137.568-145.504-60.608 8.8-67.264 61.184-67.264 126.816v59.264c0 76.064-63.84 140.864-137.856 148L256 416.96v522.4h527.552a102.72 102.72 0 0 0 100.928-83.584l73.728-388.96a102.72 102.72 0 0 0-100.928-121.824z"
                                p-id="2580"
                                :fill="detail.islike?$store.state.identity===2?'rgb(234, 189, 110)':'rgb(69, 79, 255)':'#7F7F7F'">
                            </path>
                        </svg>
                        <p class="text">{{ detail.likeNum }}</p>
                    </div>
                </div>
                <div class="right">
                    <p>{{detail.createTime}}</p>
                </div>
            </div>
            <div class="content">
                <div class="content-html"  v-html="fullContent">
                </div>
                <!-- <div class="content-html" v-else v-html="partialContent">
                </div>

                <div class="tip-login" v-if="!$store.state.userInfo">
                    <i @click="onLogin" class="el-icon-d-arrow-right" style="font-size: 32px; transform: rotate(90deg);"></i>
                    <div @click="onLogin">登录后查看更多内容</div>
                </div> -->
                <div class="footer">
                    <div class="line"></div>
                    <p class="tip">文章版权为冠美所有，转载请在文章开头和结尾显眼处标注出处与链接。</p>
                </div>
            </div>

            <div class="bottom" v-if="$store.state.userInfo">
                <div class="like" @click="onLike">
                    <svg t="1664266760214" class="icon" viewBox="0 0 1024 1024" version="1.1"
                        xmlns="http://www.w3.org/2000/svg" p-id="2579" width="22" height="22">
                        <path
                            d="M64 483.04V872c0 37.216 30.144 67.36 67.36 67.36H192V416.32l-60.64-0.64A67.36 67.36 0 0 0 64 483.04zM857.28 344.992l-267.808 1.696c12.576-44.256 18.944-83.584 18.944-118.208 0-78.56-68.832-155.488-137.568-145.504-60.608 8.8-67.264 61.184-67.264 126.816v59.264c0 76.064-63.84 140.864-137.856 148L256 416.96v522.4h527.552a102.72 102.72 0 0 0 100.928-83.584l73.728-388.96a102.72 102.72 0 0 0-100.928-121.824z"
                            p-id="2580" :fill="'#FFF'">
                        </path>
                    </svg>
                    <p>{{ detail.likeNum }}</p>
                </div>
                <div class="text">觉得不错的话，别忘了点个赞鼓励一下~</div>
            </div>

        </div>
    </div>
</template>

<script>
import { getCustomDetail ,getCustomDetailNone} from '@/api/course'
import { onLike } from '@/api/operation'
export default {
    data() {
        return {
            relevantCourseList: [],
            id: null,
            // typeId: null,
            detail: {},
            fullContent:"",
            partialContent:""
        }
    },
    created() {
        if (this.$route.query.id) {
            this.id = this.$route.query.id
            // this.typeId = this.$route.query.typeId
        }
        if(this.$store.state.userInfo){
        this.getCustomDetail()
        }else{
            this.getCustomDetailNone()
        }
    },

    methods: {

        onBack() {
            this.$router.back()
        },

        // 登录
        onLogin(){
            this.$store.commit('onShowLogindialog', true)
            
        },
        // 点赞
        async onLike() {
            if(this.detail.islike){
                this.$message.warning('你已经赞过啦~')
                return false
            }
            try {
                const res = await onLike(this.id)
                this.getCustomDetail()
            } catch (e) {

            }
        },

        // 获取详情
        async getCustomDetail() {
            try {
                const res = await getCustomDetail(this.id)
                res.data.content = res.data.content.replace(new RegExp('poster', 'g'), 'poster1');
                this.fullContent  = res.data.content
                this.detail = res.data
            } catch (e) {
            }
        },
        
         // 获取详情
         async getCustomDetailNone() {
            try {
                const res = await getCustomDetailNone(this.id)
                res.data.content = res.data.content.replace(new RegExp('poster', 'g'), 'poster1');
                this.fullContent  = res.data.content
                this.partialContent = this.fullContent.substring(0, Math.floor(this.fullContent.length / 3));

                this.detail = res.data
            } catch (e) {
            }
        },

    },
}
</script>

<style lang="scss" scoped>
.container-course-detail-vip {
    .back {
        background: rgb(252, 246, 231);
    }
}

.container-course-detail-common {
    .back {
        background: rgb(235, 235, 235);
    }
}

.container-course-detail {
    min-height: calc(100vh - 70px);
    padding-top: 30px;
    background: var(--BG);

    .box-content {
        width: 100%;
        max-width: 1000px;
        margin: 0 auto;
    }
}


.box-content {
    padding: 20px 40px;
    background: #fff;
    border-radius: 10px;
    .info {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .back {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100px;
            height: 30px;
            border-radius: 6px;
            color: rgba(0, 0, 0, 0.5);
            font-family: Noto Sans SC;
            font-size: 14px;
            cursor: pointer;

            &:hover {
                opacity: .8;
            }

            p {
                margin-left: 4px;
            }
        }

        .data {
            display: flex;
            align-items: center;
            color: #747477;
            font-size: 12px;

            .el-icon {
                font-size: 16px;
            }

            .like {
                p {
                    color: var(--colorB) !important;
                }
            }

            .eyes {
                width: 63px;
                display: flex;
                align-items: center;
                cursor: pointer;

                &:hover {
                    opacity: .7;
                }

                p {
                    margin-left: 2px;
                }
            }

            .collection {
                width: 63px;
                display: flex;
                align-items: center;

                p {
                    margin-left: 2px;
                }
            }
        }
    }

    .top {
        // display: flex;
        // align-items: center;
        // justify-content: space-between;
        color: rgb(0, 0, 0);

        .title {
            font-size: 30px;
        }

        .title-b {
            font-size: 16px;
            color: #999;
        }


        .collection {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 70px;
            height: 35px;
            border: 1px solid var(--colorD);
            background: #fff;
            color: var(--colorA);
            border-radius: 6px;
            cursor: pointer;

            p {
                line-height: 0;
            }

            &:hover {
                opacity: .8;
            }

            .el-icon {
                font-size: 20px;
            }
        }
    }

    .content {
        padding: 30px 0;
        position: relative;
        .tip-login{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            color: #666;
            height: 100px;
            position: relative;
            top: -100px;
            background: #fff; 
            // background: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
        }

        .content-html {
            min-height: 600px;
        }

        .footer {
            margin-top: 15px;

            .line {
                border-bottom: 1px solid rgb(212, 212, 212);
            }

            .tip {
                color: rgba(0, 0, 0, 0.3);
                font-family: Noto Sans SC;
                font-size: 12px;
                font-weight: 400;
                margin-top: 10px;
            }
        }
    }

    .bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .like {
            width: 80px;
            height: 80px;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            background: var(--colorB);
            border-radius: 40px;
            cursor: pointer;

            &:hover {
                opacity: .8;
            }

            P {
                color: #fff;

                font-size: 14px;
                font-weight: 400;
                // line-height: 21px;
            }
        }

        .text {
            color: rgb(89, 86, 86);
            font-size: 14px;
            font-weight: 400;
            margin-top: 10px;
        }
    }
}
</style>