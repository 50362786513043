
<template>
    <footer class="container-footer"
        :class="$store.state.identity === 2 ? 'container-footer-vip' : 'container-footer-common'">
        <div class="content">

            <div class="logo-vip" v-if="$store.state.identity === 2">
                <img class="logo" src="../assets/images/footer/footer-logo-vip.png" alt="">
                <div class="company"></div>
            </div>

            <div class="logo-vip" v-else>
                <img class="logo" src="../assets/images/footer/footer-logo.png" alt="">
                <div class="company"></div>
            </div>
            <ul class="list-anchor">
                <li @click="openNewWebsite('https://www.guanmeikj.com/index/introduction.html')">
                    <!-- <a href="http://www.guanmeikj.com/women/"></a> -->
                    <p>公司简介</p>
                </li>
                <li @click="openNewWebsite('https://www.guanmeikj.com/index/recruitment.html')"> 
                    <p>精英招募</p>
                    <!-- <a href="http://www.guanmeikj.com/job/"></a> -->   
                </li>
                <li @click="openNewWebsite('http://container.open.taobao.com/container?appkey=23082561&encode=utf-8')">
                    <p>无线宝</p>
                  
                </li>
                <li @click="openNewWebsite('https://www.guanmeikj.com/index/Page/index.html?cate=21')">
                    <p>互动营销</p>
                    <!-- <a href="http://www.guanmeikj.com/hudong/">互动营销</a> -->
                </li>
                <li @click="openNewWebsite('https://www.guanmeikj.com/index/Page/index.html?cate=20')">
                    <p>店铺装修</p>
                    <!-- <a href="http://www.guanmeikj.com/dianpu/">店铺装修</a> -->
                </li>
            </ul>
        </div>
        <div class="bottom">
            <p>版权所有©重庆冠美科技有限公司 渝ICP备14000400</p>
            <p>
                <img class="icon" src="../assets/images/footer/icon-2.png" alt="">
                <span>渝公网安备 50011202500466号</span>
            </p>
            <p>邮箱：guanmei@guanmeikj.com</p>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'footerView',
    data() {
        return {

        }
    },

    mounted() {

    },

    methods: {
        // 跳转外部链接
        openNewWebsite(url) {
            window.open(url, '_blank');
        },
        setGif() {
            let ht = ''
            for (let i = 0; i < 25; i++) {
                //2.85 计算37张 1张需要的动画百分比
                ht += `
			${4 * i + 1}% {
			 background: url('../assets/images/footer/GM_(${i + 1}).png')  ;
			}
		`
            }
            let keyframes = `
		@keyframes run-gif{
			${ht}
		}
	`
            var S = document.createElement('style');
            S.innerHTML = keyframes;
            dChild(S);
        }
    }
}
</script>

<style lang="scss" scoped>
.container-footer-common {
    background: url('../assets/images/footer/footer-bg.png');
    background-size: cover;

    .content {
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }

    .list-anchor {
        li {
            &:hover {
                opacity: .6;
            }
        }

        li::after {
            background: #000;

        }

        p {
            color: #000;

        }
    }

    .bottom {
        p {
            color: rgb(110, 110, 110);
        }
    }
}

.container-footer-vip {
    background: rgb(31, 31, 31);
    backdrop-filter: blur(500px);

    .content {
        border-bottom: 1px solid rgb(185, 162, 125);
    }

    .list-anchor {
        li::after {
            background: rgba(234, 189, 110, 1);
        }

        p {
            color: #fff;
        }

    }

    .bottom {
        p {


            color: #fff;
        }
    }
}

.container-footer {
    height: 209px;
    height: 209px;

    .content {
        height: 165px;
        display: flex;
        align-items: center;
        justify-content: center;

        .logo {
            margin-right: 149px;
            width: 298px;
        }

        .logo-vip {
            position: relative;

            .company {
                position: absolute;
                top: 1px;
                left: -5px;
                width: 65px;
                height: 62px;
                animation-duration: 3s;
                animation-direction: alternate;
                animation-iteration-count: infinite;
                animation-name: run-gif;
                background-size: contain;
                // background:url('../assets/images/footer/GM_(1).png');


            }
        }
    }

    .bottom {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 44px;

        p {
            font-family: Noto Sans SC;
            font-size: 12px;
            font-weight: 400;
            display: flex;
            align-items: center;

            &:nth-child(2) {
                margin: 0 15px;
            }

            .icon {
                width: 14px;
                height: 14px;
            }
        }
    }


    .list-anchor {
        display: flex;
        align-items: center;

        li {
            cursor: pointer;
            margin-right: 50px;
            padding-left: 10px;
            position: relative;

            &::after {
                content: '';
                position: absolute;
                width: 5px;
                height: 5px;
                border-radius: 50%;
                left: 0;
                top: 50%;
                margin-top: -2.5px;
            }

            &:hover {
                opacity: .6;
            }

            p {
                font-size: 14px;

            }
        }
    }

}

@keyframes run-gif {
    0% {
        background: url('../assets/images/footer/GM_(1).png');
        background-size: contain;

    }

    5% {
        background: url('../assets/images/footer/GM_(2).png');
        background-size: contain;


    }

    9% {
        background: url('../assets/images/footer/GM_(3).png');
        background-size: contain;


    }

    13% {
        background: url('../assets/images/footer/GM_(4).png');
        background-size: contain;


    }

    17% {
        background: url('../assets/images/footer/GM_(5).png');
        background-size: contain;


    }

    21% {
        background: url('../assets/images/footer/GM_(6).png');
        background-size: contain;

    }

    25% {
        background: url('../assets/images/footer/GM_(7).png');
        background-size: contain;

    }

    29% {
        background: url('../assets/images/footer/GM_(8).png');
        background-size: contain;

    }

    33% {
        background: url('../assets/images/footer/GM_(9).png');
        background-size: contain;

    }

    37% {
        background: url('../assets/images/footer/GM_(10).png');
        background-size: contain;

    }

    41% {
        background: url('../assets/images/footer/GM_(11).png');
        background-size: contain;

    }

    45% {
        background: url('../assets/images/footer/GM_(12).png');
        background-size: contain;

    }

    49% {
        background: url('../assets/images/footer/GM_(13).png');
        background-size: contain;

    }

    53% {
        background: url('../assets/images/footer/GM_(14).png');
        background-size: contain;

    }

    57% {
        background: url('../assets/images/footer/GM_(15).png');
        background-size: contain;

    }

    61% {
        background: url('../assets/images/footer/GM_(16).png');
        background-size: contain;

    }

    65% {
        background: url('../assets/images/footer/GM_(17).png');
        background-size: contain;

    }

    69% {
        background: url('../assets/images/footer/GM_(18).png');
        background-size: contain;

    }

    73% {
        background: url('../assets/images/footer/GM_(19).png');
        background-size: contain;

    }

    77% {
        background: url('../assets/images/footer/GM_(20).png');
        background-size: contain;

    }

    81% {
        background: url('../assets/images/footer/GM_(21).png');
        background-size: contain;

    }

    85% {
        background: url('../assets/images/footer/GM_(22).png');
        background-size: contain;

    }

    89% {
        background: url('../assets/images/footer/GM_(23).png');
        background-size: contain;

    }

    93% {
        background: url('../assets/images/footer/GM_(24).png');
        background-size: contain;

    }

    100% {
        background: url('../assets/images/footer/GM_(25).png');
        background-size: contain;

    }
}
</style>