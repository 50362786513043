<template>
    <el-dialog :visible.sync="visible" :close-on-click-modal="false" custom-class="container-dialog" :show-close="false"
        width="368px">
        <div class="content">
            <CaseItem :detail="detail" :hiddenData="true"></CaseItem>
        </div>
        <Close :visible.sync="visible" @onClose="onClose"></Close>
    </el-dialog>
</template>
<script>
import CustomerService from '@/components/customerService'
import { copy } from "@/utils/clipboard";
import { saveBrowse } from '@/api/common'
import { collectionByType, removeCollection } from '@/api/case'
import Close from '@/components/close'
import OnlineEdit from '@/components/case/onlineEdit.vue'
import { saveEditRecord } from '@/api/case'
import CaseItem from '@/components/case/caseItem.vue'
export default {
    components: {
        CustomerService,
        Close,
        CaseItem,
        OnlineEdit
    },
    inject: {
        setChildrenRef: { // 注册子组件
            default: () => { },
        },
        getParentRef: {  // 获取根组件实例
            from: "getRef",
            default: () => { },
        },
        getParentChildrenRef: {  // 获取根组件链下任何注册的子组件
            from: "getChildrenRef",
            default: () => { },
        },
    },
    props: {
        visible: {
            type: Boolean,
            default: true,
            required: true,
        },
        detail: {
            type: Object,
            default: () => { return {} },
            required: false,
        },
    },
    data() {
        return {
            controls: false,
            onlineEditVisible: false,
        }
    },
    created() {
        console.log(this.detail, 'detail')
    },
    methods: {
        //保存浏览记录
        async onSaveBrowse() {
            try {
                const params = {
                    type: 0,
                    "id": this.detail.caseId
                }
                const res = await saveBrowse(params)
                this.getParentRef().getList()

            } catch (e) {
                console.log(e)
            }
        },
        operateVideo() {
            this.controls = true
            this.onSaveBrowse()
            this.$refs.myVideo.play()
        },
        onClose() {
            this.$emit('update:visible', false);
        },
        handleCopy(text, e) {
            copy(text, e);
        },
        onCollection() {
            if (this.$store.state.identity === 0) {
                this.$store.commit('onShowLogindialog', true)
                return false
            }
            if (this.detail.collectionId) {
                this.onCancelCollection()
            } else {
                this.onSaveCollection()
            }
        },
        // 在线编辑
        onlineEdit() {
            this.onSaveEditRecord()
            this.onlineEditVisible = true
        },

        //保存编辑记录
        async onSaveEditRecord() {
            try {
                const res = await saveEditRecord(this.detail.caseId)
            } catch (e) {
                console.log(e)
            }
        },

        // 收藏案例
        async onSaveCollection() {
            const data = {
                "type": 0,
                "id": this.detail.caseId
            }
            try {
                const res = await collectionByType(data)
                this.detail.collectionId = 1
                this.getParentRef().getList()

            } catch (e) {
                // this.$message.error(e.message)
            }
        },

        openCaseModelB() {
            console.log(1231)
            this.$parent.caseModelBdialogVisible = true
        },

        // 取消收藏案例
        async onCancelCollection() {
            const data = {
                "type": 0,
                "id": this.detail.caseId
            }
            try {
                const res = await removeCollection(data)
                this.detail.collectionId = null
                this.getParentRef().getList()

            } catch (e) {
                // this.$message.error(e.message)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.content {
    background: #fff;
    margin: 0 auto;
    padding: 40px 0;
    // justify-content: center;
    width: 258px;
    height: 100%;

    .box-video {
        width: 258px;
        height: 550px;
        border: 1px solid rgb(244, 244, 244);
        border-radius: 10px;
        overflow: hidden;
        position: relative;

        .video {
            width: 100%;
            height: 100%;
        }

        .play {
            position: absolute;
            height: 100%;
            width: 100%;
            background: rgba(0, 0, 0, .4);
            display: flex;
            align-items: center;
            justify-content: center;
            left: 0;
            top: 0;
        }
    }

    .box-innovate {
        position: absolute;
        z-index: 99;
        top: 0px;
        width: 75px;
        height: 72px;
        overflow: hidden;
        left: 0px;

        .innovate {
            width: 92px;
            height: 24px;
            color: rgb(255, 255, 255);
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: rgb(255, 255, 255);
            background: linear-gradient(to left top, rgba(254, 220, 68, 1) 0%, rgba(250, 112, 154, 1) 100%);
            transform: rotate(-45deg) translateX(-24px) translateY(-6px);
        }
    }

    .info-A {
        width: 100%;
        display: flex;
        align-items: center;
        margin: 10px 0;

        .tags {
            display: flex;
            align-items: center;
            color: rgb(255, 255, 255);
            font-size: 12px;

            .tags-item {
                padding: 1px 3px;
                border-radius: 2px;
                min-width: 30px;
                border-radius: 4px;

                &:nth-child(1) {
                    background: linear-gradient(rgba(253, 85, 144, 1) 0%, rgba(247, 109, 102, 1) 100%);
                    margin-right: 3px;
                }

                &:nth-child(2) {
                    background: linear-gradient(rgba(59, 210, 115, 1) 0%, rgba(51, 222, 183, 1) 100%);
                }
            }
        }

        .title {
            flex: 1;
            color: rgb(0, 0, 0);
            font-size: 14px;
            margin-left: 6px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .info-b {

        display: flex;
        align-items: center;
        justify-content: space-between;

        .data {
            display: flex;
            align-items: center;
            color: var(--colorA);
            font-size: 12px;

            .el-icon {
                font-size: 16px;
            }

            .eyes {
                width: 63px;
                display: flex;
                align-items: center;

                p {
                    margin-left: 2px;
                }
            }

            .collection {
                width: 63px;
                display: flex;
                align-items: center;

                p {
                    margin-left: 2px;
                }
            }
        }

        .collection-active {
            // background: #eaeaea;
        }

        .right {
            display: flex;
            align-items: center;

            .collection {
                width: 25px;
                height: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 10px;
                border: 1px solid var(--colorD);
                border-radius: 4px;
                color: var(--colorA);
                font-size: 16px;
                cursor: pointer;

                &:hover {
                    opacity: .8;
                }
            }

            button {
                width: 90px;
                height: 25px;
                border: 1px solid var(--colorD);
                border-radius: 4px;
                color: var(--colorA);
                font-size: 12px;
                background: none;
                font-family: Noto Sans SC;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;

                &:hover {
                    border: none;
                    color: var(--colorC);
                    background: var(--colorB);
                }

                .el-icon {
                    font-size: 14px;
                    margin-right: 2px;
                    margin-top: 2px;
                }
            }
        }

    }

    .info {
        flex: 1;
        height: 558px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .info-content {
            .top {
                display: flex;
                flex-direction: column;
                align-items: center;

                .title {
                    color: rgb(0, 0, 0);
                    font-size: 24px;
                }

                .type {
                    margin-top: 10px;
                    width: 90px;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 20px;
                    font-size: 14px;
                    position: relative;

                    .innovate {
                        position: absolute;
                        z-index: 99;
                        position: absolute;
                        width: 56px;
                        height: 18px;
                        top: -9px;
                        right: -40px;
                        color: rgb(255, 255, 255);
                        font-size: 12px;
                        text-align: center;
                        line-height: 18px;
                        color: rgb(255, 255, 255);
                        background: linear-gradient(to left top, rgba(254, 220, 68, 1) 0%, rgba(250, 112, 154, 1) 100%);
                        border-radius: 9px;
                    }
                }
            }

            .price {
                margin: 18px 0;
                height: 80px;
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;

                .tag {
                    padding: 0px 5px;
                    position: absolute;
                    left: 0;
                    top: 0;
                    font-size: 12px;
                    border-radius: 8px 0px 8px 0px;
                }

                .text {
                    display: flex;
                    align-items: flex-end;
                    color: rgb(235, 28, 38);
                    font-weight: bold;

                    del {
                        font-size: 18px;
                        line-height: 22px;
                    }

                    p {
                        font-size: 28px;
                        line-height: 28px;
                        margin: 0 5px;
                    }

                    .start {
                        color: rgba(0, 0, 0, 0.5);
                        font-size: 14px;
                        font-weight: 400;

                        text-align: center;
                        text-decoration-line: underline;

                    }
                }

            }

            .item {
                padding: 6px 0;

                .label {
                    color: rgba(0, 0, 0, 0.5);
                    font-size: 12px;
                }

                .text {
                    margin-top: 10px;
                    flex: 1;
                    color: rgba(0, 0, 0, 1);
                    line-height: 18px;
                }
            }

        }

        .bottom {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .button {
                display: flex;
                align-items: center;

                .button-consult {
                    width: 262px;
                    height: 40px;
                    color: #fff;
                    font-weight: bold;
                    border-radius: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 18px;
                    border: none;
                    cursor: pointer;

                    &:hover {
                        opacity: .7;
                    }
                }

                .collection {
                    width: 40px;
                    height: 40px;
                    border-radius: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 20px;
                    margin-left: 5px;

                    &:hover {
                        opacity: .7;
                    }

                    .icon {
                        width: 25px;
                    }
                }
            }

            .tip {
                color: rgb(185, 162, 125);
                font-size: 14px;
                margin-top: 8px;
                display: flex;
                align-items: center;

                .more {
                    font-size: 12px;
                    // width: 66px;
                    padding: 0px 6px;
                    height: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #000;
                    background: linear-gradient(to right, rgba(243, 217, 172, 1) 11.647%, rgba(234, 189, 110, 1) 100%);
                    border-radius: 10px;
                    margin-left: 5px;

                    cursor: pointer;

                    &:hover {
                        opacity: .7;
                    }
                }

                .text-upgrade {
                    cursor: pointer;

                    &:hover {
                        opacity: .7;
                    }
                }
            }


        }

        .bottom-vip {
            padding-bottom: 20px;
        }
    }



    .close {
        position: absolute;
        top: 14px;
        right: 14px;
        cursor: pointer;
    }


}

.content-vip {
    .info-content {
        .price {

            background: rgb(252, 246, 231);
            /* 金色渐变2 */
            border: 1px solid rgba(234, 189, 110, 1);


            .tag {

                color: rgb(64, 34, 0);

                background: linear-gradient(to right, rgba(234, 188, 109, 1) 0%, rgba(244, 218, 175, 1) 100%);

            }

        }

        .type {
            background: rgb(252, 246, 231);
            color: rgb(64, 34, 0);
        }
    }

    .bottom {
        .button-consult {
            background: linear-gradient(to right, rgba(243, 217, 172, 1) 11.647%, rgba(234, 189, 110, 1) 100%);
        }

        .collection {
            border: 1px solid rgb(234, 221, 204);
            color: rgb(185, 162, 125);
        }
    }
}

.content-common {
    .info-content {
        .price {

            background: rgb(247, 247, 247);
            /* 金色渐变2 */
            border: 1px solid rgba(31, 209, 250, 1);


            .tag {

                color: #fff;
                background: linear-gradient(to right, rgba(69, 79, 255, 1) 0%, rgba(31, 209, 250, 1) 99.237%);

            }

        }

        .type {
            background: rgba(69, 79, 255, 0.1);
            color: rgb(69, 79, 255);
        }
    }

    .bottom {
        .button-consult {
            background: rgb(69, 79, 255);
        }

        .collection {
            border: 1px solid rgb(232, 232, 232);
            color: rgba(0, 0, 0, 0.5);

        }
    }
}
</style>

<style lang="scss">
.container-dialog {
    border-radius: 20px !important;
    width: 709px;
    // height: 638px;
    position: relative;
    overflow: hidden;

    .el-dialog__header {
        display: none !important;
    }

    .el-dialog__body {
        padding: 0;
    }
}
</style>