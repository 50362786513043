import axios from '@/service/axios'

// 获取教程详情
export function getCourseDetail(params) {
    return axios.get(`/decorate/getById/${params}`)
}


// // 获取教程相关案例
// export function getRelevantCase(params) {
//     return axios.get(`/decorate/getById/${params}`)
// }

// 获取装修教程类别
export function getCourseType(params){
    return axios.get(`/decorate-type/getAll`)
}


// 获取会员运维内容
export function getCustomDetail(params){
    return axios.get(`custom/getById/${params}`,{},false)
}


// 获取会员运维内容-未登录状态
export function getCustomDetailNone(params){
    return axios.get(`custom/getByIdPublic/${params}`,{},false)
}
