<template>
    <div class="container-classification">
        <div class="classification-item">
            <div class="icon">
                <img src="../../../assets/images/home/classification-1.png" alt="">
            </div>
            <ul class="list" v-if="classificationData.length>0&&classificationData[1].secondTypes">
                <li class="item"
                    @click="onClassificationItemclick(classificationData[1].firstTypeId, item.secondTypeId,1)"
                    v-for="(item, index) in classificationData[1].secondTypes.slice(0, 6)" :key="index">
                    <p>{{ item.title }}</p>
                </li>
            </ul>
        </div>

        <div class="classification-item">
            <div class="icon">
                <img src="../../../assets/images/home/classification-2.png" alt="">
            </div>
            <ul class="list" v-if="classificationData.length>1&&classificationData[2].secondTypes">
                <li class="item"
                    @click="onClassificationItemclick(classificationData[2].firstTypeId, item.secondTypeId,2)"
                    v-for="(item, index) in classificationData[2].secondTypes.slice(0, 6)" :key="index">
                    <p>{{ item.title }}</p>

                </li>
            </ul>
        </div>
        <div class="classification-item">
            <div class="icon">
                <img src="../../../assets/images/home/classification-3.png" alt="">
            </div>
            <ul class="list">
                <li class="item"
                    @click="onClassificationItemclick(classificationData[3].firstTypeId, item.secondTypeId,3)"
                    v-for="(item, index) in classificationData[3].secondTypes.slice(0, 6)" :key="index">
                    <p>{{ item.title }}</p>

                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { getClassification } from '@/api/home'
export default {
    data() {
        return {
            classificationData: [],
        }
    },
    created() {
        console.log(21212121212121212121212121)
        this.getClassificationData()
    },
    methods: {
        async getClassificationData() {
            try {
                const res = await getClassification()
                console.log(res,21212121212121212121212121)
                this.classificationData = res.data
            } catch (e) {

            }
        },
        onClassificationItemclick(type, nodeType, index) {
            this.$router.push({
                path: `/classification`, query: {
                    type: type,
                    nodeType: nodeType,
                    index: index
                }
            })
        }
    }
}

</script>

<style lang="scss" scoped>
.container-classification {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    margin-bottom: 50px;

    .classification-item {
        padding: 20px;
        width: 32%;
        height: 110px;
        background: rgb(255, 255, 255);
        border: 1px solid rgb(244, 244, 244);
        border-radius: 10px;
        display: flex;
        align-items: center;

        .list {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            margin-left: 20px;

            .item {
                width: 33.33%;
                height: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: rgba(0, 0, 0, 0.5);
                font-size: 14px;
                cursor: pointer;
                margin: 3px 0;
                border-radius: 20px;
                position: relative;
                transition: all 0.2s ease;

                // &::before {
                //     position: absolute;
                //     display: block;
                //     content: '';
                //     top: 0;
                //     left: 0;
                //     border-radius: 20px;    
                //     width: 100%;
                //     height: 100%;
                //     opacity: 0;

                //     &:hover {
                //         opacity: 1;

                //     }

                //     // z-index: -1;
                // }

                // p {
                //     color: #fff;
                //     z-index: 999;
                // }

                &:hover {
                    background: rgb(69, 79, 255);
                    border-radius: 20px;
                    color: #fff;
                    font-weight: 500;
                }
            }
        }
    }
}
</style>