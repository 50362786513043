<template>
    <div class="container-info">
        <div class="info">
            <div class="info-none" v-if="$store.state.identity === 0">
                <p class="text-1">{{ getTimeState() }}，亲爱的你</p>
                <p class="text-2">欢迎来到冠美</p>
            </div>
            <div class="info-member" v-else-if="$store.state.identity === 1">
                <div class="top">
                    <div class="left">
                        <img class="head" src="../../../assets/images/personal/head.png" alt="">
                        <div class="text">
                            <p class="name">{{ $store.state.userInfo.name }}，{{ getTimeState() }}</p>
                            <p class="word">想要更好的运营店铺？我们给你支招。</p>
                        </div>
                    </div>
                    <div class="personal" @click="viewPersonal">
                        <p> 个人中心</p>    
                        <img src="../../../assets/images/home/Vector.png" alt="">
                    </div>
                </div>

                <button class="button-upgrade" @click="onUpgrade">
                    升级年框
                </button>

                <div class="privilege">
                    <p class="title">
                        升级年框，尊享更多特权
                    </p>
                    <div class="privilege-content">
                        <swiper class="swiper-wrapper list" :options="swiperOption" ref="mySwiper">
                            <swiper-slide class="swiper-slider" v-for="item in $store.state.userInfo.levelShows"
                                :key="item.levelShowId">
                                <div class="item">
                                    <div class="icon">
                                        <img :src="item.imgurl" alt="">
                                    </div>
                                    <p>{{item.title}}</p>
                                </div>
                            </swiper-slide>
                        </swiper>
                        <div class="button-navigation button-prev">
                            <i class="el-icon-arrow-left"></i>
                        </div>
                        <div class="button-navigation button-next">
                            <i class="el-icon-arrow-right"></i>
                        </div>
                    </div>


                    <!-- <ul class="list">
                        <li class="item" v-for="item in $store.state.userInfo.levelShows" :key="item.levelShowId">
                            <div class="icon">
                                <img :src="item.imgurl" alt="">
                            </div>
                            <p>{{item.title}}</p>
                        </li>
                    </ul> -->
                </div>
            </div>
            <div class="info-vip" v-else>
                <div class="top">
                    <div class="left">
                        <div class="head-box">
                            <img class="head" src="../../../assets/images/personal/head.png" alt="">
                            <div class="vip">
                                <img class="icon" src="../../../assets/images/home/info-vip.png" alt="">
                            </div>
                        </div>
                        <div class="text">
                            <p class="name">{{ $store.state.userInfo.name }}，{{ getTimeState() }}</p>
                            <p class="word">今天是冠美为您服务的第{{ $store.state.userInfo.startTime }}天。</p>
                        </div>
                    </div>
                    <div class="personal" @click="viewPersonal">
                        <p> 个人中心</p>
                        <img src="../../../assets/images/home/persoanl-arrow.png" alt="">
                    </div>
                </div>
                <div class="border">
                    <div class="border-item"></div>
                    <div class="border-item"></div>
                </div>
                <ul class="privilege">
                    <li class="item" v-for="item in $store.state.userInfo.levelShows" :key="item.levelShowId">
                        <div class="icon">
                            <img :src="item.imgurl" alt="">
                            <div class="lock" v-if="!item.levelId">
                                <img src="../../../assets/images/home/lock.svg" alt="">
                            </div>
                        </div>
                        <p>{{item.title}}</p>
                    </li>
                </ul>
            </div>
        </div>
        <Upgrade :saveType="8" :saveCopyType="11" :visible.sync="visible"></Upgrade>
    </div>
</template>

<script>
import Upgrade from '@/components/upgrade'
import { saveUserBrowseData } from '@/api/common'
export default {
    components: {
        Upgrade
    },
    data() {
        return {
            visible: false,
            swiperOption: {
                autoplay: { //自动开始
                    delay: 2500, //时间间隔
                    // disableOnInteraction: false, //*手动操作轮播图后不会暂停*
                },
                slidesPerView: 4,
                // spaceBetween: 15,
                loop: true, // 循环模式选项
                // 如果需要前进后退按钮
                navigation: {
                    nextEl: '.button-next',
                    prevEl: '.button-prev',
                },
            }
        }
    },
    methods: {

        // 个人中心
        viewPersonal() {
            this.$router.push({ name: 'info' })
        },
        getTimeState() {
            // 获取当前时间
            let timeNow = new Date();
            // 获取当前小时
            let hours = timeNow.getHours();
            // 设置默认文字
            let state = ``;
            // 判断当前时间段
            if (hours >= 0 && hours <= 10) {
                state = `早上好!`;
            } else if (hours > 10 && hours < 14) {
                state = `中午好!`;
            } else if (hours >= 14 && hours <= 18) {
                state = `下午好!`;
            } else if (hours > 18 && hours <= 24) {
                state = `晚上好!`;
            }
            return state;
        },
        // 升级年框
        onUpgrade(){
            this.visible = true
            saveUserBrowseData({
                typeId:5,
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.container-info {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    display: flex;
    align-items: center;
    justify-content: flex-end;

}

.info {
    width: 454px;
    height: 340px;
    background: rgb(242, 251, 255);
    /* 蓝色投影 */
    // box-shadow: 8px 10px 40px rgba(7, 58, 192, 0.5);
    border-radius: 10px;
    overflow: hidden;
    z-index: 997;

}

.info-vip {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(rgba(255, 215, 146, 1) 11.647%, rgba(234, 189, 110, 1) 100%);
    background-size: contain;
    /* 蓝色投影 */
    box-shadow: 8px 10px 40px rgba(7, 58, 192, 0.5);
    border-radius: 10px;

    .top {
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 110px;
        /* 金色渐变2 */
        // background: linear-gradient(rgba(243, 217, 172, 1) 11.647%, rgba(234, 189, 110, 1) 100%);
        /* 金色投影 */
        // box-shadow: 8px 10px 40px rgba(192, 126, 7, 0.24);
        border-radius: 10px;
        z-index: 999;

        .left {
            display: flex;
            align-items: center;
        }

        .head-box {
            width: 50px;
            height: 50px;
            border: 1px solid rgb(53, 121, 235);
            border-radius: 50%;
            margin-right: 10px;
            position: relative;

            .head {
                border-radius: 50%;
                width: 100%;
                height: 100%;
            }

            .vip {
                position: absolute;
                // bottom: -6px;
                left: 50%;
                margin-left: -19px;
                bottom: -8px;
                width: 38px;    
                // height: 12px;


                .icon {
                    width: 100%;
                    height: 100%;
                }

            }
        }

        .text {
            .name {
                color: rgb(0, 0, 0);
                font-size: 18px;
                font-weight: 500;
                letter-spacing: 2px;
            }

            .word {
                color: rgb(0, 0, 0);
                font-size: 12px;
                line-height: 22px;
                // letter-spacing: 1px;
            }
        }

        .personal {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100px;
            height: 28px;
            background: rgb(255, 255, 255);
            // border: 1px solid rgb(229, 229, 229);
            border-radius: 20px;
            cursor: pointer;

            &:hover {
                opacity: 0.7;
            }

            p {
                color: rgb(64, 34, 0);
                font-size: 12px;
                line-height: 18px;
            }

            img {
                width: 6px;
                height: 10px;
                margin-left: 4px;
            }
        }

    }

    .border {
        width: 80%;
        margin: 0 auto;

        .border-item {
            width: 100%;
            height: 1px;

            &:first-child {
                background: rgb(185, 162, 125);
                opacity: 0.6
            }

            &:last-child {
                background: rgb(255, 255, 255);
                opacity: 0.7
            }
        }
    }

    .privilege {
        width: 100%;
        box-sizing: border-box;
        // background:
        //     rgb(40, 40, 40);
        flex: 1;
        margin-top: -10px;
        padding: 35px 45px 25px 45px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);

        .item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            // height: 75px;

            .icon {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 40px;
                background: rgb(252, 246, 231);
                border-radius: 50%;
                position: relative;
                box-shadow: inset 0px 0px 6px rgb(254, 225, 151), 0px 0px 6px rgb(255, 195, 71);
            }

            .lock {
                position: absolute;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                top: 0;
                left: 0;
                background: rgba($color: #000000, $alpha: 0.5);
                display: flex;
                align-items: center;
                justify-content: center;
            }

            p {
                color: rgb(64, 34, 0);
                font-size: 12px;
                line-height: 22px;
            }

            .question {
                width: 11px;
                height: 11px;
            }
        }
    }
}

.info-member {
    height: 100%;
    width: 100%;
    padding: 20px;

    .top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {
            display: flex;
            align-items: center;
        }

        .head {
            width: 50px;
            height: 50px;
            border: 1px solid rgb(53, 121, 235);
            border-radius: 50%;
            margin-right: 10px;
        }

        .text {
            .name {
                color: rgb(0, 0, 0);
                font-size: 20px;
                font-weight: 500;
                letter-spacing: 2px;
            }

            .word {
                color: rgb(0, 0, 0);
                font-size: 12px;
                line-height: 22px;
                // letter-spacing: 1px;
            }
        }

        .personal {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100px;
            height: 28px;
            background: rgb(255, 255, 255);
            border: 1px solid rgb(229, 229, 229);
            border-radius: 20px;
            cursor: pointer;

            &:hover {
                opacity: 0.7;
            }

            p {
                color: rgb(0, 0, 0);
                font-size: 12px;
                line-height: 18px;
            }

            img {
                width: 6px;
                height: 10px;
                margin-left: 4px;
            }
        }

    }

    .button-upgrade {
        display: block;
        margin: 0 auto;
        margin-top: 20px;
        cursor: pointer;
        // height: 80px;
        width: 250px;
        height: 45px;
        border: none;
        background: linear-gradient(to right, rgba(244, 219, 176, 1) 0%, rgba(234, 188, 108, 1) 100%);
        border-radius: 40px;
        color: rgb(64, 34, 0);
        font-family: Noto Sans SC;
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        letter-spacing: 0px;
        text-align: center;
        margin-top: 30px;
        margin-bottom: 20px;

        &:hover {
            opacity: 0.8;
        }
    }

    .privilege {
        width: 100%;
        height: 149px;
        background: rgb(255, 255, 255);
        box-shadow: 0px 2px 30px rgba(180, 202, 248, 0.05);
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;

        .button-navigation {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 99;
        }

        .button-next {
            right: 10px;
        }

        .button-prev {
            left: 10px;
        }

        .title {
            color: rgb(0, 0, 0);
            font-size: 14px;
            font-weight: 500;
        }

        .privilege-content {
            width: 100%;
            // height: 75px;
            margin-top: 22px;
            padding: 0 16px;
            position: relative;
        }

        .list {

            width: 100%;
            height: 100%;

            .item {
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                .icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 40px;
                    height: 40px;
                    background: rgb(251, 223, 185);
                    border-radius: 50%;
                }

                p {
                    color: rgb(106, 106, 106);
                    font-size: 12px;
                    line-height: 22px;
                }

                .question {
                    width: 11px;
                    height: 11px;
                }
            }
        }
    }
}

.info-none {
    height: 100%;
    width: 100%;
    padding-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .text-1 {
        font-size: 24px;
        font-weight: 500;
        letter-spacing: 2px;
    }

    .text-2 {
        font-size: 14px;
        font-weight: 500;
        margin-top: 10px;
    }

    img {
        width: 251px;
        margin-top: 50px;
        cursor: pointer;

        &:hover {
            opacity: 0.9;
        }
    }
}
</style>