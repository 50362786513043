<template>
    <div>
        <div class="silder" :class="$store.state.identity === 2 ? 'silder-v' : 'silder-c'">
            <SilderV v-if="$store.state.identity === 2"></SilderV>
            <div class="silder-common">
                <el-popover placement="left" width="170" trigger="hover">
                    <div class="tip">
                        <div class="title">
                            <img class="icon" src="../../assets/images/silder/chat.png" alt="">
                            <p>在线咨询客服</p>
                        </div>
                        <!-- <div class="tip-content">
                            <img class="icon" src="../../assets/images/silder/icon-1.png" alt="">
                            <img class="icon" src="../../assets/images/silder/icon-2.png" alt="">
                            <p class="text">17723680201</p>
                            <button class="button-copy" @click="handleCopy('17723680201', $event)">复制</button>
                        </div> -->
                    </div>
                    <div slot="reference" @click="onConsult" class="silder-item item-1 service"></div>
                </el-popover>

                <el-popover placement="left" trigger="hover" width="200">
                    <div class="tip-collection">
                        <div class="key">
                            <div class="item">Ctrl</div>
                            <p>+</p>
                            <div class="item">D</div>
                        </div>
                        <p class="text">收藏鹿宾，随时发现灵感</p>
                    </div>
                    <div class="silder-item collection item-2" slot="reference"></div>
                </el-popover>
                <el-popover v-if="$store.state.identity !== 0" placement="left" trigger="hover" width="200">
                    <div class="tip-feedback">
                        <img src="../../assets/images/silder/feedback.png" alt="">
                        <p class="text">没找到想要的？</p>
                        <p class="text">赶快把想法告诉我们</p>
                        <button class="button" @click="onFeedback()">反馈</button>
                    </div>
                    <div class="silder-item  item-4" slot="reference"></div>
                </el-popover>
                <div v-if="backVisible" class="silder-item item-3 back" @click="onBack()"></div>
            </div>
            <img class="float" v-if="$store.state.userInfo.window&&$store.state.userInfo.window.floatWindow" :src="$store.state.userInfo.window.floatWindow" @click="onShowWindow" alt="" />

        </div>
        <Feedback v-if="feedbackVisible" :visible.sync="feedbackVisible"></Feedback>
    </div>
</template>
<script>
let pinyin = require('js-pinyin');
import { copy } from "@/utils/clipboard";
import { saveUserBrowseData } from '@/api/common'
import SilderV from './silderV.vue'
import Feedback from '@/components/feedback/index.vue'
export default {
    components: {
        SilderV,
        Feedback
    },
    data() {
        return {
            clipboard: '',
            backVisible: false,
            feedbackVisible: false,
        }
    },
    created() {
        window.addEventListener('scroll', this.windowScrollListener);
    },
    mounted() {

    },
    methods: {
        onShowWindow(){
            saveUserBrowseData({ typeId: 65 });
            this.$store.commit('onChangeShowWindow',true)
        },
        
        windowScrollListener(e) {
            const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
            if (scrollTop > 600) {
                this.backVisible = true
            } else {
                this.backVisible = false
            }
        },

        // 反馈
        onFeedback() {
            saveUserBrowseData({ typeId: 59 });
            this.feedbackVisible = true
        },

        // 联系客服
        onConsult() {
            this.$store.commit('onlineConsult')
            saveUserBrowseData({ typeId: 33 })
        },

        onCopyText() {
            let str = '15223386057';
            const el = document.createElement("textarea");
            el.addEventListener("focusin", (e) => e.stopPropagation());
            el.value = str;
            document.body.appendChild(el);
            el.select();
            document.execCommand("copy");
            document.body.removeChild(el);
        },
        
        handleCopy(text, e) {
            copy(text, e);
            saveUserBrowseData({
                typeId: 34,
            })
        },
        onBack() {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }
    },
    destroyed() {
        window.removeEventListener('scroll', this.windowScrollListener);
    }
}

</script>

<style lang="scss" scoped>
.silder {
    position: fixed;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 998;
}

.float{
    margin-top: 20px;
}


.silder-common {
    width: 52px;
    // height: 144px;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(244, 244, 244);
    box-shadow: 1px 1px 8px rgba(51, 70, 114, 0.07);
    border-radius: 50px;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 6px 0;
}

.silder-item {
    width: 40px;
    height: 40px;
}

.silder-v {
    .item-1 {
        &:hover {
            background: url('../../assets/images/silder/item-v-1.png') 0 0 no-repeat;
        }
    }

    .item-2 {
        &:hover {
            background: url('../../assets/images/silder/item-v-2.png') 0 0 no-repeat;
        }
    }

    .item-3 {
        &:hover {
            background: url('../../assets/images/silder/item-v-3.png') 0 0 no-repeat;
        }
    }

    .item-4 {
        &:hover {
            background: url('../../assets/images/silder/item-v-4.png') 0 0 no-repeat;
        }
    }
}

.silder-c {
    .item-1 {
        &:hover {
            background: url('../../assets/images/silder/item-c-1.png') 0 0 no-repeat;
            background-size: 38px;
        }
    }

    .item-2 {
        &:hover {
            background: url('../../assets/images/silder/item-c-2.png') 0 0 no-repeat;
        }
    }

    .item-3 {
        &:hover {
            background: url('../../assets/images/silder/item-c-3.png') 0 0 no-repeat;
        }
    }

    .item-4 {
        &:hover {
            background: url('../../assets/images/silder/item-c-4.png') 0 0 no-repeat;
        }
    }
}


.silder {

    .item-1 {
        background: url('../../assets/images/silder/item-1.png') center center no-repeat;
        background-size: 40px !important;
    }

    .item-2 {
        background: url('../../assets/images/silder/item-2.png') center center no-repeat;
        background-size: 40px !important;


    }

    .item-3 {
        background: url('../../assets/images/silder/item-3.png') center center no-repeat;
        background-size: 40px !important;
    }

    .item-4 {
        background: url('../../assets/images/silder/item-4.png') center center no-repeat;
        background-size: 40px !important;
    }
}

.tip-feedback {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
        width: 106px;
    }

    .text {
        color: rgb(69, 69, 69);
        font-family: Noto Sans SC;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
    }

    .button {
        width: 74px;
        height: 29px;
        background: rgb(251, 129, 48);
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgb(255, 255, 255);
        font-family: Noto Sans SC;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 4px;
        border: none;
        cursor: pointer;
        margin-top: 10px;

        &:hover {
            opacity: .6;
        }
    }
}

.tip-collection {
    .key {
        display: flex;
        align-items: center;
        justify-content: center;

        .item {
            padding: 2px 12px;
            // height: 32px;
            background: var(--colorB);
            border-radius: 4px;
            font-size: 18px;
            color: #fff;
        }

        p {}
    }

    .text {
        margin-top: 10px;
        color: rgb(0, 0, 0);
        font-family: Noto Sans SC;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
    }
}

.tip {
    .title {
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgb(0, 0, 0);
        font-size: 14px;
        padding: 6px 0;
        font-weight: 500;
        // padding-left: 15px;

        img {
            width: 27px;
            height: 24px;
            margin-right: 8px;
        }
    }




    .tip-content {
        height: 35px;
        background: rgb(247, 247, 247);
        border: 0px solid rgb(0, 0, 0);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 15px;
        margin-top: 5px;

        .icon {
            width: 20px;
            height: 20px;
        }

        .text {
            color: rgba(0, 0, 0, 0.5);
            font-size: 12px;
        }

        .button-copy {
            font-size: 12px;
            width: 44px;
            line-height: 0;
            height: 22px;
            /* 按钮灰色 */
            background: rgb(244, 244, 244);
            /* 浅灰 */
            border: 1px solid rgba(0, 0, 0, 0.3);
            border-radius: 29px;
            cursor: pointer;

            &:hover {
                border: none;
                color: #fff;
                background: rgb(69, 79, 255);
            }
        }
    }
}
</style>