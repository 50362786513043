<template>
    <el-dialog :visible="visible" custom-class="container-feedback" width="720px">
        <div class="content">
            <div class="header">
                <div class="title">
                    <img src="../../assets/images/silder/notifications.png" alt="">
                    <p>
                        提交反馈
                    </p>
                </div>
            </div>
            <div class="main">
                <el-radio-group class="raido" v-model="form.type">
                    <el-radio :label="0">案例需求</el-radio>
                    <el-radio :label="1">功能建议</el-radio>
                </el-radio-group>
                <Editor :content.sync="form.content"></Editor>
                <p class="tip">*运营人员会在1-3个工作内就您反馈的内容与您联系。</p>
            </div>
            <button class="button-submit" @click="onFeedback">提交反馈</button>
            <Close @onClose="onClose"></Close>
        </div>
    </el-dialog>
</template>

<script>
import Close from '@/components/close'
import Editor from '@/components/editor'
import { feedback } from '@/api/home.js'
export default {
    components: {
        Close,
        Editor,
    },
    props: {
        visible: {
            type: Boolean,
            default: false,
            required: true
        }
    },
    data() {
        return {
            type: 0,
            form: {
                type: 0,
                content: null,
            }
        }
    },
    methods: {
        onClose() {
            this.$emit('update:visible', false)
        },
        async onFeedback() {
            if (!this.form.content || this.form.content === '<p><br></p>') {
                this.$message.warning('请输入反馈内容')
                return false
            }
            try {
                const res = await feedback(this.form)
                this.$message.success('反馈成功!客服人员会在1-3个工作日处理您的反馈')
                this.onClose()
            } catch (e) {
                this.$message.error(e.message)
            }
        }
    },
}
</script>


<style lang="scss" scoped>
.container-feedback {

    .header {
        padding: 0 20px;
        height: 60px;
        border-bottom: 1px solid rgb(229, 229, 229);
        display: flex;
        align-items: center;

        .title {
            display: flex;
            align-items: center;

            img {
                width: 30px;
                margin-right: 4px;
            }

            p {
                color: rgb(0, 0, 0);
                font-size: 14px;
                font-weight: 500;
            }
        }
    }

    .content {
        padding-bottom: 30px;
    }

    .main {
        padding: 30px;

        .raido {
            margin-bottom: 15px;

            :deep(.is-checked) {
                .el-radio__inner {
                    border-color: var(--colorB);
                    background: var(--colorB);
                }

                .el-radio__label {
                    color: rgb(0, 0, 0);
                }
            }
        }

        .tip {
            color: rgba(0, 0, 0, 0.5);
            font-family: Noto Sans SC;
            font-size: 12px;
            margin-top: 10px;
        }
    }

    .button-submit {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        width: 250px;
        height: 50px;
        background: var(--colorB);
        color: var(--colorWB);
        border-radius: 40px;
        border: none;
        font-family: Noto Sans SC;
        font-size: 20px;
        font-weight: 500;
        cursor: pointer;

        &:hover {
            opacity: .7;
        }
    }
}
</style>

<style lang="scss">
.container-feedback {
    z-index: 9999 !important;
    border-radius: 14px !important;
    // height: 638px;
    position: relative;
    overflow: hidden;

    .el-dialog__header {
        display: none !important;
    }

    .el-dialog__body {
        padding: 0;
    }
}
</style>