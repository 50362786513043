<template>
    <div class="item-swipe" :style="styles">
        <swiper class="swiper-wrapper" :options="swiperOption" @slideChange="slideChange" ref="mySwiper">
            <swiper-slide v-for="(item, index) in detail.banner" :key="index" class="swiper-slider swiper-item banner1">
                <img :src="item.img" alt="">
            </swiper-slide>
        </swiper>
        <div class="pagination"
            :style="{ justifyContent: detail.pagination.position === 'br' ? 'flex-end' : detail.pagination.position === 'bl' ? 'flex-start' : 'center' }">
            <div class="item" @click="onPaginationChange(index)"
                :style="activeIndex === index ? itemActivedStyles : itemStyles" v-for="(item, index) in detail.banner"
                :key="index"></div>
        </div>
    </div>
</template>
<script>
// 图片
export default {
    name: 'Swipe',
    props: {
        detail: {
            type: Object,
            default: {},
            required: true,
        }
    },
    data() {
        return {
            styles: {
                position: 'absolute',
                width: `${this.detail.width}px`,
                height: `${this.detail.height}px`,
                left: `${this.detail.left}px`,
                top: `${this.detail.top}px`,
                opacity: `${this.detail.opacity}`,
                borderRadius: `${this.detail.bR}px`,
            },
            itemStyles: {
                width: `${this.detail.pagination.old.w}px`,
                height: `${this.detail.pagination.old.h}px`,
                borderRadius: `${this.detail.pagination.old.br}px`,
                backgroundColor: `${this.detail.pagination.old.color}`,
                marginRight: `${this.detail.pagination.spacing}px`
            },
            itemActivedStyles: {
                width: `${this.detail.pagination.active.w}px`,
                height: `${this.detail.pagination.active.h}px`,
                borderRadius: `${this.detail.pagination.active.br}px`,
                backgroundColor: `${this.detail.pagination.active.color}`,
                marginRight: `${this.detail.pagination.spacing}px`
            },
            activeIndex: 0,
            swiperOption: {
                // effect: 'fade',
                loop: true,
                autoplay: this.detail.auto === 'true' ? true : false,
                direction: this.detail.ver === '0' ? 'horizontal' : 'vertical',
            },
        }
    },
   
    computed: {
        swiper() {
            return this.$refs.mySwiper.$swiper;
        }
    },
//     “Bolsonarismo is a strong opposition force and got even stronger after this election despite Bolsonaro’s loss,” she said.

// In the last legislative elections, Bolsonaro’s Liberal Party increased its representatives in the lower house from 76 to 99, while in the Senate it doubled from seven members to 14. Though Lula da Silva’s Workers’ Party has also increased representation in both houses, conservative-leaning politicians will dominate the next legislature overall.


    methods: {
        // 跳转链接
        onNavigation() {
            if (this.detail.url) {
                window.open(this.detail.url, '_blank')
            }
        },
        onPaginationChange(index) {
            // console.log(index)
            this.activeIndex = index
            this.swiper.slideToLoop(index, 0, true)
        },
        slideChange(e) {
            console.log(this.swiper.realIndex, 'this.swiper.realIndex')
            const realIndex = this.swiper.realIndex;
            this.activeIndex = realIndex
        },
    },
}
</script>
<!--  -->
<style lang="scss" scoped>
.item-swipe {
        .pagination {
        width: 100%;
        position: absolute;
        bottom: 20px;
        z-index: 999;
        display: flex;
        padding: 0 20%;
    }
}

.swiper-wrapper {
    width: 100%;
    height: 100%;

    img {
        width: 100%;
        height: 100%;
    }
}
</style>