const OSS = require('ali-oss')
import moment from 'moment';
import { getUpLoadFileParams } from '@/api/common'


let client = null

// 上传文件的路径，使用日期命名文件目录
const uploadPath = (file) => {
    return `${moment().format('YYYYMMDD')}/${file.name.split(".")[0]}-${file.uid}.${file.type.split("/")[1]}`
}

function onUploadFile(file) {
    return new Promise(async (reslove, reject) => {
        try {
            await onGetUpLoadFileParams()
            const res = await onOSSUpload(file)
            reslove(res)
        } catch (e) {
            reject(e)
        }  
    })
}

//  两个硬盘100 固态 80 显示屏300 主板U300 内存条100 显卡400 海电源200 80 键盘20

// 获取oss配置参数
async function onGetUpLoadFileParams(file) {
    console.log(file)
    try {
        const res = await getUpLoadFileParams()
        // console.log(res, 'res')
        client = new OSS({
            region: res.data.region,
            accessKeyId: res.data.accessKeyId,
            accessKeySecret: res.data.accessKeySecret,
            stsToken: res.data.securityToken,
            bucket: res.data.bucket,
            endpoint: res.data.endpoint
        })
    } catch (e) {
        console.log(e)
    }
}

async function onOSSUpload(file) {
    return new Promise(async (reslove, reject) => {
        try {
            const path = uploadPath(file)
            const res = await client.put(
                path,
                file,
            );
            reslove(res)
        } catch (e) {
            reject(e)
        }
    })
}




export default onUploadFile
