
<template>
    <div class="container-banner">
        <div class="swiper-container swiper">
            <swiper class="swiper-wrapper" :options="swiperOption" @slideChange="slideChange" ref="mySwiper">
                <!-- <swiper-slide class="swiper-slider" v-for="(item, index) in bannerList" :key="index">
                    <img @click="onViewOperation(item)" class="img" :src="item.image" alt="" />
                </swiper-slide> -->
             
                <swiper-slide class="swiper-slider swiper-item banner2" @click.native="onViewOperation(1)">
                    <div class="info">
                        <div class="text  animate__animated fadeInDown">
                            <h2>品牌店铺营销</h2>
                            <p>店铺营销升级的灵感池</p>
                            <p>结合时下热点，聚焦多行业</p>
                            <p>提供顾问式指导，深度解析多品牌店铺营销实例</p>
                            <button @click.native="onViewOperation(1)">立即查看</button>
                        </div>
                        <div class="block  fadeInUp">
                            <img class="block-item" src="../../../assets/images/banner/banner2/block.png" alt="">
                            <img class="center" src="../../../assets/images/banner/banner2/block-center.png" alt="">
                            <img class="light" src="../../../assets/images/banner/banner2/light.png" alt="">
                        </div>
                    </div>
                    <img class="bg"
                       src="http://gm-case.oss-cn-hangzhou.aliyuncs.com/20221014/%E5%93%81%E7%89%8C%E8%90%A5%E9%94%80-vc-upload-1665730768941-4.png"
                      alt="">
                </swiper-slide>
                <swiper-slide class="swiper-slider swiper-item banner1" @click.native="onViewOperation(2)">
                    <div class="info">
                        <div class="text animate__animated fadeInDown">
                            <h2>会员运维中心</h2>
                            <p>会员中心能力升级</p>
                            <p>通过采集会员互动数据与千人千页互动模块</p>
                            <p>助力店铺精细化用户生命周期运营</p>
                            <p>构建会员资产，提升会员复购，构建权益场景，提高会员粘性</p>
                            <button @click.native="onViewOperation(1)">立即查看</button>

                        </div>
                        <div class="block  fadeInUp ">
                            <div class="block-bar bar1">
                                <img src="../../../assets/images/banner/banner1/bar1.png" alt="">
                            </div>
                            <div class="block-bar  bar2">
                                <img src="../../../assets/images/banner/banner1/bar2.png" alt="">
                            </div>
                            <div class="block-bar bar3">
                                <img src="../../../assets/images/banner/banner1/bar3.png" alt="">
                            </div>
                            <div class="block-bar bar4">
                                <img src="../../../assets/images/banner/banner1/bar4.png" alt="">
                            </div>
                            <img class="block-bar bar5" src="../../../assets/images/banner/banner1/bar.png" alt="">
                            <img class="light" src="../../../assets/images/banner/banner1/light.png" alt="">
                        </div>
                    </div>
                    <img class="bg"
                        src="http://gm-case.oss-cn-hangzhou.aliyuncs.com/20221014/%E4%BC%9A%E5%91%98%E8%BF%90%E7%BB%B4%20%E8%83%8C%E6%99%AF-vc-upload-1665730768941-2.png"
                        alt="">
                </swiper-slide>
                <!-- -->
                <!-- <swiper-slide class="swiper-slider swiper-item banner3" @click="onViewOperation(3)">
                    <div class="info">
                        <div class="text  fadeInDown">
                            <h2>品牌心智特有表达</h2>
                            <p>通过店铺创新组件表达品牌心智，</p>
                            <p>点燃用户消费欲望，持续锁定目标用户类型，持续唤醒高价值</p>
                            <p>用户消费，持续创造高品质用户特色，
                            </p>
                            <p>从而建构用户心智空间</p>
                            <button @click.native="onViewOperation(1)">查看详情</button>

                        </div>
                        <div class="block  fadeInUp">
                            <img class="block-item" src="../../../assets/images/banner/banner3/block.png" alt="">
                            <img class="center" src="../../../assets/images/banner/banner3/block-center1.png" alt="">
                            <img class="light" src="../../../assets/images/banner/banner3/light.png" alt="">
                        </div>
                    </div>
                    <img class="bg"
                        src="http://gm-case.oss-cn-hangzhou.aliyuncs.com/20221014/%E5%93%81%E7%89%8C%E8%90%A5%E9%94%80%20%E8%83%8C%E6%99%AF-vc-upload-1665730768941-6.png"
                        alt="">
                </swiper-slide> -->
            </swiper>
            <div class="container-pagination">
                <div class="pagination" @click="onViewOperation(activeIndex + 1)">
                    <div></div>
                    <div class="pagination-button" slot="pagination">
                        <i class="el-icon arrow-left el-icon-arrow-left" @click.stop="onArrowChange('prev')"
                            slot="button-prev"></i>
                        <i class="el-icon arrow-right el-icon-arrow-right" @click.stop="onArrowChange('next')"
                            slot="button-next"></i>
                    </div>
                    <div class="pagination-list">
                        <div class="item" @click.stop="onPaginationChange(0)"
                            :class="activeIndex === 0 ? 'active' : ''">
                        </div>
                        <div class="item" @click.stop="onPaginationChange(1)"
                            :class="activeIndex === 1 ? 'active' : ''">
                        </div>
                        <!-- <div class="item" @click.stop="onPaginationChange(2)" :class="activeIndex===2?'active':''">
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { saveUserBrowseData } from '@/api/common'
import { getBanner } from '@/api/home'
export default {
    data() {
        return {
            bannerSwiper: null,
            bannerList: [],
            activeIndex: 0,
            swiperOption: {
                effect: 'fade',
                loop: true,
                autoplay: { 
                    delay: 6000,
                    disableOnInteraction: false
                },
                // 设置点击箭头
                // navigation: {
                //     nextEl: ".arrow-right",
                //     prevEl: ".arrow-left"
                // },
                observer: true,//修改swiper自己或子元素时，自动初始化swiper
                observeParents: true,
            }
        }
    },

    computed: {
        swiper() {
            return this.$refs.mySwiper.$swiper;
        }
    },

    created() {
        this.getBannerList()
    },

    mounted() {
    },

    methods: {
        onArrowChange(type) {
            let index = this.activeIndex
            if (type === 'prev') {
                index = index - 1 < 0 ? 1 : index - 1
            } else {
                index = index + 1 < 1 ? 0 : index + 1
            }
            this.swiper.slideToLoop(index, 0, true)
        },
        slideChange(e) {
            // console.log(this.swiper.realIndex, 'this.swiper.realIndex')
            const realIndex = this.swiper.realIndex;
            this.activeIndex = realIndex
        },

        // 跳转页面
        onViewOperation(index) {
            
            // console.log(index)
            let type = null

            switch (this.activeIndex) {
                case 0:
                    type = 11;
                    break;
                case 1:
                    type = 9
                    break;
                case 3:
                    break;
            }

            if (type) {
                saveUserBrowseData({
                    typeId: 14,
                    spmId: type,
                })
                window.open(`https://lubin.guanmeikj.com/#/operation?type=${type}`);
            }
        },
        onPaginationChange(index) {
            // console.log(index)
            this.activeIndex = index
            this.swiper.slideToLoop(index, 0, true)
        },
        // 获取banner
        async getBannerList() {
            try {
                const res = await getBanner()
                res.data.forEach(item => {
                    item.config = JSON.parse(item.config)
                })
                this.activeIndex
                this.bannerList = res.data
            } catch (e) {

            }
        }
    },

    destroyed() {
        this.swiper.destroy(false)
    },
};
</script>

<style lang="scss" scoped>
@keyframes up {
    0% {
        transform: translate(0, 0px);
    }

    100% {
        transform: translate(0, 10px);
    }
}

@keyframes light {
    0% {
        opacity: 0;
        transform: translate(0, 288px);
    }

    80% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: translate(0, -300px);
    }
}

@keyframes banner2Center {
    0% {

        transform: translate(0, 0px) scale(0.9);
    }

    100% {

        transform: translate(0, 10px) scale(1);
    }
}

@keyframes banner3Center {
    0% {
        transform: translate(0, 0px);
    }

    100% {
        transform: translate(0, 5px);
    }
}

@keyframes paginationProgress {
    0% {
        width: 0;
    }

    100% {
        width: 50px;
    }
}

.banner1 {
    .block {
        left: 400px;
        width: 565px;
        height: 100%;
        position: relative;

        .block-bar {
            position: absolute;
            animation-duration: 2s;
            animation-iteration-count: infinite;
            animation-fill-mode: both;
            animation-direction: alternate;
            animation-name: up;
        }

        .bar1 {
            top: 307px;
            left: 1px;
            animation-delay: 0s;

        }

        .bar2 {
            top: 322px;
            left: 288px;
            animation-delay: .2s;
        }

        .bar3 {
            top: 219px;
            right: 1px;
            animation-delay: .4s;
        }

        .bar4 {
            top: 100px;
            left: 404px;
            animation-delay: .6s;
        }

        .bar5 {
            top: 96px;
            left: 59px;
            animation-delay: 1s;
        }

        .light {
            position: absolute;
            left: 61px;
            bottom: 0;
            animation-duration: 6s;
            animation-iteration-count: infinite;
            animation-fill-mode: both;
            animation-direction: normal;
            animation-name: light;

        }
    }
}

.banner2 {
    .block {
        left: 320px;
        position: relative;

        .block-item {
            position: absolute;
            animation-duration: 2s;
            animation-iteration-count: infinite;
            animation-fill-mode: both;
            animation-direction: alternate;
            animation-name: up;
        }

        .center {
            position: absolute;
            top: 0;
            left: 257px;
            z-index: -1;
            animation-delay: 0.5s;
            animation-duration: 2s;
            animation-iteration-count: infinite;
            animation-fill-mode: both;
            animation-direction: alternate;
            animation-name: banner2Center;
        }

        .light {
            position: absolute;
            left: 115px;
            bottom: 0;
            animation-duration: 6s;
            animation-iteration-count: infinite;
            animation-fill-mode: both;
            animation-direction: normal;
            animation-name: light;

        }
    }
}


.banner3 {
    .block {
        left: 380px;
        position: relative;

        .block-item {
            position: absolute;
            top: 0;
            left: 132px;
            animation-duration: 2s;
            animation-iteration-count: infinite;
            animation-fill-mode: both;
            animation-direction: alternate;
            animation-name: up;
        }

        .center {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            animation-delay: 0.5s;
            animation-duration: 2s;
            animation-iteration-count: infinite;
            animation-fill-mode: both;
            animation-direction: alternate;
            animation-name: up;
        }

        .light {
            position: absolute;
            left: 0;
            bottom: 0;
            animation-duration: 6s;
            animation-iteration-count: infinite;
            animation-fill-mode: both;
            animation-direction: normal;
            animation-name: light;

        }
    }
}


.container-banner {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
    max-width: 1920px;
    overflow: hidden;
    margin-right: 20px;

    .swiper-container {
        height: 100%;
    }

    .img {
        width: 100%;
        height: 100%;
    }
}


.swiper-item {
    position: relative;

    .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .info {
        z-index: 99;
        width: 100%;
        height: 100%;
        max-width: 1440px;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
        position: absolute;
        z-index: 995;
        button{
            font-size: 12px;
            font-weight: bold;
            // padding: 5px 10px;
            width:150px;
            height: 30px;
            letter-spacing: 4px;
            cursor: pointer;
            border: none;
            border-radius: 15px;
            margin-top: 10px;
            color: rgb(64, 34, 0);
            background: linear-gradient(to right, rgb(244, 219, 176) 0%, rgb(234, 188, 108) 100%);
        }
        .text {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            padding-left: 70px;
            color: #fff;
            height: 100%;

            h2 {
                font-size: 40px;
                line-height: 60px;
                margin-bottom: 30px;
            }

            p {
                font-size: 14px;
                font-weight: 400;
                line-height: 22px;
                margin-bottom: 6px;
            }
        }

        .block {
            position: absolute;
            top: 0;
            height: 100%;
            display: flex;
            align-items: center;
        }
    }
}

.container-pagination {
    width: 100%;
    height: 100%;
    max-width: 1440px;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    position: absolute;
    z-index: 995;

    .pagination {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 67%;
        position: relative;

        .pagination-button {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .el-icon {
                font-size: 24px;
                color: #fff;
                transition: all .5s;
                cursor: pointer;

                &:hover {
                    opacity: .7;
                    transform: scale(1.2);
                }
            }
        }

        .pagination-list {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-bottom: 20px;
            padding-left: 70px;

            .item {
                width: 50px;
                height: 2px;
                background: rgba(255, 255, 255, 0.3);
                margin-right: 10px;
                position: relative;

                &::after {
                    content: '';
                    display: block;
                    width: 0px;
                    height: 2px;
                    background: #fff;
                    position: absolute;
                    left: 0;
                    top: 0;

                }
            }

            .active {
                &::after {
                    animation-duration: 6.5s;
                    animation-iteration-count: infinite;
                    animation-fill-mode: both;
                    animation-direction: normal;
                    animation-name: paginationProgress;
                }
            }


        }

    }
}
</style>


<style lang="scss">
.swiper-slider {
    // background: blue;
}

@keyframes bfadeInUp {
    0% {
        opacity: 0;
        transform: translate3d(0, 5%, 0);
    }

    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}


@keyframes bfadeInDown {
    0% {
        opacity: 0;
        transform: translate3d(0, -5%, 0);
    }

    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}


.swiper-slide-active .fadeInDown {
    animation-duration: 1s;
    animation-name: bfadeInDown;
}

.swiper-slide-active .fadeInUp {
    animation-duration: 1s;
    animation-name: bfadeInUp;
}
</style>