<template>
    <div class="item-singlean" ref="singlean" :style="styles">
        <DecorationModuleCode :content="detail.config" v-if="detail.imageType === 'diy'"></DecorationModuleCode>
        <img v-else :src="detail.bI" :style="{ width: styles.width, height: styles.height }" alt="">
    </div>
</template>

<script>
// 循环动画
const DecorationModuleCode = () => import('@/components/decorationModuleCode/index.vue')
import anime from 'animejs/lib/anime.js';
export default {
    name: 'Singlean',
    components: {
        DecorationModuleCode
    },
    props: {
        detail: {
            type: Object,
            default: {},
            required: true,
        }
    },

    data() {
        return {
            styles: {
                position: 'absolute',
                width: `${this.detail.width}px`,
                height: `${this.detail.height}px`,
                left: `${this.detail.left}px`,
                top: `${this.detail.top}px`,
                opacity: `${this.detail.opacity}`,
                borderRadius: `${this.detail.bR}px`,
                transform: '',
            }
        }
    },
    created() {
        this.init()
    },
    mounted() {
        this.createdAnimation()
    },
    methods: {
        createdAnimation() {
            const data = this.detail

            let params = {
                loop: data.times || true,
                delay: data.delay ? Number(data.delay) * 1000 : 0,
                duration: data.td ? Number(data.td) * 1000 : 3000,
                easing: data.ttf || 'linear',
                direction: data.route === 'oneway' ? 'normal' : 'alternate', //单向 双向循环
                endDelay: data.stopTd ? Number(data.stopTd) * 1000 : 0
            }

            let { txs = 0, txe = 0, tys = 0, tye = 0, ty = 0, tss = 1, tse = 1, ops = 1, ope = 1, ttr = 0, ttre = 0, ttf = 0, antype = 0, transxs = 0, transxe = 0, transys = 0, transye = 0, num = 0 } = data;
            const keys = Object.keys(data.an) // 'txy', 'ts', 'opa', 'tr', 'tx3d', 'ty3d'
            keys.forEach(key => {
                switch (key) {
                    case 'txy':
                        console.log(data.an[key])
                        if (data.an[key]) {
                            params.translateX = txe
                            params.translateY = tye
                        }
                        break;
                    case 'ts':
                        if (data.an[key]) {
                            params.scale = tse ? tse : 1
                        }
                        break;
                    case 'opa':
                        if (data.an[key]) {
                            params.opacity = ope ? ope : 1
                        }
                        break;
                    case 'tr':
                        if (data.an[key]) {
                            params.rotate = ttre ? ttre : 0
                        }
                        break;
                    case 'tx3d':
                        if (data.an[key]) {
                            params.rotateX = transxe ? Number(transxe) : 0
                        }
                        break;
                    case 'ty3d':
                        if (data.an[key]) {
                            params.rotateY = transye ? Number(transye) : 0
                        }
                        break;
                }
            })
            // console.log(params, data.an.ts, 'params-singlean')
            const myAnimation = anime({
                targets: [this.$refs.singlean,],
                ...params
            });
        },
        init() {
            const data = this.detail
            let styles = this.styles
            let { txs = 0, txe = 0, tys = 0, tye = 0, ty = 0, tss = 1, tse = 1, ops = 1, ope = 1, ttr = 0, ttf = 0, antype = 0, transxs = 0, transxe = 0, transys = 0, transye = 0, num = 0 } = data;
            const keys = Object.keys(data.an) // 'txy', 'ts', 'opa', 'tr', 'tx3d', 'ty3d'
            keys.forEach(key => {
                switch (key) {
                    case 'txy':
                        if (data.an[key]) {
                            styles.translateX = txs
                            styles.translateY = tys
                        }
                        break;
                    case 'ts':
                        if (data.an[key]) {
                            styles.scale = tss
                        }
                        break;
                    case 'opa':
                        if (data.an[key]) {
                            styles.opacity = ops
                        }
                        break;
                    case 'tr':
                        if (data.an[key]) {
                            styles.transform = `${styles.transform} rotate(${transxs}deg)`
                        }
                        break;
                    case 'tx3d':
                        if (data.an[key]) {
                            styles.transform = `${styles.transform} rotateX(${transxs}deg)`
                        }
                        break;
                    case 'ty3d':
                        if (data.an[key]) {
                            styles.transform = `${styles.transform} rotateY(${transys}deg)`
                        }
                        break;
                }
            })
            console.log(styles, 'styles')
            this.styles = styles
        },
    },


}
</script>

<style lang="scss" scoped>
.container-code-item {}
</style>