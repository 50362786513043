<template>
  <main class="main">
    
    <div class="main-card">
      <Banner v-if="$store.state.userInfo"></Banner>
      <Info></Info>
    </div>

    <!-- <div style="zoom:.4;position: relative;display: flex;justify-content: center;">
      <div style="position: relative;">
        <DecorationModuleCode></DecorationModuleCode>
      </div>
    </div> -->

    <div class="container-main">
      <ClassificationA v-if="$store.state.identity === 2"></ClassificationA>
      <ClassificationB v-else></ClassificationB>
      <div class="tags">
        <div class="tags-list tags-list-vip" v-if="$store.state.identity === 2">
          <div
            class="tags-item"
            :class="[activedTagIndex === 0 ? 'tags-item-active' : '']"
            @click="onTagChange(0)"
          >
            <img
              class="icon"
              v-if="activedTagIndex === 0"
              src="../../assets/images/case/recommend-v.png"
              alt=""
            />
            <p>为你推荐</p>
          </div>
          <div
            class="tags-item"
            :class="[activedTagIndex === 1 ? 'tags-item-active' : '']"
            @click="onTagChange(1)"
          >
            <img
              class="icon"
              v-if="activedTagIndex === 1"
              src="../../assets/images/case/sort-1.png"
              alt=""
            />
            <p>热门内容</p>
          </div>
          <div
            class="tags-item"
            :class="[activedTagIndex === 2 ? 'tags-item-active' : '']"
            @click="onTagChange(2)"
          >
            <img
              class="icon"
              v-if="activedTagIndex === 2"
              src="../../assets/images/case/sort-2.png"
              alt=""
            />
            <p>冠美创新内容</p>
          </div>
          <el-badge :value="recentContentNumber" :max="99" class="item">
            <div
              class="tags-item badge"
              :class="[activedTagIndex === 3 ? 'tags-item-active' : '']"
              @click="onTagChange(3)"
            >
              <img
                class="icon"
                v-if="activedTagIndex === 3"
                src="../../assets/images/case/sort-3.png"
                alt=""
              />
              <p>近期上新内容</p>
            </div>
          </el-badge>
        </div>
        <div class="tags-list tags-list-common" v-else>
          <div
            class="tags-item"
            :class="[activedTagIndex === 0 ? 'tags-item-active' : '']"
            @click="onTagChange(0)"
          >
            <img
              class="icon"
              v-if="activedTagIndex === 0"
              src="../../assets/images/case/recommend-c.png"
              alt=""
            />
            <p>为你推荐</p>
          </div>
          <div
            class="tags-item"
            :class="[activedTagIndex === 1 ? 'tags-item-active' : '']"
            @click="onTagChange(1)"
          >
            <img
              class="icon"
              v-if="activedTagIndex === 1"
              src="../../assets/images/home/group.png"
              alt=""
            />
            <p>热门内容</p>
          </div>
          <div
            class="tags-item"
            :class="[activedTagIndex === 2 ? 'tags-item-active' : '']"
            @click="onTagChange(2)"
          >
            <img
              class="icon"
              v-if="activedTagIndex === 2"
              src="../../assets/images/case/shape-1.png"
              alt=""
            />
            <p>冠美创新内容</p>
          </div>
          <el-badge :value="recentContentNumber" :max="99" class="item">
            <div
              class="tags-item badge"
              :class="[activedTagIndex === 3 ? 'tags-item-active' : '']"
              @click="onTagChange(3)"
            >
              <img
                class="icon"
                v-if="activedTagIndex === 3"
                src="../../assets/images/case/shape-2.png"
                alt=""
              />
              <p>近期上新内容</p>
            </div>
          </el-badge> 
        </div>
        <div class="more" @click="viewMore">
          <p>查看更多</p>
          <img src="../../assets/images/home/Vector.png" alt="" />
        </div>
      </div>
      <CaseList
        :loading="loading"
        :caseList="caseList"
        :pagination.sync="pagination"
      ></CaseList>
    </div>
  </main>
</template>
<script>
import Banner from "./components/banner.vue";
import Info from "./components/info.vue";
import ClassificationA from "./components/classificationA.vue";
import ClassificationB from "./components/classificationB.vue";
import CaseList from "@/components/case/caseList.vue";
import DecorationModuleCode from "@/components/decorationModuleCode/index.vue";
import { getRecentContent, getRecommendCase } from "@/api/home";
import { getCases } from "@/api/case";
import { saveUserBrowseData } from "@/api/common";

export default {
  name: "home",
  provide() {
    return {
      //这个api设定主动通知 将组件实例绑定在根组件上
      setChildrenRef: (name, ref) => {
        this[name] = ref;
      },
      //这个api是主动获取绑定的组件
      getChildrenRef: (name) => {
        return this[name];
      },
      // 这个是获取根组件
      getRef: () => {
        return this;
      },
    };
  },
  components: {
    Banner,
    Info,
    ClassificationA,
    ClassificationB,
    CaseList,
    DecorationModuleCode,
  },
  data() {
    return {
      loading: true,
      caseList: [],
      activedTagIndex: 0,
      pagination: {
        total: 0,
        current: 1,
        size: 10,
      },
      caseCondition: {
        sort: 2,
      },
      recentContentNumber: 0,
    };
  },

  watch: {
    "pagination.current": {
      deep: true,
      handler(newVal, oldVal) {
                this.getList();
      },
    },
  },
  created() {
    console.log(222,this.$store.state.userInfo)
    if (this.$store.state.userInfo) {
      this.getRecentContentNumber();
      // this.getRecommendCaseList();
      this.getList();
      saveUserBrowseData({
        typeId: 47,
      });
    }
  },
  methods: {
    // 查看更多
    viewMore() {
      this.$router.push({
        name: "classification",
        params: {
          onlyViewInnovation: this.activedTagIndex === 1 ? true : false,
        },
      });
    },

    onTagChange(index) {
      this.activedTagIndex = index;
      this.pagination.current = 1;
      let caseCondition = {};

      // 为你推荐
      // if (index == 0) {
      //   this.getRecommendCaseList();
      //   return false;
      // }

      switch (index) {
        case 1:
          caseCondition["sort"] = 2; //排名1时间，2浏览数
          break;
        case 2:
          caseCondition["isinnovation"] = 1; //0不是创新1创新
          break;
        default:
          caseCondition["sort"] = 1; //排名1时间，2浏览数
      }
      this.caseCondition = caseCondition;
      this.getList();
    },

    // 获取最近上新内容
    async getRecentContentNumber() {
      try {
        const res = await getRecentContent();
        this.recentContentNumber = res.data;
      } catch (e) {}
    },

    // 获取为你推荐
    async getRecommendCaseList() {
      console.log("getRecommendCaseList");
      const params = {
        current: this.pagination.current,
        size: this.pagination.size,
      };
      try {
        const res = await getRecommendCase(params);
        this.caseList = res.data.records;
        this.pagination.total = res.data.total;
      } catch (e) {}
      this.loading = false;
    },

    // 查询案例
    async getList() {
      if(this.activedTagIndex === 0){
        this.getRecommendCaseList()
        return false
      }
      const params = {
        ...this.caseCondition,
        ...this.pagination,
      };
      try {
        const res = await getCases(params);
        this.caseList = res.data.records;
        this.pagination.total = res.data.total;
      } catch (e) {}
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.container-main {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}

.main-card {
  position: relative;
  min-height: 400px;
  // height: 37vh;
  display: flex;
  align-items: center;
}

.case-list {
  padding: 40px 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 40px;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 340px;
  position: relative;
}

.tags-list-vip {
  .tags-item {
    color: rgb(0, 0, 0);

    &:hover {
      border: solid 1px transparent !important;
      background-image: linear-gradient(#fff, #fff),
        linear-gradient(
          to right,
          rgba(243, 217, 172, 1),
          rgba(234, 189, 110, 1)
        ) !important;
      background-origin: border-box !important;
      background-clip: content-box, border-box !important;
    }
  }

  .tags-item-active {
    border: solid 1px transparent !important;
    background-image: linear-gradient(#fff, #fff),
      linear-gradient(to right, rgba(243, 217, 172, 1), rgba(234, 189, 110, 1)) !important;
    background-origin: border-box !important;
    background-clip: content-box, border-box !important;

    p {
      display: inline-block;
      background: linear-gradient(
        to right,
        rgba(254, 220, 68, 1),
        rgba(250, 112, 154, 1)
      );
      color: transparent;
      -webkit-background-clip: text;
    }
  }
}

.tags-list-common {
  .tags-item {
    &:hover {
      color: rgb(67, 87, 255) !important;
      border: solid 1px transparent !important;
      background-image: linear-gradient(#fff, #fff),
        linear-gradient(to right, rgba(73, 59, 255, 1), rgba(59, 182, 255, 1)) !important;
      background-origin: border-box !important;
      background-clip: content-box, border-box !important;
    }
  }

  .tags-item-active {
    color: rgb(67, 87, 255) !important;
    border: solid 1px transparent !important;
    background-image: linear-gradient(#fff, #fff),
      linear-gradient(to right, rgba(73, 59, 255, 1), rgba(59, 182, 255, 1)) !important;
    background-origin: border-box !important;
    background-clip: content-box, border-box !important;
  }
}

.tags {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .tags-list {
    display: flex;
    align-items: center;

    .tags-item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 150px;
      height: 50px;
      background: rgb(255, 255, 255);
      border: 1px solid rgb(202, 202, 202);
      border-radius: 10px;
      font-size: 14px;
      font-weight: 500;
      margin-right: 10px;
      cursor: pointer;
      transition: transform 0.3s linear;

      &:hover {
        transform: translateY(-3px);
      }

      .icon {
        margin-right: 5px;
      }
    }

    .badge {
      margin-right: 0 !important;
    }

    :deep(.el-badge__content) {
      background: linear-gradient(
        to right bottom,
        rgba(255, 69, 69, 1) 0%,
        rgba(255, 202, 93, 1) 100%
      );
    }
  }

  .more {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 32px;
    border: 1px solid rgb(229, 229, 229);
    border-radius: 20px;
    color: rgb(0, 0, 0);
    font-size: 14px;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }

    img {
      margin-left: 5px;
    }
  }
}
</style>