<template>
    <div class="container-operation">
        <div class="content">
            <div class="banner">
                <img :src="this.typeOptions[this.activeIndex].imgurl" alt="">
            </div>
       
            <ul class="tab" v-if="typeOptions[1]">
                <li class="item" v-for="(item,index) in typeOptions" :key="item.value"
                    :class="[id === item.customTypeId ? 'item-active' : '']"
                    @click="onTabChange(item.customTypeId,index)">
                    <p>{{ item.name }}</p>
                </li>
            </ul>
            <CustomCaseList v-if="list[0]" :list="list" :pagination.sync="pagination"></CustomCaseList>
        </div>
    </div>
</template>
<!-- -->
<script>
import CustomCaseList from '@/components/customCase/customCaseList'
import { getOperationType } from '@/api/operation'
import { saveUserBrowseData } from '@/api/common'
export default {
    components: {
        CustomCaseList
    },
    data() {
        return {
            typeList: [],
            list: [],
            id: null,
            activeIndex: 0,
            pagination: {
                total: 0,
                current: 1,
                size: 12,
            },
            typeOptions: [
                {
                    value: 1,
                    label: '会员运维'
                },
                {
                    value: 2,
                    label: '品牌店铺升级'
                },
                {
                    value: 3,
                    label: '品牌心智表达'
                },
            ]
        }
    },
    watch: {
        'pagination.current': {
            deep: true,
            handler(newVal, oldVal) {
                this.getCourseList()
            }
        }
    },
    created() {
        // 是否为banner图跳转
        if (this.$route.query.type) {
            this.id = parseInt(this.$route.query.type)
        }
        this.getOperationType()
        saveUserBrowseData({
            typeId: 4,
        })
        // this.getBannerList()
    },
    watch: {
        'pagination.current': {
            deep: true,
            handler(newVal, oldVal) {
                this.getList()
            }
        }
    },
    methods: {
        onTabChange(id, index) {
            this.id = id
            this.activeIndex = index
            this.pagination.current = 1
            this.getList()
            console.log()
        },
        async getOperationType() {
            try {
                const res = await getOperationType()
                
                this.typeOptions = res.data
                if (!this.id) {
                    this.id = this.typeOptions[0].customTypeId
                } else {
                    // banner图片
                    this.activeIndex = this.typeOptions.findIndex(item => item.customTypeId === this.id)
                }
                this.getList()
            } catch (e) { }
        },

        async getList() {
            const params = {
                id: this.id,
                ...this.pagination
            }
            try {
                const res = await this.$http.post('/custom/getByBannerId', params)
                this.list = res.data.records
                this.pagination.total = res.data.total
            } catch (e) {

            }
        }
    }

}   
</script>

<style lang="scss" scoped>
.container-operation {
    min-height: calc(100vh - 70px);
    padding-top: 10px;
    background: var(--BG);

    .content {
        width: 100%;
        max-width: 1000px;
        margin: 0 auto;
        background: #fff;
    }

    .banner {
        width: 100%;
        // height: 15vh;
        height: 300px;
        // padding-top: 20px;


        img {
            height: 100%;
            width: 100%;
        }
    }

    .course {
        padding: 0 25px;
    }

    .tab {
        display: flex;
        justify-content: flex-start;
        margin-top: 30px;
        // padding: 0 25px;

        .item {
            // width: 117px;
            padding: 0 10px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            font-weight: 500;
            margin-right: 20px;
            cursor: pointer;
            transition: color .3s linear;

            &:hover {
                opacity: .7;
            }
        }

        .item-active {
            background: var(--colorB);
            border-radius: 10px;
            color: #fff;
        }
    }
}

.list {
    width: 100%;
    padding: 20px 0px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 450px);
    grid-column-gap: 40px;
    grid-row-gap: 40px;

    // .course-item {

    // }
}

.empty {
    height: 600px;
}
</style>