<template>
  <div class="container-course">
    
    <ul class="tab" style="margin-bottom:20px;">
      <li
        class="item"
        v-for="(item,index) in typeList"
        :key="item.decorateTypeId"
        :class="[index === currentIndex ? 'item-active' : '']"
        @click="onTabChangeA(index)"
      >
        <p>{{ item.platform }}</p>
      </li>
    </ul>
    <ul class="tab">
      <li
        class="item"
        v-for="item in typeList[currentIndex].children"
        :key="item.decorateTypeId"
        :class="[condition.id === item.decorateTypeId ? 'item-active' : '']"
        @click="onTabChange(item.decorateTypeId)"
      >
        <p>{{ item.name }}</p>
      </li>
    </ul>
    <CourseList :caseList="caseList" :pagination.sync="pagination"></CourseList>
  </div>
</template>
<script>
import { saveUserBrowseData } from "@/api/common";

import CourseList from "@/components/course/courseList.vue";
export default {
  name: "courseList",
  components: {
    CourseList,
  },
  data() {
    return {
        currentIndex:0,
      active: 0,
      caseList: [],
      typeList: [],
      condition: {
        id: 0,
      },
      pagination: {
        total: 0,
        current: 1,
        size: 12,
      },
    };
  },

  provide() {
    return {
      //这个api设定主动通知 将组件实例绑定在根组件上
      setChildrenRef: (name, ref) => {
        this[name] = ref;
      },
      //这个api是主动获取绑定的组件
      getChildrenRef: (name) => {
        return this[name];
      },
      // 这个是获取根组件
      getRef: () => {
        return this;
      },
    };
  },
  watch: {
    "pagination.current": {
      deep: true,
      handler(newVal, oldVal) {
        this.getList();
      },
    },
  },
  created() {
    this.getCourseType();
    saveUserBrowseData({
      typeId: 3,
    });
  },
  activated() {
    // this.getList()
  },
  methods: {
    onTabChange(type) {
      this.condition.id = type;
      this.pagination.current = 1;
      this.getList();
    },


    onTabChangeA(index){
    this.currentIndex = index
    this.onTabChange(this.typeList[this.currentIndex].children[0].decorateTypeId)  
    },

    // 获取装修教程类型
    async getCourseType() {
      try {
        const res = await this.$http.get("/decorate-type/getAll");
    


        console.log(res,"res")

        const platformMap = {};

        // 遍历原始数组，按照 platform 值将对象分组存储
        res.data.forEach((item) => {
          const platform = item.platform;
          if (!platformMap[platform]) {
            platformMap[platform] = {
              platform, // Store the platform string directly
              children: [],
            };
          }
          platformMap[platform].children.push(item);
        });

        // 最终的结果为一个对象数组，其中每个元素代表了一个共同的 "platform" 值下的对象集合，包含了 platform 和 children 数组。
        const result = Object.values(platformMap);


        this.typeList = result;

        this.condition.id = this.typeList[0].children[0].decorateTypeId;
        

        console.log(result, "result");

        this.getList();
      } catch (e) {}
    },

    async getList() {
      const params = {
        ...this.condition,
        ...this.pagination,
      };
      try {
        const res = await this.$http.post("/decorate/getByType", params);
        this.caseList = res.data.records;
        this.pagination.total = res.data.total;
      } catch (e) {}
    },
  },
};
</script>
<style lang="scss" scoped>
.container-course {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;

  // height: calc(100vh - 70px);
  // background: linear-gradient(rgba(47, 47, 255, 0.05) 0%, rgba(196, 196, 196, 0) 40%);
  .tab {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    .item {
      // width: 117px;
      padding: 0 10px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 500;
      // margin-right: 20px;
      cursor: pointer;
      transition: color 0.3s linear;

      &:hover {
        opacity: 0.7;
      }
    }

    .item-active {
      background: var(--colorB);
      border-radius: 10px;
      color: #fff;
    }
  }
}
</style>