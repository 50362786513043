import axios from '@/service/axios'

// 保存浏览记录
export function saveBrowse(params) {
    return axios.post('/browse/saveBrowse', params)
}

// 获取用户信息
export function getUserInfo() {
    return axios.get('/user/getLoginUser')
}


// 到期服务提示
export function closeTip() {
    return axios.get('/user-spm/spm')
}

// 获取手机验证码
export function getCode(params) {
    return axios.get('/admin/getMobileCode', params)
}

// 获取配置参数
export function getUpLoadFileParams() {
    return axios.get('/case/getUpload')
}

// 埋点
export function saveUserBrowseData(params) {
    return axios.post('/spm/saveSpm',params)
}

// 埋点
export function saveUserBrowseDataB(params) {
    return axios.post('/spm/noLoginSpm',params)
}

