<template>
  <div class="case-item" >
    <div class="check" v-if="$store.state.showShare">
      <el-checkbox  v-model="checked" size="small" @change="onCheckChange"></el-checkbox>
    </div>

    <div class="box-video">
      <div
        v-if="detail.dynamicCode && detail.typeId === 1"
        style="
          zoom: 0.335;
          position: relative;
          display: flex;
          justify-content: center;
        "
      >
        <div style="position: relative">
          <DecorationModuleCode
            :content="JSON.parse(detail.dynamicCode)"
          ></DecorationModuleCode>
        </div>
      </div>
      <template v-else>
        <video
          class="video"
          ref="myVideo"
          :controls="controls"
          controlslist="nodownload"
        >
          <source :src="detail.videoUrl" type="video/mp4" />
        </video>
        <div class="play" v-if="!controls">
          <img
            @click="operateVideo"
            src="../../assets/images/case/play.png"
            width="40"
            height="40"
            alt=""
          />
        </div>
      </template>

      <div class="box-innovate">
        <div class="innovate" v-if="detail.isinnovation === 1">创新模块</div>
      </div>
    </div>

    <div class="info-A">
      <div class="tags">
        <div
          class="tags-item"
          v-for="(tag, index) in detail.labels"
          :style="[{ background: tag.color }]"
          :key="index"
        >
          {{ tag.name }}
        </div>
        <div
        v-if="detail.typeId===2"
          class="tags-item"
          style="background:#444eff;"
        >
          功能
        </div>
      </div>
      <p class="title">
        {{ detail.name }}
      </p>
    </div>

    <div class="info-b" v-if="$store.state.userInfo">
      <div class="data">
        <template v-if="!hiddenData">
          <div class="eyes">
            <i class="el-icon el-icon-view"></i>
            <p class="text">{{ detail.browseNum ? detail.browseNum : 0 }}</p>
          </div>
          <div class="collection">
            <i class="el-icon el-icon-star-on"></i>
            <p class="text">
              {{ detail.collectionNum ? detail.collectionNum : 0 }}
            </p>
          </div>
        </template>
      </div>
      <div class="right">
        <div class="collection collection-active" @click="onCollection">
          <i class="el-icon-star-off" v-if="!detail.collectionId"></i>
          <i class="el-icon-star-on" v-else style="color: #df583a"></i>
        </div>

        <!-- 装修模块 0为免费 必须包含代码数据才能显示在线编辑 -->
        <button
          class="button-edit"
          @click="onlineEdit"
          v-if="detail.typeId === 1"
        >
          <i
            class="el-icon"
            :class="[
              detail.dynamicCode ||
              (detail.dynamicCode && $store.state.identity === 2)
                ? 'el-icon-edit-outline'
                : 'el-icon-chat-line-square',
            ]"
          ></i>
          <p>
            {{
              detail.dynamicCode ||
              (detail.dynamicCode && $store.state.identity === 2)
                ? "在线编辑"
                : "查看详情"
            }}
          </p>
        </button>

        <!-- 小部件 -->
        <button class="button-consult" v-else @click="onConsult">
          <i class="el-icon el-icon-chat-line-square"></i>
          <p>查看详情</p>
        </button>
      </div>
    </div>

    <CaseModelA
      v-if="caseModelAdialogVisible"
      :activedCaseDetail="detail"
      :caseModelAdialogVisible.sync="caseModelAdialogVisible"
    ></CaseModelA>
    <CaseModelB
      v-if="caseModelBdialogVisible"
      :activedCaseDetail="detail"
      :caseModelBdialogVisible.sync="caseModelBdialogVisible"
    ></CaseModelB>
    <OnlineEdit
      v-if="onlineEditVisible"
      :activedCaseDetail="detail"
      :onlineEditVisible.sync="onlineEditVisible"
    >
    </OnlineEdit>
  </div>
</template>

<script>
import CaseModelA from "./caseModelA.vue";
import CaseModelB from "./caseModelB.vue";
import OnlineEdit from "./onlineEdit.vue";
import { getCaseInfo, saveEditRecord } from "@/api/case";
import { saveBrowse, saveUserBrowseData } from "@/api/common";
import DecorationModuleCode from "@/components/decorationModuleCode/index.vue";

export default {
  components: {
    CaseModelA,
    CaseModelB,
    OnlineEdit,
    DecorationModuleCode,
  },
  props: {
    detail: {
      type: Object,
      default: {},
      require: true,
    },
    hiddenData: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  inject: {
    getParentRef: {
      from: "getRef",
      default: () => {},
    },
  },
  data() {
    return {
      controls: false,
      activedCaseDetail: null,
      caseModelAdialogVisible: false,
      caseModelBdialogVisible: false,
      onlineEditVisible: false,
    };
  },

  methods: {
    onCheckChange(e){
      console.log(e,"e")
      let list  = this.$store.state.shareData.caseList
      console.log(list)
      if(e){
        list.push(this.detail.caseId)
        console.log(list,"list1")
      }else{
        list = list.filter(item=>item!==this.detail.caseId)
        console.log(list,"list2")
      }
      console.log(list,"12")
      this.$store.commit("onUpdateCaseList",list)
    },
    //保存浏览记录
    async onSaveBrowse() {
      const params = {
        type: 0,
        id: this.detail.caseId,
      };
      try {
        const res = await saveBrowse(params);
        this.detail.browseNum = this.detail.browseNum + 1;
        // this.getParentRef().getList()
      } catch (e) {
        console.log(e);
      }
    },

    //保存编辑记录
    async onSaveEditRecord() {
      try {
        const res = await saveEditRecord(this.detail.caseId);
      } catch (e) {
        console.log(e);
      }
    },

    onCollection() {
      if (this.$store.state.identity === 0) {
        this.$store.commit("onShowLogindialog", true);
        return false;
      }
      if (this.detail.collectionId) {
        this.onCancelCollection();
      } else {
        this.onSaveCollection();
      }
    },

    // 在线编辑
    onlineEdit() {
      // 不是免费模块 代码数据存在的情况下 普通用户展示年框专享弹窗
      if (
        this.detail.isfree === 1 &&
        this.$store.state.identity !== 2 &&
        this.detail.dynamicCode
      ) {
        this.caseModelBdialogVisible = true;
        saveUserBrowseData({
          typeId: 20,
          spmId: this.detail.caseId,
        });
      } else if (!this.detail.dynamicCode) {
        // 代码数据不存在 展示详情
        this.onConsult();
      } else {
        this.onSaveEditRecord();
        this.onlineEditVisible = true;
      }
      saveUserBrowseData({
        typeId: 19,
        spmId: this.detail.caseId,
      });
    },

    // 咨询使用  需要先查询案例标签，使用行业 场景
    async onConsult() {
      try {
        const res = await getCaseInfo(this.detail.caseId);
        this.detail = {
          ...res.data,
          ...this.detail,
        };
        this.caseModelAdialogVisible = true;
        saveUserBrowseData({
          typeId: 25,
          spmId: this.detail.caseId,
        });
      } catch (e) {
        console.log(e);
      }
    },

    operateVideo() {
      this.controls = true;
      this.onSaveBrowse();
      this.$refs.myVideo.play();
    },

    // 收藏案例
    async onSaveCollection() {
      const data = {
        type: 0,
        id: this.detail.caseId,
      };
      try {
        const res = await this.$http.post("/collection/collectionByType", data);
        this.detail.collectionNum = this.detail.collectionNum + 1;

        this.detail.collectionId = "12";
        // this.$emit('detail',{...this.detail,collectionId:12,})
        // this.getParentRef().getList()
      } catch (e) {
        this.$message.error(e.message);
      }
    },

    // 取消收藏案例
    async onCancelCollection() {
      const data = {
        type: 0,
        id: this.detail.caseId,
      };
      try {
        const res = await this.$http.post("/collection/removeCollection", data);
        this.detail.collectionNum = this.detail.collectionNum - 1;

        this.detail.collectionId = null;
        // this.$emit('detail',{...this.detail,collectionId:12,})
        // this.getParentRef().getList()
      } catch (e) {
        this.$message.error(e.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.case-item {
  position: relative;
  .case-tag{
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 99;
    background: #eee;
    color: #000;
    padding: 4px;
    border-radius: 6px;
    font-size: 12px;
  }
  .check{
    position: absolute;
    z-index: 999;
    top: 20px;
    left: 20px;
  }
  // border: 1px solid #000;
  .box-innovate {
    position: absolute;
    z-index: 99;
    top: 0px;
    width: 75px;
    height: 72px;
    overflow: hidden;
    left: 0px;

    .innovate {
      width: 92px;
      height: 24px;
      color: rgb(255, 255, 255);
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgb(255, 255, 255);
      background: linear-gradient(
        to left top,
        rgba(254, 220, 68, 1) 0%,
        rgba(250, 112, 154, 1) 100%
      );
      transform: rotate(-45deg) translateX(-24px) translateY(-5px);
    }
  }

  .box-video {
    width: 100%;
    height: 550px;
    border: 1px solid rgb(244, 244, 244);
    border-radius: 10px;
    overflow: hidden;
    position: relative;

    &:hover {
      .video {
        transform: scale(1.05);
      }
    }

    .video {
      width: 100%;
      height: 100%;
      transition: all 0.5s ease;
    }

    .play {
      position: absolute;
      height: 100%;
      width: 100%;
      background: rgba(0, 0, 0, 0.4);
      display: flex;
      align-items: center;
      justify-content: center;
      left: 0;
      top: 0;
    }
  }


  .info-A {
    display: flex;
    align-items: center;
    margin: 10px 0;

    .tags {
      display: flex;
      align-items: center;
      color: rgb(255, 255, 255);
      font-size: 12px;

      .tags-item {
        padding: 1px 3px;
        border-radius: 2px;
        min-width: 30px;
        border-radius: 4px;

        &:nth-child(1) {
          background: linear-gradient(
            rgba(253, 85, 144, 1) 0%,
            rgba(247, 109, 102, 1) 100%
          );
          margin-right: 3px;
        }

        &:nth-child(2) {
          background: linear-gradient(
            rgba(59, 210, 115, 1) 0%,
            rgba(51, 222, 183, 1) 100%
          );
        }
      }
    }

    .title {
      flex: 1;
      color: rgb(0, 0, 0);
      font-size: 14px;
      margin-left: 6px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .info-b {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .data {
      display: flex;
      align-items: center;
      color: var(--colorA);
      font-size: 12px;

      .el-icon {
        font-size: 16px;
      }

      .eyes {
        width: 63px;
        display: flex;
        align-items: center;

        p {
          margin-left: 2px;
        }
      }

      .collection {
        width: 63px;
        display: flex;
        align-items: center;

        p {
          margin-left: 2px;
        }
      }
    }

    .collection-active {
      // background: #eaeaea;
    }

    .right {
      display: flex;
      align-items: center;

      .collection {
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        border: 1px solid var(--colorD);
        border-radius: 4px;
        color: var(--colorA);
        font-size: 16px;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }
      }

      button {
        width: 90px;
        height: 25px;
        border: 1px solid var(--colorD);
        border-radius: 4px;
        color: var(--colorA);
        font-size: 12px;
        background: none;
        font-family: Noto Sans SC;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          border: none;
          color: var(--colorC);
          background: var(--colorB);
        }

        .el-icon {
          font-size: 14px;
          margin-right: 2px;
          margin-top: 2px;
        }
      }
    }
  }
}
</style>