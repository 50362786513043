import axios from '@/service/axios'


// 
export function getOperationType(params) {
    return axios.get(`/custom-type/getAll`)
}

export function getOperationList(params) {
    return axios.post(`/custom/getByBannerId`, params)
}

export function onLike(params) {
    return axios.get(`/custom-like/addLike/${params}`)
}