<template>
    <div class="container-main" :class="$store.state.identity === 2 ? 'container-mian-vip' : 'container-main-common'">
        <keep-alive>
            <router-view :key="key"></router-view>
        </keep-alive>
    </div>
</template>
<script>
import CourseList from '@/components/course/courseList.vue'
export default {
    name:'course',
    components: {
        CourseList
    },
    data() {
        return {

        }
    },
    computed: {
        key() {
            // return this.$key();
            return this.$route.path
        }
    },
    created() {

    },
    methods: {

    }
}

</script>
<style lang="scss" scoped>
.container-main {
    min-height: calc(100vh - 70px);
    padding-top: 30px;
    background: var(--BG);
}
</style>