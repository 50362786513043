<template>
    <div class="container-course">
        <template v-if="list[0]">
            <div class="case-list">
                <CustomCaseItem v-for="item in list" :detail="item" :key="item.decorateId"></CustomCaseItem>
            </div>
            <Pagination :pagination.sync="pagination"></Pagination>
        </template>
        <el-empty class="empty" v-else description="暂无数据！"></el-empty>
    </div>
</template>

<script>
import Pagination from '@/components/pagination'
import CustomCaseItem from './customCaseItem.vue'
export default {
    components: {
        CustomCaseItem,
        Pagination
    },
    props: {
        list: {
            type: Array,
            default: [],
            required: true,
        },
        pagination: {
            type: Object,
            default: () => { return {} },
            required: true
        },
    },
    data() {
        return {
        }
    },
}
</script>

<style lang="scss" scoped>
.case-list {
    padding: 20px 0;
    display: grid;
    grid-template-columns: 1fr;
    // grid-column-gap: 26px;
    grid-row-gap: 20px;
}

.empty {
    height: 600px;
}
</style>