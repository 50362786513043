<template>
    <el-dialog :visible="visible" custom-class="container-feedback" width="520px">
        <div class="content">
            <div class="header">
                <div class="title">
                    <img src="../../assets/images/silder/notifications.png" alt="">
                    <p>
                        添加分享
                    </p>
                </div>
            </div>
            <div class="main">
                <div class="list">
                    <div class="item" v-for="item in list" :key="item.id">
                        <div class="left">
                            <div class="name">{{item.title}}</div>
                            <div class="total">{{item.total}}条内容</div>
                        </div>
                        <el-button type="primary" size="small" @click="onJoin(item.id)">加入</el-button>
                    </div>
         
                </div>
            </div>
            <button class="button-submit" @click="onCreate">创建分享</button>
            <Close @onClose="onClose"></Close>
        </div>
        <el-dialog
      width="30%"
      title="创建分享"
      :visible.sync="showForm"
      append-to-body>
      <el-form ref="form" :rules="rules" :model="form" label-width="80px">
        <el-form-item label="分享标题" prop="title">
            <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="分享描述" prop="content">
    <el-input type="textarea" v-model="form.content"></el-input>
</el-form-item>
        </el-form>
  </el-form-item>
  <span slot="footer" class="dialog-footer">
    <el-button @click="onCancelCreate">取 消</el-button>
    <el-button type="primary" @click="onConfirmCreate">创建</el-button>
  </span>
    </el-dialog>
    </el-dialog>
</template>

<script>
import Close from "@/components/close";
import Editor from "@/components/editor";
import { feedback } from "@/api/home.js";
import { getShareWrapAll, addShareWrap, addShareContent } from "@/api/share";
export default {
  components: {
    Close,
    Editor,
  },
  props: {
    // visible: {
    //   type: Boolean,
    //   default: true,
    //   required: false,
    // },
  },
  data() {
    return {
      type: 0,
      form: {
        type: 0,
        content: null,
      },
      list: [],
      visible: true,
      showForm: false,
      form: {
        title: "",
        content: "",
      },
      rules: {
        title: [{ required: true, message: "请输入分享标题", trigger: "blur" }],
        // content: [
        //   { required: true, message: "请输入分享描述", trigger: "blur" },
        // ],
      },
    };
  },
  created() {
    this.onGetShareWrapAll();
  },
  methods: {
    onClose() {
      this.visible = false;
      this.$store.commit("onShowSharePackage", false);
    },
    onCreate() {
      this.showForm = true;
    },
    async onJoin(id) {
      const data = {
        id,
        ...this.$store.state.shareData,
      };

      try {
        const res = await addShareContent(data);
        this.onGetShareWrapAll();
        const h = this.$createElement;
        // this.list = res.data;
        this.$message({
          duration: 2000,
          message: h("div", { style: "display:flex;align-items: center;" }, [
            h("span", null, "成功加入"),
            h(
              "p",
              {
                style: "color: teal;cursor: pointer;",
                on: {
                  click: () => {
                    this.onView(id);
                  },
                },
              },
              "点击查看"
            ),
          ]),
        });
      } catch (e) {
        this.$message.error(e.message);
      }
    },
    onView(id) {
      let url = this.$router.resolve({
        path: "/share",
        query: { id: id },
      });
      window.open(url.href, "_blank");
      console.log(id, "id");
    },
    onCancelCreate() {
      this.form = {
        title: "",
        content: "",
      };
      this.showForm = false;
    },
    async onConfirmCreate() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          try {
            const res = await addShareWrap(this.form);
            this.onGetShareWrapAll();
            this.onCancelCreate();
          } catch (e) {
            this.$message.error(e.message);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async onGetShareWrapAll() {
      try {
        const res = await getShareWrapAll();
        console.log(res, "res");
        this.list = res.data;
      } catch (e) {
        this.$message.error(e.message);
      }
    },
    async onFeedback() {
      if (!this.form.content || this.form.content === "<p><br></p>") {
        this.$message.warning("请输入反馈内容");
        return false;
      }
      try {
        const res = await feedback(this.form);
        this.$message.success("反馈成功!客服人员会在1-3个工作日处理您的反馈");
        this.onClose();
      } catch (e) {
        this.$message.error(e.message);
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.container-feedback {
  .list {
    .item {
      height: 60px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #eee;
    }
  }
  .header {
    padding: 0 20px;
    height: 60px;
    border-bottom: 1px solid rgb(229, 229, 229);
    display: flex;
    align-items: center;

    .title {
      display: flex;
      align-items: center;

      img {
        width: 30px;
        margin-right: 4px;
      }

      p {
        color: rgb(0, 0, 0);
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  .content {
    padding-bottom: 30px;
  }

  .main {
    padding: 30px;

    .raido {
      margin-bottom: 15px;

      :deep(.is-checked) {
        .el-radio__inner {
          border-color: var(--colorB);
          background: var(--colorB);
        }

        .el-radio__label {
          color: rgb(0, 0, 0);
        }
      }
    }

    .tip {
      color: rgba(0, 0, 0, 0.5);
      font-family: Noto Sans SC;
      font-size: 12px;
      margin-top: 10px;
    }
  }

  .button-submit {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 250px;
    height: 50px;
    background: var(--colorB);
    color: var(--colorWB);
    border-radius: 40px;
    border: none;
    font-family: Noto Sans SC;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
}
</style>

<style lang="scss">
.container-feedback {
  z-index: 9999 !important;
  border-radius: 14px !important;
  // height: 638px;
  position: relative;
  overflow: hidden;

  .el-dialog__header {
    display: none !important;
  }

  .el-dialog__body {
    padding: 0;
  }
}
</style>