<template>
    <p class="item-text" :style="styles" @click="onNavigation">{{detail.content}}</p>
</template>

<script>
// 图片
export default {
    name: 'Text',
    props: {
        detail: {
            type: Object,
            default: {},
            required: true,
        }
    },
    data() {
        return {
            styles: {
                position: 'absolute',
                width: `${this.detail.width}px`,
                height: `${this.detail.height}px`,
                left: `${this.detail.left}px`,
                top: `${this.detail.top}px`,
                borderRadius: `${this.detail.bR}px`,
                transform: `rotate(${this.detail.rotate}deg)`,
                fontSize: `${this.detail.size}px`,
                color: `${this.detail.color}`,
                textAlign: this.detail.align,
                lineHeight: `${this.detail.lineheight}px`,
                background: this.detail.bC,
                fontWeight: this.detail.weight,
                fontStyle: this.detail.italic,
                textDecoration: this.detail.through,
            }
        }
    },
    created() {
        if (this.detail.style&&this.detail.style.borderStatus==='open') {
            
            this.styles = {
                ...this.styles,
                borderTop: this.detail.style.border.t==='true' ? `${this.detail.style.border.width}px ${this.detail.style.border.style} ${this.detail.style.border.color}` : '',
                borderLeft: this.detail.style.border.l==='true' ? `${this.detail.style.border.width}px ${this.detail.style.border.style} ${this.detail.style.border.color}` : '',
                borderRight: this.detail.style.border.R ==='true'? `${this.detail.style.border.width}px ${this.detail.style.border.style} ${this.detail.style.border.color}` : '',
                borderBottom: this.detail.style.border.b ==='true'? `${this.detail.style.border.width}px ${this.detail.style.border.style} ${this.detail.style.border.color}` : '',
            }
        }
    },
    methods: {
        onNavigation() {
            if (this.detail.url) {
                window.open(this.detail.url, '_blank')
            }
        },
    },
}
</script>

<style lang="scss" scoped>

</style>