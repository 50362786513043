<template>
  <div class="container-layout">
    <Loading v-if="$store.state.loading"></Loading>
    <HeaderView></HeaderView>
    <keep-alive>
      <router-view ref="appView" v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>

    <router-view ref="appView" v-if="!$route.meta.keepAlive"></router-view>

    <FooterView v-if="$route.name === 'home'"></FooterView>
    <Silder></Silder>
    <!-- <LoginView v-if="$store.state.loginDialogVisible"></LoginView> -->
    <HomeWindow></HomeWindow>
    <SharePackage v-if="$store.state.showSharePackage"></SharePackage>
  </div>
</template>

<script>
import Loading from "@/components/loading";
import HeaderView from "./header.vue";
import FooterView from "./footer.vue";
import Silder from "@/components/silder";
import LoginView from "@/views/login/index.vue";
import HomeWindow from "@/components/homewindow/index.vue";
import SharePackage from "@/components/sharePackage/index.vue";
export default {
  components: {
    Loading,
    HeaderView,
    FooterView,
    Silder,
    LoginView,
    HomeWindow,
    SharePackage,
  },
  data() {
    return {};
  },
  computed: {
    key() {
      return this.$route.path + Math.random();
    },
  },
};
</script>

<style lang="scss" scoped>
.container-layout {
  min-width: 100%;
  min-height: 100%;
}
</style>
