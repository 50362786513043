<template>
    <div class="container-systeman animate__animated" :style="styles">
        <DecorationModuleCode :content="detail.config" v-if="detail.imageType==='diy'"></DecorationModuleCode>
        <img v-else :src="detail.bI" alt="">
    </div>
</template>

<script>
import 'animate.css';
const DecorationModuleCode = () => import('@/components/decorationModuleCode/index.vue')
export default {
    props: {
        detail: {
            type: Object,
            default: {},
            required: true,
        }
    },
    components: {
        DecorationModuleCode
    },
    data() {
        return {
            styles: {
                width: `${this.detail.width}px`,
                height: `${this.detail.height}px`,
                left: `${this.detail.left}px`,
                top: `${this.detail.top}px`,
                transform: `route(${this.detail.rotate}deg)`,
                opacity: `${this.detail.opacity}`,
                borderRadius: `${this.detail.bR}px`,
                animationName: `${this.detail.config.name}`,
                animationDuration: `${this.detail.config.duration}s`,
                animationDelay: `${this.detail.config.delay}s`,
                animationIterationCount: `${this.detail.config.iterationType === 'infinite' ? this.detail.config.iterationType : this.detail.config.count ? this.detail.config.count : 1}`,
                animationFillMode: `${this.detail.config.fillMode}`,
                animationDirection: `${this.detail.config.direction}`,
                // animationTimingFunction:`${this.detail.config.ttf?this.detail.config.ttf:'linear'}`
            }
        }
    },
    created() {

    },
    mounted() {

    },
    methods: {

    },


}
</script>

<style lang="scss" scoped>
.container-systeman {
    position: absolute;
}
</style>