<template>
    <el-dialog :visible.sync="$store.state.loginDialogVisible" :close-on-press-escape="false"
        :close-on-click-modal="false" :keyboard="false" custom-class="container-login" title="Tips" width="1000px">
        <div class="content">

            <div class="left">
            </div>


            <div class="right">
                <div class="tabs">
                    <div class="item" :class="{ 'active': formType === 1 }" @click="onTabChange(1)">手机号登录</div>
                    <div class="item" :class="{ 'active': formType === 2 }" @click="onTabChange(2)">账号密码登录</div>
                </div>
                <div class="form">
                    <el-form :model="form" ref="formA" v-show="formType === 1" :rules="rulesA">
                        <el-form-item class="item" label="" prop="mobile" :error="errMessage">
                            <div class="icon">
                                <img src="../../assets/images/personal/phone.png" style="width:13px;height:20px" alt="">
                            </div>
                            <el-input placeholder="请输入手机号" oninput="value=value.replace(/[^\d]/g, '')" maxlength="11"
                                v-model.trim="form.mobile" clearable>
                            </el-input>
                        </el-form-item>
                        <el-form-item class="item" label="" prop="code">
                            <div class="icon">
                                <img src="../../assets/images/personal/code.png" style="width:19px;height:19px" alt="">
                            </div>

                            <el-input class="input-code" maxlength="6" @keyup.enter.native="onSubmit('formA')"
                                oninput="value=value.replace(/[^\d]/g, '')" v-model="form.code" placeholder="请输入六位验证码"
                                clearable>
                            </el-input>
                            <el-button class="button-code" @click="getMobileCode" :disabled="codeDisabled">{{
                            getCodeText
                            }}</el-button>
                        </el-form-item>
                        <button class="buttom-submit" type="button" @click="onSubmit('formA')">
                            立即登录
                        </button>
                        <p class="tip" @click="onShowTip(60)">手机号未注册？点这里</p>
                    </el-form>
                    <el-form :model="form" ref="formB" v-show="formType === 2" :rules="rulesB">
                        <el-form-item class="item" label="" prop="username">
                            <div class="icon">
                                <img src="../../assets/images/login/account.png" alt="">
                            </div>
                            <el-input placeholder="请输入账号" v-model="form.username" clearable>
                            </el-input>
                        </el-form-item>
                        <el-form-item class="item" label="" prop="password">
                            <div class="icon">
                                <img src="../../assets/images/personal/password.png" style="width:15px;height:20px"
                                    alt="">
                            </div>
                            <el-input type="password" placeholder="请输入密码" @keyup.enter.native="onSubmit('formB')"
                                v-model="form.password" clearable>
                            </el-input>
                        </el-form-item>
                        <P class="forget" @click="onShowTip(61)">忘记密码？</P>
                        <button class="buttom-submit" type="button" @click="onSubmit('formB')">
                            立即登录
                        </button>
                        <p class="tip" @click="onShowTip(62)">没有账号？点这里</p>
                    </el-form>
                </div>
            </div>
        </div>

        <el-dialog width="450px" class="tip-dialog" title="内层 Dialog" :visible.sync="tipVisible" append-to-body>
            <div class="box-tip">
                <p class="title">{{ tipType === 2 ? '请联系客服人员处理' : '请联系客服人员添加账号' }}</p>
                <div class="text">
                    <img src="../../assets/images/silder/chat.png" alt="">
                    <p>联系方式17723680201</p>
                    <span>（钉钉微信同号）</span>
                </div>
                <div class="close" @click="tipVisible = false">
                    <img src="../../assets/images/login/close.png" alt="">
                </div>
            </div>
        </el-dialog>
    </el-dialog>
</template>
<script>
import { set } from 'vue';
import { saveUserBrowseDataB } from '@/api/common'
export default {
    name: 'login',
    components: {

    },
    data() {
        return {
            tipType: 1,
            formType: 1,
            tipVisible: false,
            errMessage: '',
            html: '',
            form: {
                mobile: '',
                code: '',
                username: '',
                password: '',
            },
            isBind: false,
            getCodeText: "获取验证码",
            codeDisabled: false,
            getCodeTime: 60,
            getCodeTimer: null,
            rulesA: {
                mobile: [{ required: true, message: '请输入手机号', trigger: 'blur' },
                { pattern: /^1[3456789][0-9]{9}$/, message: '请输入正确的号码格式', trigger: 'blur' }
                ],
                code: [
                    { required: true, message: '请输入验证码', trigger: 'blur' },
                ],
            },
            rulesB: {
                username: [{ required: true, message: '请输入账号', trigger: 'blur' },
                ],
                password: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                ],
            }
        }
    },
    created() {
        this.checkCodeTime()
    },
    methods: {
        // 防止刷新网页重置获取验证码时间
        checkCodeTime() {
            // 本地拿到时间
            const time = localStorage.getItem('loginTime');
            // 判断是否大于0
            if (time && time > 0) {
                //  解决刷新页面按钮有空白情况
                this.getCodeText = time + "s后重新发送"
                this.getCodeTime = time
                this.onSendCode();
            }
        },

        // 显示提示框
        onShowTip(type) {
			let text = ''
			switch(type){
				case 60:
				text = '我的手机号无法登陆，如何绑定手机号？'
				break;
				case 61:
				text = '我忘记了我的密码，请问如何修改密码？'
				break;
				case 62:
				text = '我想注册账号，请问如何注册？'
				break;
			}

			window.m7CardInfo = {
			    "left": {
			        "url": 'https://gm-case.oss-cn-hangzhou.aliyuncs.com/20221111/icon_%E9%97%AE%E9%A2%98-vc-upload-1668150944441-2.png'  // 左侧图片地址，可不填
			    },
			    "right1": {
			        "text": text,  // 首行文字内容，展示时超出两行隐藏，卡片上单行隐藏
			        "color": "#000",                // 字体颜色，支持十六位 #ffffff 格式的颜色，不填或错误格式默认#595959
			        "fontSize": 14                      // 字体大小， 默认12 ， 请传入number类型的数字
			    },
			    "right2": {
			
			        "text": '',        // 第二行文字内容，展示时超出两行隐藏，卡片上单行隐藏
			        "color": "#666",                 // 字体颜色，支持十六位 #ffffff 格式的颜色，不填或错误格式默认#595959
			        "fontSize": 12                      // 字体大小， 默认12 ， 请传入number类型的数字
			    },
			    "right3": {
			        "text": "",           // 第三行文字内容，展示时超出两行隐藏，卡片上单行隐藏
			        "color": "#ff6b6b",                 // 字体颜色，支持十六位 #ffffff 格式的颜色，不填或错误格式默认#ff6b6b
			        "fontSize": 14                      // 字体大小， 默认14 ， 请传入number类型的数字
			    },
			    "extraInfos": [""],             // 额外信息，访客不可见，座席点击卡片上的更多可见，可不填，字符串形式的文本数组
			    "url": ''                   // 点击可跳转的链接
			}
			window.qimoChatClick()
			saveUserBrowseDataB({
			    typeId: type,
			})
        },

        // 登录验证
        onSubmit(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.onConfrimSubmit()
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },

        // 获取验证码
        getMobileCode() {
            this.$refs.formA.validateField('mobile', (error) => {
                if (!error) {
                    this.onConfirmsentCode()
                }
            })
        },

        // 获取手机验证码
        async onConfirmsentCode() {
            try {
                const res = await this.$http.get('/admin/getMobileCode', { mobile: this.form.mobile })
                this.onSendCode()
            } catch (err) {
                this.errMessage = err.message
                setTimeout(() => {
                    this.errMessage = ''
                }, 4000)
                // this.$message.error(err)
            }
        },
        onBeforeClose() {
            console.log(this.form)
            this.form = {
                mobile: '',
                code: '',
                username: '',
                password: '',
            }
        },

        // 提交登录
        async onConfrimSubmit() {
            let data = {}
            let url = ''
            if (this.formType === 1) {
                data = {
                    "mobile": this.form.mobile,
                    "code": this.form.code,
                }
                url = '/admin/logInByMobile'
            } else {
                data = {
                    "username": this.form.username,
                    "password": this.$md5(this.form.password.toLowerCase()),
                }
                url = '/admin/login'
            }

            try {
                const res = await this.$http.post(url, data, true)
                localStorage.setItem('token', res.data.token)
                localStorage.setItem('showWindow', 1)
                localStorage.setItem("userInfo", JSON.stringify(res.data.user))
                setTimeout(() => {
                    this.$router.go(0) 
                }, 2000)
            } catch (err) {
                if (err.message) {
                    this.$message.error(err.message)
                }
                console.log(err)
            }
        },

        // 获取用户信息
        async getUserInfo() {
            try {
                const res = await this.$http.get('/user/getLoginUser')
                this.$store.commit('updateUserInfo', res.data)
                this.onClose()
                this.$router.go(0)
            } catch (err) {
                console.log(err)
            }
        },

        // 关闭登录弹窗
        onClose() {
            this.form = {
                mobile: '',
                code: '',
                username: '',
                password: '',
            }
            this.$refs.formA.resetFields()
            this.$refs.formB.resetFields()
            this.$store.commit('onShowLogindialog', false)
        },

        onSendCode() {
            // 点击按钮，禁用按钮，防止多次点击
            this.codeDisabled = true
            this.getCodeText = this.getCodeTime + "s后重新发送"
            localStorage.setItem('loginTime', this.getCodeTime)
            this.getCodeTimer = setInterval(() => {
                if (this.getCodeTime > 0) {
                    this.getCodeTime--
                    localStorage.setItem('loginTime', this.getCodeTime)
                    this.getCodeText = this.getCodeTime + "s后重新发送"
                } else {
                    clearInterval(this.getCodeTimer);
                    this.getCodeTime = 60
                    this.codeDisabled = false
                    this.getCodeText = this.form.mobile ? '重新发送' : '获取验证码'
                }
            }, 1000)
        },
        onTabChange(type) {
            this.formType = type
        },
        handleClick() {

        }
    }
}
</script>


<style lang="scss" scoped>
.content-box {
    width: 100%;
    height: 480px;
    // box-shadow: 1px 1px 8px rgba(51, 70, 114, 0.1);
    // box-sizing: border-box;
    // background: linear-gradient(rgba(69, 79, 255, .6) 0%, rgba(31, 209, 250, .6) 99.237%);
    // border-radius: 14px;
}

.content {
    position: relative;
    width: 100%;
    height: 580px;
    display: flex;
    align-items: center;
    overflow: hidden;

    .close {
        position: absolute;
        z-index: 999;
        right: 10px;
        top: 10px;
        cursor: pointer;

        &:hover {
            scale: 1.05;
        }
    }

    .left {
        height: 100%;
        width: 525px;
        background: rgb(69, 79, 255) url('../../assets/images/login/left-bg.png') 0 0 no-repeat;
        background-size: cover;
        position: relative;

        .particles-b {
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 98;
            height: 20%;
            width: 100%;
        }

        .shader {
            position: absolute;
            left: 0;
            top: 0;
            z-index: -1;
            height: 100%;
            width: 100%;
        }

        .info {
            height: 100%;
            width: 100%;
            z-index: 99;
            color: #fff;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            padding-left: 112px;

            .logo {
                width: 113px;
                margin-bottom: 20px;
            }

            .title {
                font-size: 45px;
                font-weight: 500;
                opacity: 0.9;
            }

            .text {
                font-family: Noto Sans SC;
                font-size: 30px;
                font-weight: 500;
                line-height: 50px;
                opacity: 0.9;

            }
        }
    }

    .right {
        flex: 1;
        height: 100%;
        padding: 26px 60px 45px 60px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .tabs {
            display: flex;
            align-items: center;
            font-size: 16px;
            color: rgba(0, 0, 0, 0.5);
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);

            .item {
                flex: 1;
                text-align: center;
                padding-bottom: 10px;
                cursor: pointer;
                margin-bottom: -1px;
                font-weight: 500;
            }

            .active {
                color: rgb(67, 87, 255);
                border-bottom: 2px rgb(67, 87, 255) solid;
            }
        }

        .form {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;

            :deep(.el-input-group__prepend) {
                width: 46px;
                max-width: 46px;
                padding: 0 15px;
                z-index: 9;
            }

            .item {
                position: relative;
                margin-bottom: 30px;

                .icon {
                    position: absolute;
                    left: 2px;
                    width: 46px;
                    top: 1px;
                    height: 38px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    z-index: 99;
                    border-right: 1px solid rgba(0, 0, 0, 0.1);
                    background: none;
                }

                :deep(.el-input) {
                    // z-index: 99;
                }

                :deep(.el-form-item__content) {
                    display: flex;
                    align-items: center;
                    position: relative;
                    z-index: 999;
                }


                :deep(.el-input__inner) {
                    padding-left: 60px;
                    z-index: 99;
                    background: rgb(247, 247, 247);
                    color: rgb(0, 0, 0);
                    font-family: Noto Sans SC;
                    font-size: 14px;

                    &:focus {
                        background: #fff;
                        border-color: rgb(69, 79, 255) !important;
                    }
                }


                .input-code {
                    width: 250px;
                }

                .button-code {
                    padding: 0;
                    width: 90px;
                    height: 40px;
                    margin-left: 10px;
                    /* 浅浅灰 */
                    background: rgb(247, 247, 247);
                    /* 主色 */
                    border: 1px solid rgb(69, 79, 255);
                    border-radius: 6px;
                    color: rgb(69, 79, 255);
                    font-size: 12px;
                    font-weight: 400;
                    font-family: Noto Sans SC;

                    &:hover {
                        opacity: .8;
                    }
                }

                .el-button.is-disabled {
                    color: #C0C4CC;
                    cursor: not-allowed;
                    background-image: none;
                    background-color: #FFF;
                    border-color: #EBEEF5;
                }
            }
        }

        .is-disabled {
            background: rgb(239, 241, 244);
            color: #9f9b9b;
            border: none;
        }

        .buttom-submit {
            width: 350px;
            height: 52px;
            border: none;
            position: relative;
            cursor: pointer;
            margin-top: 120px;
            color: rgb(255, 255, 255);
            font-family: Noto Sans SC;
            font-size: 20px;
            font-weight: 500;
            border-radius: 40px;
            background: linear-gradient(to right, rgba(69, 79, 255, 1) 0%, rgba(31, 209, 250, 1) 100%);
            margin-bottom: 20px;

            &:hover {
                opacity: 0.8;
            }

            &::before {
                content: '';
                display: block;
                position: absolute;
                width: 328.14px;
                height: 10px;
                border-radius: 40px;
                bottom: -5px;
                background: linear-gradient(to right, rgba(105, 91, 229, 1) 0%, rgba(31, 188, 250, 1) 100%);
                filter: blur(18px);

            }
        }

        .tip {
            margin-top: 0px;
            color: rgba(0, 0, 0, 0.5);
            font-size: 12px;
            z-index: 99;
            cursor: pointer;
            text-align: center;

            &:hover {
                opacity: .8;
            }
        }
    }
}

.box-tip {
    height: 269px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .title {
        color: rgb(0, 0, 0);
        font-family: Noto Sans SC;
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 20px;
    }

    .close {
        position: absolute;
        z-index: 999;
        right: 10px;
        top: 10px;
        cursor: pointer;

        &:hover {
            scale: 1.05;
        }
    }

    .text {
        display: flex;
        align-items: center;
        height: 35px;
        background: rgb(247, 247, 247);
        font-size: 14px;

        border-radius: 8px;
        padding: 0 14px;

        img {
            width: 27px;
            height: 27px;
        }

        p {
            color: rgb(0, 0, 0);
            font-family: Noto Sans SC;
            margin-left: 10px;
        }

        span {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.5)
        }
    }
}
</style>

<style lang="scss">
.container-login {
    border-radius: 20px !important;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .el-dialog__header {
        display: none !important;
    }

    .el-dialog__body {
        padding: 0;
        flex: 1;
        overflow: auto;
    }
}

.tip-dialog {

    .el-dialog {
        border-radius: 10px !important;
        overflow: hidden;
    }

    .el-dialog__header {
        display: none !important;
    }

    .el-dialog__body {
        padding: 0;
    }
}


.forget {
    font-size: 12px;
    color: #666;
    text-align: right;
    cursor: pointer;

    &:hover {
        opacity: .7;
    }
}

.box-form {
    .el-input-group__prepend {
        background: rgb(239, 241, 244);
    }

    .el-input__inner {
        background: rgb(239, 241, 244);
    }

}

// .el-dialog {
//     // display: flex;
//     // flex-direction: column;
//     // margin: 0 !important;
//     // position: absolute;
//     // top: 50%;
//     // left: 50%;
//     // transform: translate(-50%, -50%);
// }

// .el-dialog .el-dialog__body {
//     flex: 1;
//     overflow: auto;
// }
</style>
