import Clipboard from "clipboard"
import Vue from "vue"

function clipboardSuccess() {
  Vue.prototype.$message.success("复制成功")
}

function clipboardError() {
  Vue.prototype.$message.error("复制失败")
}

export function copy(text, event, onSuccess, onError) {
  event = event || {}
  const clipboard = new Clipboard(event.target, {
    text: () => text
  })
  clipboard.on('success', () => {
    onSuccess ? onSuccess() : clipboardSuccess()
    clipboard.off('error')
    clipboard.off('success')
    clipboard.destroy()
  })
  clipboard.on('error', () => {
    onSuccess ? onError() : clipboardError()
    clipboard.off('error')
    clipboard.off('success')
    clipboard.destroy()
  })
  clipboard.onClick(event)
}