<template>
    <div class="item-nodeAnimate" ref="nodeAnimate" :style="styles">
        <DecorationModuleCode :content="detail.config" v-if="detail.imageType === 'diy'"></DecorationModuleCode>
        <img v-else :src="detail.bI" :style="{ width: styles.width, height: styles.height }" alt="">
    </div>
</template>

<script>
const DecorationModuleCode = () => import('@/components/decorationModuleCode/index.vue')
import anime from 'animejs/lib/anime.js';
export default {
    name: "NodeAnimate",
    components: {
        DecorationModuleCode
    },
    props: {
        detail: {
            type: Object,
            default: {},
            required: true,
        }
    },
    data() {
        return {
            styles: {
                position: 'absolute',
                width: `${this.detail.width}px`,
                height: `${this.detail.height}px`,
                left: `${this.detail.left}px`,
                top: `${this.detail.top}px`,
                opacity: `${this.detail.opacity}`,
                borderRadius: `${this.detail.bR}px`,
                transform: '',
                // rotate:'',
            }
        }
    },
    created() {
        // this.init()
    },
    mounted() {
        this.createdAnimation()
    },
    methods: {
        createdAnimation() {
            let keyframes = []
            this.detail.list.forEach(item => {
                let params = {
                    duration: item.td ? Number(item.td) * 1000 : 3000,
                    easing: item.ttf || null,
                    endDelay: item.stopTd ? Number(item.stopTd) * 1000 : 0
                }
                let { tx = 0, txe = 0, tye = 0, ty = 0, tss = 1, tse = 1, ops = 1, ope = 1, ttr = 0, ttf = 0, antype = 0, transxs = 0, transxe = 0, transys = 0, transye = 0, num = 0 } = item;
                const keys = Object.keys(item.an) // 'txy', 'ts', 'opa', 'tr', 'tx3d', 'ty3d'
                keys.forEach(key => {
                    switch (key) {
                        case 'txy':
                            if (item.an[key]) {
                                params.translateX = txe
                                params.translateY = tye
                            }
                            break;
                        case 'ts':
                            if (item.an[key]) {
                                params.scale = tse
                            }
                            break;
                        case 'opa':
                            if (item.an[key]) {
                                params.opacity = ope
                            }
                            break;
                        case 'tr':
                            if (item.an[key]) {
                                params.rotate = ttr
                            }
                            break;
                        case 'tx3d':
                            if (item.an[key]) {
                                params.rotateZ = transxe
                            }
                            break;
                        case 'ty3d':
                            if (item.an[key]) {
                                params.rotateY = transye
                            }
                            break;
                    }
                })

                keyframes.push(params)
            })
            const myAnimation = anime({
                targets: [this.$refs.nodeAnimate,],
                keyframes: keyframes,
                loop: this.detail.num || true,
                delay: this.detail.delay ? Number(this.detail.delay) * 1000 : 0, // 停留时间
            });
            console.log(keyframes, 'keyframes')
        },

        init() {
            const data = this.detail.list[0]
            let styles = this.styles
            let { tx = 0, ty = 0, tss = 1, tse = 1, ops = 1, ope = 1, ttr = 0, ttf = 0, antype = 0, transxs = 0, transxe = 0, transys = 0, transye = 0, num = 0 } = data;

            const keys = Object.keys(data.an) // 'txy', 'ts', 'opa', 'tr', 'tx3d', 'ty3d'
            keys.forEach(key => {
                switch (key) {
                    case 'txy':
                        if (data.an[key]) {
                            styles.translateX = tx
                            styles.translateY = ty
                        }
                        break;
                    case 'ts':
                        if (data.an[key]) {
                            styles.scale = tss
                        }
                        break;
                    case 'opa':
                        if (data.an[key]) {
                            styles.opacity = ops
                        }
                        break;
                    case 'tr':
                        if (data.an[key]) {
                            styles.rotate = ttr
                        }
                        break;
                    case 'tx3d':
                        if (data.an[key]) {
                            styles.transform = `${styles.transform} rotateX(${transxs}deg)`
                        }
                        break;
                    case 'ty3d':
                        if (data.an[key]) {
                            styles.transform = `${styles.transform} rotateY(${transys}deg)`
                        }
                        break;
                }
            })
            this.styles = styles
        },
    },
}
</script>

<style lang="scss" scoped>
.container-nodeAnimate {
    position: absolute;

    img {
        width: 100%;
        height: 100%;
    }
}
</style>