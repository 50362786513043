<template>
    <el-dialog :visible.sync="dialogVisible" custom-class="container-notice" title="Tips" width="800px">
        <div class="box">
            <div class="left">
                <div class="title">
                    <img class="icon" src="../../assets/images/header/icon-notice.png" alt="">
                    <p>系统公告</p>
                </div>
                <ul class="list notice-list">
                    <li class="item" v-for="(item, index)  in noticeList" :key="item.noticeId"
                        :class="[activeNoticeIndex === index ? 'item-new' : '']" @click="onViewNoticeItem(index)">
                        <div class="item-header">
                            <p class="item-title"
                                :class="[!item.userId & activeNoticeIndex !== index ? 'item-title-new' : '']">
                                {{ item.title }}
                            </p>
                            <p class="item-time">{{ item.releaseTime }}</p>
                        </div>
                        <div class="item-text" v-html="item.content"></div>
                    </li>
                </ul>
            </div>
            <div class="content">
                <div class="top">
                    <p class="name">{{noticeList[activeNoticeIndex].title}}</p>
                    <p class="time">{{ noticeList[activeNoticeIndex].releaseTime }}</p>
                </div>
                <div class="detail">

                    <div class="text" v-html="noticeList[activeNoticeIndex].content">

                    </div>
                </div>
            </div>
            <Close @onClose="onClose" style="top:10px;right:10px"></Close>
        </div>
    </el-dialog>
</template>

<script>
import { saveUserBrowseData } from '@/api/common'
import Close from '@/components/close'
export default {
    components: {
        Close
    },
    props: {
        dialogVisible: {
            type: Boolean,
            default: true,
            required: true,
        },
        noticeList: {
            type: Array,
            default: [],
            required: true,
        },
        activeNoticeIndex: {
            type: Number,
            default: 0,
            required: true,
        }
    },
    methods: {
        onClose() {
            this.$emit('update:activeNoticeIndex', 0)
            this.$emit('update:dialogVisible', false);
        },
        onViewNoticeItem(index) {
            this.$emit('update:activeNoticeIndex', index)
            saveUserBrowseData({
                typeId:16,
                spmId:this.noticeList[index].noticeId
            })
            this.$parent.saveReadRecord()
        },
    }
}
</script>

<style lang="scss" scoped>
.box {
    height: 100%;
    background: #fff;
    display: flex;

    .left {
        flex: 1;
        display: flex;
        flex-direction: column;

        .title {
            color: rgb(0, 0, 0);
            height: 40px;
            display: flex;
            align-items: center;
            padding: 0 16px;
            font-size: 14px;
            font-weight: 500;
            height: 50px;
            border-bottom: 1px solid rgb(229, 229, 229);
            font-family: Noto Sans SC;
            font-weight: 500;

            .icon {
                width: 22px;
                margin-right: 8px;
            }
        }

    }

    .close {
        position: absolute;
        top: 14px;
        right: 14px;
        cursor: pointer;
    }

    .content {
        flex: 1;
        display: flex;
        flex-direction: column;

        .top {
            height: 50px;
            display: flex;
            align-items: center;
            // justify-content: space-between;
            border-bottom: 1px solid rgb(229, 229, 229);
            font-family: Noto Sans SC;
            font-weight: 500;

            .name {
                color: #000;
                color: rgb(0, 0, 0);
                font-size: 14px;
                padding-left: 20px;
                line-height: 0;

            }

            .time {
                color: rgba(0, 0, 0, 0.5);
                font-size: 12px;
                margin-left: 10px;
                line-height: 0;
            }
        }

        .detail {
            width: 530px;
            flex: 1;
            padding: 0 20px;

            :deep(img) {
                max-width: 100% !important;
            }

            .text {
                padding: 28px 0;
            }
        }
    }

    .list {
        flex: 1;
        overflow-y: auto;
        padding: 10px;
        // padding: 0px 0px 0px 0px;

        &::-webkit-scrollbar {

            width: 4px;
            background: #fff;

        }

        &::-webkit-scrollbar-thumb {
            background: rgb(229, 229, 229);
            border-radius: 6px;
        }

        .item-new {
            background: rgb(247, 247, 247);
        }

        .item {
            height: 85px;
            padding: 0 14px;
            font-size: 12px;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            cursor: pointer;
            padding: 0 20px;

            &:hover {
                background: rgb(247, 247, 247);
            }

            .item-header {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .item-title {
                    color: rgb(0, 0, 0);
                    position: relative;
                    padding-left: 8px;
                    width: 65%;
                    white-space: nowrap; //禁止换行
                    overflow: hidden; //超出隐藏
                    text-overflow: ellipsis //显示省略号
                }

                .item-title-new::after {
                    content: '';
                    position: absolute;
                    width: 7px;
                    height: 7px;
                    background: rgb(255, 60, 57);
                    border-radius: 10px;
                    border-radius: 50%;
                    left: 0;
                    top: 50%;
                    margin-top: -3.5px;
                }
                .item-time {
                    color: rgb(106, 106, 106);
                }
            }

            .item-text {
                text-align: left;
                color: rgb(139, 139, 139);
                padding-left: 8px;
                margin-top: 8px;
                display: -webkit-box;
                /**对象作为伸缩盒子模型展示**/
                -webkit-box-orient: vertical;
                /**设置或检索伸缩盒子对象的子元素的排列方式**/
                -webkit-line-clamp: 2;
                /**显示的行数**/
                overflow: hidden;
            }
        }
    }
}
</style>

<style lang="scss">
.container-notice {
    border-radius: 20px !important;
    width: 800px;
    height: 550px;
    position: relative;
    overflow: hidden;

    .el-dialog__header {
        display: none !important;
    }

    .el-dialog__body {
        padding: 0;
    }

    img {
        max-width: 100%;
    }

    video {
        max-width: 100%;
    }

}
</style>