<template>
    <div class="item-imgan" ref="imgan" :style="styles">
        <DecorationModuleCode :content="detail.config" v-if="detail.imageType === 'diy'"></DecorationModuleCode>
        <img v-else :src="detail.bI" :style="{ width: styles.width, height: styles.height }" alt="">
    </div>
</template>

<script>
// 单图动画
const DecorationModuleCode = () => import('@/components/decorationModuleCode/index.vue')
import anime from 'animejs/lib/anime.js';
export default {
    components: {
        DecorationModuleCode
    },
    props: {
        detail: {
            type: Object,
            default: {},
            required: true,
        }
    },

    data() {
        return {
            startTrans: '',
            endTrans: '',
            styles: {
                position: 'absolute',
                width: `${this.detail.width}px`,
                height: `${this.detail.height}px`,
                left: `${this.detail.left}px`,
                top: `${this.detail.top}px`,
                opacity: `${this.detail.opacity}`,
                borderRadius: `${this.detail.bR}px`,
                transform: '',
                // rotate:'',
            }
        }
    },
    created() {
        this.init()
    },
    mounted() {
        this.createdAnimation()
    },
    methods: {
        createdAnimation() {
            const data = this.detail
            let params = {
                loop: data.times || true,
                delay: data.delay ? Number(data.delay) * 1000 : 0,
                duration: data.td ? Number(data.td) * 1000 : 1000,
                // easing: data.ttf || 'linear',
                // direction: 'normal',
            }
            let { tx = 0, ty = 0, tss = 1, tse = 1, ops = 1, ope = 1, ttr = 0, ttf = 0, antype = 0, transxs = 0, transxe = 0, transys = 0, transye = 0, num = 0 } = data;
            const keys = Object.keys(data.an) // 'txy', 'ts', 'opa', 'tr', 'tx3d', 'ty3d'
            keys.forEach(key => {
                switch (key) {
                    case 'txy':
                        if (data.an[key]) {
                            params.translateX = { value: data.antype === 'fadeIn' ? `-${tx}` : tx, }
                            params.translateY = { value: data.antype === 'fadeIn' ? `-${ty}` : ty }
                        }
                        break;
                    case 'ts':
                        if (data.an[key]) {
                            params.scale = tse
                        }
                        break;
                    case 'opa':
                        if (data.an[key]) {
                            params.opacity = ope
                        }
                        break;
                    case 'tr':
                        if (data.an[key]) {
                            params.rotate = ttr
                        }
                        break;
                    case 'tx3d':
                        if (data.an[key]) {
                            params.rotateZ = transxe
                        }
                        break;
                    case 'ty3d':
                        if (data.an[key]) {
                            params.rotateY = transye
                        }
                        break;
                }
            })
            console.log(params, 'params')
            const myAnimation = anime({
                targets: [this.$refs.imgan],
                ...params
            });
        },
        init() {
            const data = this.detail
            let styles = this.styles
            let { tx = 0, ty = 0, tss = 1, tse = 1, ops = 1, ope = 1, ttr = 0, ttf = 0, antype = 0, transxs = 0, transxe = 0, transys = 0, transye = 0, num = 0 } = data;

            const keys = Object.keys(data.an) // 'txy', 'ts', 'opa', 'tr', 'tx3d', 'ty3d'
            keys.forEach(key => {
                switch (key) {
                    case 'txy':
                        if (data.an[key]) {
                            //移入效果
                            if (data.antype === 'fadeIn') {
                                styles.translateX = tx
                                styles.translateY = ty
                            }
                        }
                        break;
                    case 'ts':
                        if (data.an[key]) {
                            styles.scale = tss
                        }
                        break;
                    case 'opa':
                        if (data.an[key]) {
                            styles.opacity = ops
                        }
                        break;
                    case 'tr':
                        if (data.an[key]) {
                            // styles.rotate = ttr
                        }
                        break;
                    case 'tx3d':
                        if (data.an[key]) {
                            styles.transform = `${styles.transform} rotateX(${transxs}deg)`
                        }
                        break;
                    case 'ty3d':
                        if (data.an[key]) {
                            styles.transform = `${styles.transform} rotateY(${transys}deg)`
                        }
                        break;
                }
            })
            this.styles = styles
        },
    },
}
</script>

<style lang="scss" scoped>

</style>