<template>
    <div class="item-dialog">
        <div class="" :style="styles" @click="onOpenDialog">
            <DecorationModuleCode :content="detail"></DecorationModuleCode>
        </div>
        <div class="content" v-if="visible">
            <div class="mask" :style="maskStyles"></div>
            <DecorationModuleCode :visible.sync='visible' style="overflow: hidden; background:#fff;position:relative"
                :content="detail.config.content"></DecorationModuleCode>
        </div>
    </div>
</template>
<script>
// 图片
const DecorationModuleCode = () => import('@/components/decorationModuleCode/index.vue')
export default {
    name: 'Dialog',
    components: {
        DecorationModuleCode
    },
    props: {
        detail: {
            type: Object,
            default: {},
            required: true,
        }
    },
    data() {
        return {
            styles: {
                position: 'absolute',
                width: `${this.detail.width}px`,
                height: `${this.detail.height}px`,
                left: `${this.detail.left}px`,
                top: `${this.detail.top}px`,
                opacity: `${this.detail.opacity}`,
                borderRadius: `${this.detail.bR}px`,
            },
            maskStyles: {
                background: `${this.detail.config.layer.bC}`,
                opacity: `${this.detail.config.layer.opacity}`,
            },
            visible: 1,
        }
    },
    provide() {
        return {
            visible: this.visible,
            onClose: this.onClose()
        }
    },

    methods: {
        onNavigation() {
            if (this.detail.url) {
                window.open(this.detail.url, '_blank')
            }
        },
        onOpenDialog() {
            this.visible = true
        },
        onClose() {
            this.visible = false
        }
    },
}
</script>

<style lang="scss" scoped>
.content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;    
        height: 100%;
    }
}
</style>