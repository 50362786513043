<template>
	<div :style="styles">
		<template v-for="(item, index) in content.components">
			<!-- 动画 -->
			<Imgan :detail="item" v-if="item.type === 'imgan'" :key="index"></Imgan>
			<Singlean :detail="item" v-if="item.type === 'singlean'" :key="index"></Singlean>
			<Systeman :detail="item" v-if="item.type === 'systeman'" :key="index"></Systeman>
			<Manyan :detail="item" v-if="item.type === 'manyan'" :key="index"></Manyan>
			<NodeSnimate :detail="item" v-if="item.type === 'nodeAnimate'" :key="index"></NodeSnimate>
			<Framean :detail="item" v-if="item.type === 'framean'" :key="index"></Framean>

			<!-- 组件工具 -->
			<ImgA :detail="item" v-if="item.type === 'img'" :key="index"></ImgA>
			<WeexImg :detail="item" v-if="item.type === 'weexImg'" :key="index"></WeexImg>
			<Area :detail="item" v-if="item.type === 'area'" :key="index"></Area>
			<TextA :detail="item" v-if="item.type === 'text'" :key="index"></TextA>
			<Longpicture :detail="item" v-if="item.type === 'longpicture'" :key="index"></Longpicture>
			<VideoA :detail="item" v-if="item.type === 'video'" :key="index"></VideoA>
			<Weexcountdown :detail="item" v-if="item.type === 'weexcountdown'" :key="index"></Weexcountdown>
			<Cart :detail="item" v-if="item.type === 'cart'" :key="index"></Cart>
			<Wangwang :detail="item" v-if="item.type === 'wangwang'" :key="index"></Wangwang>
			<Imgcrop :detail="item" v-if="item.type === 'imgcrop'" :key="index"></Imgcrop>
			<Swipe :detail="item" v-if="item.type === 'swipe'" :key="index"></Swipe>
			<Diyswipe :detail="item" v-if="item.type === 'diyswipe'" :key="index"></Diyswipe>
			<Seamlessan :detail="item" v-if="item.type === 'seamlessan'" :key="index"></Seamlessan>
			<Fdswipe :detail="item" v-if="item.type === 'fdswipe'" :key="index"></Fdswipe>
			<Dialog :detail="item" v-if="item.type === 'dialog'" :key="index"></Dialog>
			<Scroll :detail="item" v-if="item.type === 'scroll'" :key="index"></Scroll>
			<PopupClosed v-bind="$attrs" v-on="$listeners" :detail="item" v-if="item.type === 'popup_closed'"
				:key="index"></PopupClosed>
		</template>
	</div>
</template> 

<script>
import Imgan from './animations/imgan'
import Systeman from './animations/systeman'
import Singlean from './animations/singlean'
import Framean from './animations/framean'
import Manyan from './animations/manyan'
import NodeSnimate from './animations/nodeAnimate'
import ImgA from './components/img'
import WeexImg from './components/weexImg'
import Area from './components/area'
import TextA from './components/text'
import Longpicture from './components/longpicture'
import VideoA from './components/video'
import Weexcountdown from './components/weexcountdown'
import Cart from './components/cart'
import Wangwang from './components/wangwang'
import Imgcrop from './components/imgcrop'
import Swipe from './components/swipe'
import Diyswipe from './components/diyswipe'
import Seamlessan from './components/seamlessan'
import Fdswipe from './components/fdswipe'
import Dialog from './components/dialog'
import Scroll from './components/scroll'
import PopupClosed from './components/popupClosed'

export default {
	name: 'DecorationModuleCode',
	components: {
		Imgan,
		Systeman,
		Singlean,
		Framean,
		Manyan,
		NodeSnimate,
		ImgA,
		WeexImg,
		Area,
		TextA,
		Longpicture,
		VideoA,
		Weexcountdown,
		Cart,
		Wangwang,
		Imgcrop,
		Swipe,
		Diyswipe,
		Seamlessan,
		Fdswipe,
		Dialog,
		Scroll,
		PopupClosed
	},
	props: {
		content: {
			type: Object,
			default: () => {
				return {
					"width": "726",
					"height": "968",
					"bC": "",
					"name": "自定义模块",
					"moduleType": "diyModule",
					"components": [{
						"type": "img",
						"name": "图片4",
						"width": 726,
						"height": 968,
						"left": 0,
						"top": 0,
						"src": "https://img.alicdn.com/imgextra/i4/907349826/O1CN01AjDXmx2MSMW6M88TU_!!907349826.png"
					}, {
						"type": "text",
						"name": "文字6",
						"width": "696",
						"height": "330",
						"left": 16,
						"top": "240",
						"size": 24,
						"color": "",
						"content": "",
						"italic": "normal",
						"through": "none",
						"align": "left",
						"bC": "#ffffff"
					}, {
						"type": "diyswipe",
						"name": "圆点轮播3",
						"width": "696",
						"height": 330,
						"left": "16",
						"top": "240",
						"auto": "true",
						"playtime": "7",
						"ispagination": "true",
						"ver": "0",
						"arrow": {
							"switch": "false",
							"width": 58,
							"height": 58,
							"l": {
								"left": 0,
								"top": 153,
								"img": "https://img.alicdn.com/imgextra/i4/1734086020/TB2fdGvkVXXXXanXpXXXXXXXXXX-1734086020.png"
							},
							"r": {
								"left": 692,
								"top": 159,
								"img": "https://img.alicdn.com/imgextra/i2/1734086020/TB2xruLkVXXXXciXXXXXXXXXXXX-1734086020.png"
							}
						},
						"config": {
							"width": 750,
							"height": 356,
							"left": 0,
							"top": 0
						},
						"pagination": {
							"old": {
								"color": "rgba(0,0,0,0.6)",
								"w": "16",
								"h": "16",
								"br": "8"
							},
							"active": {
								"color": "#feedc7",
								"w": "16",
								"h": "16",
								"br": "8"
							},
							"img_width": "80",
							"img_height": "80",
							"type": "color",
							"position": "bc",
							"spacing": "8",
							"bottom_spacing": "30",
							"other_spacing": "20"
						},
						"banner": [{
							"url": "",
							"bC": "",
							"components": [{
								"type": "imgan",
								"lock": "open",
								"display": "block",
								"name": "单图动画1",
								"width": 213,
								"height": 211,
								"left": 453,
								"top": 51,
								"tx": "200",
								"ty": 0,
								"ops": "0",
								"ope": "1",
								"tss": "",
								"tse": "",
								"td": "0.5",
								"ttf": "ease",
								"ttr": "",
								"delay": "",
								"antype": "fadeIn",
								"bI": "https://img.alicdn.com/imgextra/i3/907349826/O1CN01TiuNbW2MSMWALWc0z_!!907349826.png",
								"config": {
									"bC": "",
									"bI": "",
									"components": []
								},
								"an": {
									"txy": 1,
									"opa": true
								},
								"style": {
									"borderStatus": "closed"
								},
								"ttfname": "逐渐变慢",
								"url": "https://item.taobao.com/item.htm?id=17401490322"
							}, {
								"type": "imgan",
								"lock": "open",
								"display": "block",
								"name": "单图动画2",
								"width": 281,
								"height": 126,
								"left": 145,
								"top": 117,
								"tx": "-300",
								"ty": 0,
								"ops": "0",
								"ope": "1",
								"tss": "",
								"tse": "",
								"td": "0.5",
								"ttf": "ease",
								"ttr": "",
								"delay": "",
								"antype": "fadeIn",
								"bI": "https://img.alicdn.com/imgextra/i3/907349826/O1CN01BOFGI82MSMVsRQvtc_!!907349826.png",
								"config": {
									"bC": "",
									"bI": "",
									"components": []
								},
								"an": {
									"txy": 1,
									"opa": true
								},
								"style": {
									"borderStatus": "closed"
								},
								"ttfname": "逐渐变慢"
							}, {
								"type": "area",
								"lock": "open",
								"name": "热区3",
								"display": "block",
								"width": 344,
								"height": 166,
								"left": 96,
								"top": 101,
								"url": "https://item.taobao.com/item.htm?id=37147233894"
							}],
							"img": "",
							"left": "0",
							"top": "0",
							"tr_img": "https://img.alicdn.com/imgextra/i2/555657275/TB2wACJkz3z9KJjy0FmXXXiwXXa_!!555657275.jpg",
							"tr_active_img": "",
							"ruler": []
						}, {
							"url": "",
							"bC": "#f6f6f6",
							"components": [{
								"type": "imgan",
								"lock": "open",
								"display": "block",
								"name": "单图动画1",
								"width": 227,
								"height": 227,
								"left": 408,
								"top": 52,
								"tx": 0,
								"ty": "60",
								"ops": "0",
								"ope": "1",
								"tss": "",
								"tse": "",
								"td": "0.5",
								"ttf": "ease",
								"ttr": "",
								"delay": "0.2",
								"antype": "fadeIn",
								"bI": "https://img.alicdn.com/imgextra/i3/907349826/O1CN01mHEkUE2MSMW2c6V1I_!!907349826.png",
								"config": {
									"bC": "",
									"bI": "",
									"components": []
								},
								"an": {
									"txy": 1,
									"opa": true
								},
								"style": {
									"borderStatus": "closed"
								},
								"ttfname": "逐渐变慢",
								"url": "https://item.taobao.com/item.htm?id=17401490322"
							}, {
								"type": "imgan",
								"lock": "open",
								"display": "block",
								"name": "单图动画2",
								"width": 281,
								"height": 126,
								"left": 123,
								"top": 132,
								"tx": 0,
								"ty": "60",
								"ops": "0",
								"ope": "1",
								"tss": "",
								"tse": "",
								"td": "0.5",
								"ttf": "ease",
								"ttr": "",
								"delay": "",
								"antype": "fadeIn",
								"bI": "https://img.alicdn.com/imgextra/i3/907349826/O1CN01BOFGI82MSMVsRQvtc_!!907349826.png",
								"config": {
									"bC": "",
									"bI": "",
									"components": []
								},
								"an": {
									"txy": 1,
									"opa": true
								},
								"style": {
									"borderStatus": "closed"
								},
								"ttfname": "逐渐变慢"
							}, {
								"type": "area",
								"lock": "open",
								"name": "热区3",
								"display": "block",
								"width": 355,
								"height": 199,
								"left": 39,
								"top": 77,
								"url": "https://item.taobao.com/item.htm?id=523775858952"
							}],
							"img": "",
							"left": "100",
							"top": "0",
							"tr_img": "https://img.alicdn.com/imgextra/i2/555657275/TB2wACJkz3z9KJjy0FmXXXiwXXa_!!555657275.jpg",
							"tr_active_img": "",
							"ruler": []
						}],
						"index": 1,
						"edittime": "true",
						"cindex": 2
					}, {
						"type": "weexcountdown",
						"name": "倒计时4",
						"width": "726",
						"height": 95,
						"left": 0,
						"top": 145,
						"format": "dhms",
						"edittime": "true",
						"bI": "https://img.alicdn.com/imgextra/i3/907349826/O1CN014pqPiJ2MSMVDosCl1_!!907349826.png",
						"time": {
							"startTime": "2022-09-16 20:11:47",
							"endTime": "2022-09-30 20:11:47",
							"d": "00",
							"m": "00",
							"h": "00",
							"s": "00"
						},
						"d": {
							"size": "50",
							"color": "#000000",
							"fw": "",
							"w": 80,
							"h": 84,
							"l": 90,
							"t": 0,
							"spc": "6",
							"switch": "true",
							"weight": "700"
						},
						"h": {
							"size": "50",
							"color": "#000000",
							"spc": "6",
							"fw": "",
							"w": 82,
							"h": 87,
							"l": 232,
							"t": 0,
							"weight": "700"
						},
						"m": {
							"size": "50",
							"color": "#000000",
							"spc": "6",
							"fw": "",
							"w": 78,
							"h": 85,
							"l": 375,
							"t": 1,
							"weight": "700"
						},
						"s": {
							"size": "50",
							"color": "#000000",
							"spc": "6",
							"fw": "",
							"w": 80,
							"h": 87,
							"l": 514,
							"t": -1,
							"weight": "700"
						},
						"ms": {
							"size": "40",
							"color": "#f7f7f7",
							"spc": "3",
							"fw": "",
							"w": 80,
							"h": 100,
							"l": 630,
							"t": 131,
							"switch": "false"
						}
					}, {
						"type": "singlean",
						"name": "循环动画6",
						"width": 44,
						"height": 37,
						"left": 642,
						"top": 248,
						"route": "twoway",
						"txs": 0,
						"tys": 0,
						"txe": "-20",
						"tye": 0,
						"ops": "",
						"ope": "",
						"tss": "",
						"tse": "",
						"td": "0.5",
						"ttf": "linear",
						"ttr": "",
						"ttre": "",
						"an": {
							"txy": 1
						},
						"bI": "https://img.alicdn.com/imgextra/i1/907349826/O1CN01xH4f1Z2MSMWKL24lT_!!907349826.png",
						"img": {
							"w": 44,
							"h": 37
						}
					}, {
						"type": "singlean",
						"name": "循环动画7",
						"width": 200,
						"height": 37,
						"left": 512,
						"top": 525,
						"route": "twoway",
						"txs": 0,
						"tys": 0,
						"txe": "-20",
						"tye": 0,
						"ops": "",
						"ope": "",
						"tss": "",
						"tse": "",
						"td": "0.5",
						"ttf": "linear",
						"ttr": "",
						"ttre": "",
						"an": {
							"txy": 1
						},
						"bI": "https://img.alicdn.com/imgextra/i1/907349826/O1CN01QRiF5T2MSMWR5aQYl_!!907349826.png",
						"img": {
							"w": 200,
							"h": 37
						},
						"url": "https://item.taobao.com/item.htm?id=523775858952"
					}, {
						"type": "seamlessan",
						"name": "无缝循环7",
						"width": 726,
						"height": 58,
						"left": 0,
						"top": 298,
						"direction": "hor",
						"td": "",
						"diy": {
							"bC": "",
							"components": [{
								"type": "img",
								"lock": "open",
								"display": "block",
								"name": "图片1",
								"width": 670,
								"height": 58,
								"left": 7,
								"top": 0,
								"src": "https://img.alicdn.com/imgextra/i1/907349826/O1CN012ggSr92MSMOnm8SUL_!!907349826.png",
								"url": "https://item.taobao.com/item.htm?id=17401490322"
							}, {
								"type": "img",
								"lock": "open",
								"display": "block",
								"name": "图片3",
								"width": 670,
								"height": 50,
								"left": "600",
								"top": 0,
								"src": "https://img.alicdn.com/imgextra/i3/907349826/O1CN01jnySRI2MSMOhBogKD_!!907349826.png",
								"url": "https://item.taobao.com/item.htm?id=17401490322"
							}, {
								"type": "area",
								"lock": "open",
								"name": "热区3",
								"display": "block",
								"width": 242,
								"height": 48,
								"left": 9,
								"top": 4,
								"url": "https://item.taobao.com/item.htm?id=37147233894"
							}],
							"width": "1200",
							"height": 58,
							"ruler": []
						}
					}, {
						"type": "img",
						"name": "图片8",
						"width": 727,
						"height": 294,
						"left": 0,
						"top": 589,
						"src": "https://img.alicdn.com/imgextra/i2/907349826/O1CN01VUyKab2MSMThhKgfE_!!907349826.png",
						"url": "https://item.taobao.com/item.htm?id=523775858952",
						"an": {
							"txy": 1,
							"ts": 1,
							"opa": 1,
							"tr": 1,
							"tx3d": 1,
							"ty3d": 1
						}
					}, {
						"type": "singlean",
						"name": "循环动画10",
						"width": 53,
						"height": 52,
						"left": 627,
						"top": 821,
						"route": "oneway",
						"txs": 0,
						"tys": 0,
						"txe": "10",
						"tye": "-100",
						"ops": "",
						"ope": "",
						"tss": "",
						"tse": "",
						"td": "1",
						"ttf": "linear",
						"ttr": "",
						"ttre": "",
						"an": {
							"txy": 1
						},
						"bI": "https://img.alicdn.com/imgextra/i3/907349826/O1CN01O97YUc2MSMKGqul6G_!!907349826.png",
						"ttfname": "匀速",
						"delay": "1.2"
					}, {
						"type": "singlean",
						"name": "循环动画11",
						"width": 41,
						"height": 35,
						"left": 627,
						"top": 838,
						"route": "oneway",
						"txs": 0,
						"tys": 0,
						"txe": "-0",
						"tye": "-100",
						"ops": "",
						"ope": "",
						"tss": "",
						"tse": "",
						"td": "1",
						"ttf": "linear",
						"ttr": "",
						"ttre": "",
						"an": {
							"txy": 1
						},
						"bI": "https://img.alicdn.com/imgextra/i2/907349826/O1CN01ijjjMi2MSMMybO9GU_!!907349826.png",
						"delay": "0"
					}, {
						"type": "singlean",
						"name": "循环动画12",
						"width": 30,
						"height": 26,
						"left": 642,
						"top": 838,
						"route": "oneway",
						"txs": 0,
						"tys": 0,
						"txe": "-20",
						"tye": "-100",
						"ops": "",
						"ope": "",
						"tss": "",
						"tse": "",
						"td": "1",
						"ttf": "linear",
						"ttr": "",
						"ttre": "",
						"an": {
							"txy": 1
						},
						"bI": "https://img.alicdn.com/imgextra/i1/907349826/O1CN01wmvwdi2MSMOwlXgAS-907349826.png",
						"ttfname": "匀速",
						"delay": "0.8",
						"stopTd": "0"
					}, {
						"type": "singlean",
						"name": "循环动画13",
						"width": 20,
						"height": 20,
						"left": 652,
						"top": 844,
						"route": "oneway",
						"txs": 0,
						"tys": 0,
						"txe": "-10",
						"tye": "-100",
						"ops": "",
						"ope": "",
						"tss": "",
						"tse": "",
						"td": "1",
						"ttf": "linear",
						"ttr": "",
						"ttre": "",
						"an": {
							"txy": 1
						},
						"bI": "https://img.alicdn.com/imgextra/i3/907349826/O1CN01b97bnM2MSMUuQzmYB_!!907349826.png",
						"ttfname": "匀速",
						"delay": "0.6",
						"stopTd": ""
					}, {
						"type": "singlean",
						"name": "循环动画14",
						"width": 53,
						"height": 52,
						"left": 627,
						"top": 831,
						"route": "oneway",
						"txs": 0,
						"tys": 0,
						"txe": "20",
						"tye": "-100",
						"ops": "",
						"ope": "",
						"tss": "",
						"tse": "",
						"td": "1",
						"ttf": "linear",
						"ttr": "",
						"ttre": "",
						"an": {
							"txy": 1
						},
						"bI": "https://img.alicdn.com/imgextra/i3/907349826/O1CN01O97YUc2MSMKGqul6G_!!907349826.png",
						"ttfname": "匀速",
						"delay": "0.3",
						"stopTd": ""
					}, {
						"type": "img",
						"name": "图片9",
						"width": 62,
						"height": 62,
						"left": 627,
						"top": 821,
						"src": "https://img.alicdn.com/imgextra/i4/907349826/O1CN01KfqXpq2MSMU1MndH4_!!907349826.png",
						"an": {
							"txy": 1,
							"ts": 1,
							"opa": 1,
							"tr": 1,
							"tx3d": 1,
							"ty3d": 1
						}
					}, {
						"type": "area",
						"name": "热区15",
						"width": 602,
						"height": 125,
						"left": 82,
						"top": 6,
						"url": "https://item.taobao.com/item.htm?id=17401490322"
					}, {
						"type": "fdswipe",
						"name": "缩放轮播16",
						"width": 750,
						"height": "214",
						"left": -20,
						"top": 4,
						"auto": "true",
						"playtime": "7",
						"ispagination": "true",
						"ver": "0",
						"itw": "400",
						"itm": "0",
						"its": "0.8",
						"arrow": {
							"switch": "false",
							"width": 58,
							"height": 58,
							"l": {
								"left": 0,
								"top": 153,
								"img": "https://img.alicdn.com/imgextra/i4/1734086020/TB2fdGvkVXXXXanXpXXXXXXXXXX-1734086020.png"
							},
							"r": {
								"left": 692,
								"top": 159,
								"img": "https://img.alicdn.com/imgextra/i2/1734086020/TB2xruLkVXXXXciXXXXXXXXXXXX-1734086020.png"
							}
						},
						"config": {
							"width": 750,
							"height": 356,
							"left": 0,
							"top": 0
						},
						"pagination": {
							"old": {
								"color": "rgba(0,0,0,0.6)",
								"w": "16",
								"h": "16",
								"br": "8"
							},
							"active": {
								"color": "#feedc7",
								"w": "16",
								"h": "16",
								"br": "8"
							},
							"img_width": "80",
							"img_height": "80",
							"type": "color",
							"position": "bc",
							"spacing": "8",
							"bottom_spacing": "20",
							"other_spacing": "20"
						},
						"banner": [{
							"url": "",
							"bC": "",
							"components": [],
							"img": "https://img.alicdn.com/imgextra/i1/907349826/O1CN012MSMCw4sQN0sg51_!!907349826.jpg",
							"left": "0",
							"top": "0",
							"tr_img": "https://img.alicdn.com/imgextra/i2/555657275/TB2wACJkz3z9KJjy0FmXXXiwXXa_!!555657275.jpg",
							"tr_active_img": ""
						}, {
							"url": "",
							"bC": "",
							"components": [],
							"img": "https://img.alicdn.com/imgextra/i1/1734086020/TB2eUSNl0nJ8KJjSszdXXaxuFXa-1734086020.png",
							"left": "100",
							"top": "0",
							"tr_img": "https://img.alicdn.com/imgextra/i2/555657275/TB2wACJkz3z9KJjy0FmXXXiwXXa_!!555657275.jpg",
							"tr_active_img": ""
						}, {
							"url": "",
							"bC": "#744e4e",
							"conponents": [],
							"components": []
						}],
						"index": 1,
						"rotate": "0",
						"bR": "50"
					}, {
						"type": "bubbling",
						"name": "冒泡组件17",
						"width": 750,
						"height": 300,
						"left": -20,
						"top": 488,
						"config": {
							"marginY": "20"
						},
						"group": [{
							"bC": "",
							"components": [{
								"type": "img",
								"lock": "open",
								"display": "block",
								"name": "图片1",
								"width": 160,
								"height": 160,
								"left": 139,
								"top": 0,
								"src": "https://img.alicdn.com/imgextra/i1/1734086020/O1CN01WLEJwz1uLCxPZJ0Cm_!!1734086020.png"
							}],
							"height": 226,
							"ruler": []
						}],
						"index": 0,
						"cIndex": 16
					}, {
						"type": "framean",
						"name": "帧动画18",
						"width": 300,
						"height": 400,
						"left": 426,
						"top": 253,
						"src": "",
						"td": "",
						"stopTd": "",
						"config": {
							"totalWidth": 300,
							"totalHeight": 10000,
							"num": 25,
							"row": 25,
							"col": 1,
							"type": "auto"
						},
						"banner": [{
							"width": 300,
							"height": 800,
							"pic": "https://img.alicdn.com/imgextra/i2/907349826/O1CN01ixKWSD2MSMXANhJu0-907349826.png"
						}, {
							"width": 300,
							"height": 800,
							"pic": "https://img.alicdn.com/imgextra/i1/907349826/O1CN01bOsln62MSMXG0Ab4Y-907349826.png"
						}, {
							"width": 300,
							"height": 800,
							"pic": "https://img.alicdn.com/imgextra/i3/907349826/O1CN017HqBNw2MSMXLUQrJQ-907349826.png"
						}, {
							"width": 300,
							"height": 800,
							"pic": "https://img.alicdn.com/imgextra/i3/907349826/O1CN01bTDYiM2MSMXCZZHTo-907349826.png"
						}, {
							"width": 300,
							"height": 800,
							"pic": "https://img.alicdn.com/imgextra/i1/907349826/O1CN01PX4ypx2MSMXHaljBu-907349826.png"
						}, {
							"width": 300,
							"height": 800,
							"pic": "https://img.alicdn.com/imgextra/i1/907349826/O1CN01vvnXdZ2MSMX3V5NDh-907349826.png"
						}, {
							"width": 300,
							"height": 800,
							"pic": "https://img.alicdn.com/imgextra/i2/907349826/O1CN01JhqpJE2MSMXEl5A2l-907349826.png"
						}, {
							"width": 300,
							"height": 800,
							"pic": "https://img.alicdn.com/imgextra/i2/907349826/O1CN01O74lsm2MSMXJSAiLV-907349826.png"
						}, {
							"width": 300,
							"height": 800,
							"pic": "https://img.alicdn.com/imgextra/i2/907349826/O1CN0138UOpP2MSMXCimrkg-907349826.png"
						}, {
							"width": 300,
							"height": 800,
							"pic": "https://img.alicdn.com/imgextra/i4/907349826/O1CN01F2ilVM2MSMX9d3m0V-907349826.png"
						}, {
							"width": 300,
							"height": 800,
							"pic": "https://img.alicdn.com/imgextra/i3/907349826/O1CN014arzeO2MSMX3V5AjK-907349826.png"
						}, {
							"width": 300,
							"height": 800,
							"pic": "https://img.alicdn.com/imgextra/i3/907349826/O1CN01jOEWx12MSMXKdTmZc-907349826.png"
						}, {
							"width": 300,
							"height": 400,
							"pic": "https://img.alicdn.com/imgextra/i2/907349826/O1CN01f7W90w2MSMXDJk1sw-907349826.png"
						}]
					}],
					"uiSize": "750",
					"id": "3e449589-1e7d-4c85-8b39-7839a8b8377d",
					"ruler": []
				}
			},
			require: true
		}
	},
	data() {
		return {
			styles: {
				width: `${this.content.width}px`,
				height: `${this.content.height}px`,
				left: `${this.content.left}px`,
				top: `${this.content.top}px`,
				opacity: `${this.content.opacity}`,
				borderRadius: `${this.content.bR}px`,
			}
		}
	},

	created() {
		console.log(this.content, 'this.props')
	},

	methods: {

	},
}
</script>

<style lang="scss" scoped>
.container-code {
	// width: 750px;
	// height: 1000px;
	// background: #000;
	// margin: 0 auto;
	// position: relative;
}
</style>