<template>
    <div class="contaienr-case">
        <!-- <template v-if="caseList[0]">
            <el-skeleton :throttle="500" class="case-list" :loading="loading" animated :count="10">
                <template slot="template">
                    <div>
                        <el-skeleton-item variant="rect" style="height: 550px;" />
                        <el-skeleton-item variant="h3" style="width: 50%;" />
                        <el-skeleton-item variant="h3" style="width: 80%;" />
                    </div>
                </template>
            <CaseItem v-for="item in caseList" :detail="item" :key="item.caseId"></CaseItem>
            </el-skeleton>
            <Pagination :pagination.sync="pagination"></Pagination>
        </template> -->
        <template v-if="caseList[0]">
            <div class="case-list">
                <CaseItem v-for="item in caseList" :detail="item" :key="item.caseId"></CaseItem>
            </div>
            <Pagination :pagination.sync="pagination"></Pagination>
        </template>
        <el-empty class="empty" v-else description="暂无数据！"></el-empty>
    </div>
</template>

<script>
import CaseItem from './caseItem.vue'
import Pagination from '@/components/pagination'
export default {
    components: {
        CaseItem,
        Pagination
    },
    props: {
        caseList: {
            type: Array,
            default: [],
            required: true,
        },
        loading: {
            type: Boolean,
            default: true,
            required: false,
        },
        pagination: {
            type: Object,
            default: () => { return {} },
            required: true
        },
    },
    created() {
    },
    data() {
        return {
        }
    },
}
</script>

<style lang="scss" scoped>
.case-list {
    width: 100%;
    padding: 20px 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, 255px);
    grid-column-gap: 40px;
    grid-row-gap: 40px;
}

:deep(.el-skeleton) {
    width: 100%;
    padding: 20px 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, 255px);
    grid-column-gap: 40px;
    grid-row-gap: 40px;
}

.empty {
    height: 600px;
}
</style>