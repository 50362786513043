import Vue from "vue";
import Vuex from "vuex";
import router from "../router";
import { getUserInfo } from "@/api/common";
import { logOut } from "@/api/home";

Vue.use(Vuex);
const pinyin = require("js-pinyin");

export default new Vuex.Store({
  state: {
    // 0 未登录 1 普通 2 年框 后端返回普通用户0 年框1
    identity: JSON.parse(localStorage.getItem("userInfo"))
      ? JSON.parse(localStorage.getItem("userInfo")).level == 0
        ? 1
        : 2
      : 0,
    userInfo: JSON.parse(localStorage.getItem("userInfo")) || "",
    loginDialogVisible: JSON.parse(localStorage.getItem("userInfo"))
      ? false
      : true,
    search: "",
    loading: false,
    axiosCancelArr: [],
    feedbackVisible: true,
    showWindow: false,
    showShare: false,
    showSharePackage: false,
    shareData: {
      caseList: [],
      decorateList: [],
      customList: [],
    },
  },

  getters: {},

  mutations: {
    // 更新用户信息
    updateUserInfo(state, data) {
      state.userInfo = data;
      state.identity = data.level === 0 ? 1 : 2;
      window.qimoClientId = {
        userId: pinyin.getCamelChars(data.name), // 自定义用户的唯一id，不能传空字符串、null、中文，id由字母大小写、数字及'-'和 '_'组成
        priority: false, //是否优先进线设置，非必填，值为boolean类型的true或false
        nickName: data.name, //自定义用户昵称，定义用户昵称则userId必传
      };
      window.m7CardInfo = null;
      localStorage.setItem("userInfo", JSON.stringify(data));
    },

    //
    onShowShare(state, data) {
      state.showShare = data;
    },
    //
    onShowSharePackage(state, data) {
      state.showSharePackage = data;
    },
    onUpdateCaseList(state, data) {
      console.log(data, "data");
      state.shareData.caseList = data;
      console.log(this.state.shareData.caseList, "casc");
    },
    onUpdateDecorateList(state, data) {
      state.shareData.decorateList = data;
    },
    onUpdateCustomList(state, data) {
      state.shareData.customList = data;
    },

    // 退出登录
    signOut(state) {
      if (!localStorage.getItem("token")) {
        window.location.replace(
          `${process.env.VUE_APP_LOGINIP}?backUrl=${window.location.href}`
        );
        return
      }
      logOut().then((res) => {
        window.localStorage.clear();
        window.location.replace(
          `${process.env.VUE_APP_LOGINIP}?backUrl=${window.location.href}`
        );
      });
    },

    // 在线咨询
    onlineConsult(state, data) {
      window.m7CardInfo = {};
      window.qimoChatClick();
    },

    closeOnlineConsult() {
      window.m7CardInfo = {};
      window.qimosdk.closeChatBox();
    },

    onShowLogindialog(state, data) {
      state.loginDialogVisible = false;
    },

    // 更新用户信息
    async onUpdateUserInfo(state, data) {
      try {
        const res = await getUserInfo();
        state.userInfo = res.data;
        state.identity = res.data.level === 0 ? 1 : 2;
        localStorage.setItem("userInfo", JSON.stringify(res.data));
      } catch (e) {
        console.log(e);
      }
    },
    onSearch(state, data) {
      state.search = data;
    },
    onChangeShowWindow(state, data) {
      state.showWindow = data;
    },
    PUSH_CANCEL(state, cancel) {
      state.axiosCancelArr.push(cancel.cancelToken);
    },
    CLEAR_CANCEL(state) {
      state.axiosCancelArr.forEach((e) => {
        e && e();
      });
      state.axiosCancelArr = [];
    },
    // 网络加载
    CLOSE_LOADING(state) {
      state.loading = false;
    },
    OPEN_LOADING(state) {
      state.loading = true;
    },
  },
  actions: {
    pushCancel({ commit }, cancel) {
      commit("PUSH_CANCEL", cancel);
    },
    clearCancel({ commit }) {
      commit("CLEAR_CANCEL");
    },
  },
  modules: {},
});
