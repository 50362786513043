<template>
    <div class="box-close" @click="onClose">
        <i class="el-icon-close"
            :style="{ color: $store.state.identity === 2 ? 'rgb(234, 189, 110)' : 'rgba(67, 87, 255, 0.5)' }"></i>
    </div>
</template>

<script>
export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
            require: false,
        }
    },
    data() {
        return {}
    },
    methods: {
        onClose() {
            this.$emit('onClose')
        }
    },
}
</script>

<style lang="scss" scoped>
.box-close {
    position: absolute;
    top: 14px;
    right: 14px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    background: rgb(239, 241, 244);
    border-radius: 6px;

    .el-icon-close {
        font-weight: 800;
    }
}
</style>