<template>
    <div class="item-seamlessan" :style="styles">
        <vue-seamless-scroll :data="listData" :class-option="options">
            <template v-for="(item, index) in listData">
                <DecorationModuleCode :key="index" :content="detail.diy"></DecorationModuleCode>
            </template>
        </vue-seamless-scroll>
    </div>
</template>

<script>
// 无缝滚动
import vueSeamlessScroll from 'vue-seamless-scroll'
const DecorationModuleCode = () => import('@/components/decorationModuleCode/index.vue')

export default {
    name: 'Seamlessan',
    components: {
        vueSeamlessScroll,
        DecorationModuleCode
    },
    props: {
        detail: {
            type: Object,
            default: {},
            required: true,
        }
    },
    data() {
        return {
            styles: {
                position: 'absolute',
                width: `${this.detail.width}px`,
                height: `${this.detail.height}px`,
                left: `${this.detail.left}px`,
                top: `${this.detail.top}px`,
                opacity: `${this.detail.opacity}`,
                borderRadius: `${this.detail.bR}px`,
            },
            listData: [1, 2, 3, 4],
            options: {
                // step: 0.8,
                limitMoveNum: 1,
                direction: 2,
            },
        }

    },
    methods: {
        onNavigation() {
            if (this.detail.url) {
                window.open(this.detail.url, '_blank')
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.item-seamlessan {
    overflow: hidden;
}

// .ul-item{
//     display: flex;
// }
</style>