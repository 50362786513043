<template>
    <el-dialog :append-to-body="true" :visible.sync="onlineEditVisible" :before-close="onClose" :fullscreen="true" :close-on-click-modal="false"
        custom-class="container-edit-dialog" :show-close="true" width="80%">
        <div class="container-edit">
            <iframe ref="edit" class="edit" src="https://wxbadmin.guanmeikj.com/index.php?c=minicrx&m=crx"
                frameborder="0"></iframe>
        </div>
    </el-dialog>
</template>

<script>
import Close from '@/components/close'
export default {
    components: {
        Close
    },
    props: {
        activedCaseDetail: {
            type: Object,
            default: () => { return {} },
            required: false,
        },
        onlineEditVisible: {
            type: Boolean,
            default: false,
            required: true,
        },
    },
    data() {
        return {

        }
    },
    created() {
        const receiveMessage = (event) => {
            // console.log("receiveMessage", event)
            if (event.origin == 'https://wxbadmin.guanmeikj.com') {  //无线宝事件
                this.wxbEventFunc(event.data)
            }
        };
        window.addEventListener("message", receiveMessage);
    },
    mounted() {
        setTimeout(() => {
            let code = this.tool_encode(String(this.$store.userInfo.sellerId));
            let value = JSON.parse(this.activedCaseDetail.dynamicCode)
            console.log(value)
            const data = {
                code: code,
                data: value
            }
            console.log(data, 'data')
            this.$refs.edit.contentWindow.postMessage({ type: 'moduleEdit', data: data }, 'https://wxbadmin.guanmeikj.com/')
        }, 2000)
    },
    methods: {
        wxbEventFunc(data) {
            if (data.type == "complate") {

            }
        },
        tool_encode(_str) {
            var staticchars = "PXhw7UT1B0a9kQDKZsjIASmOezxYG4CHo5Jyfg2b8FLpEvRr3WtVnlqMidu6cN";
            var encodechars = "";
            for (var i = 0; i < _str.length; i++) {
                var num0 = staticchars.indexOf(_str[i]);
                if (num0 == -1) {
                    var code = _str[i];
                } else {
                    var code = staticchars[(num0 + 3) % 62];
                }
                var num1 = parseInt(Math.random() * 62, 10);
                var num2 = parseInt(Math.random() * 62, 10);
                encodechars += staticchars[num1] + code + staticchars[num2];
            }
            return encodechars;
        },
        onClose() {
            console.log('onclose')
            this.$emit('update:onlineEditVisible', false)
        },
    }
}
</script>

<style lang="scss" scoped>
.container-edit {
    width: 100%;
    height: 800px;
    position: relative;

    .edit {
        height: 100%;
        width: 100%;
    }

    .close {
        position: absolute;
        padding: 0 20px;
        width: 200px;
        height: 40px;
        right: 0;
        top: 0;
        background: #EEEEF1;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .icon {
            font-size: 28px;
            color: var(--colorB);
            cursor: pointer;

            &:hover {
                opacity: .8;
            }
        }
    }
}
</style>