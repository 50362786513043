<template>
    <div class="item-countdown" :style="styles">
        <div class="data" :style="itemStyles[0]">{{time[0]}}</div>
        <div class="data" :style="itemStyles[1]">{{time[1]}}</div>
        <div class="data" :style="itemStyles[2]">{{time[2]}}</div>
        <div class="data" :style="itemStyles[3]">{{time[3]}}</div>
        <div class="data" v-if="detail.ms.switch==='true'" :style="itemStyles[4]">{{time[4]}}</div>
    </div>
</template>

<script>

export default {
    name: 'Weexcountdown',
    props: {
        detail: {
            type: Object,
            default: {},    
            required: true,
        }
    },
    data() {
        return {
            time: ['00', '00', '00', '00', '00'],
            styles: {
                position: 'absolute',
                width: `${this.detail.width}px`,
                height: `${this.detail.height}px`,
                left: `${this.detail.left}px`,
                top: `${this.detail.top}px`,
                opacity: `${this.detail.opacity}`,
                borderRadius: `${this.detail.bR}px`,
                background: `url(${this.detail.bI}) no-repeat 0 0`,
                backgroundSize: '100%',
            },
            itemStyles: [],
        }
    },
    created() {
        this.countTime()

        if (this.detail.ms.switch === 'true') {
            this.countMSTime()
        }

        for (const key in this.detail) {
            switch (key) {
                case 'd':
                case 'h':
                case 'm':
                case 's':
                case 'ms':
                    this.itemStyles.push({
                        position: 'absolute',
                        width: `${this.detail[key].w}px`,
                        height: `${this.detail[key].h}px`,
                        left: `${this.detail[key].l}px`,
                        top: `${this.detail[key].t}px`,
                        fontSize: `${this.detail[key].size}px`,
                        color: `${this.detail[key].color}`,
                        letterSpacing: `${this.detail[key].spc}px`,
                        fontWeight: `${this.detail[key].weight ? this.detail[key].weight : 'normal'}`
                    })
                    break;
            }
        }

    },
    methods: {
        countTime() {
            //获取当前时间
            var date = new Date();
            var now = date.getTime();
            //设置截止时间
            var endDate = new Date(this.detail.time.endTime);
            var end = endDate.getTime();
            //时间差
            var leftTime = end - now;
            //定义变量 d,h,m,s保存倒计时的时间
            let d, h, m, s, ms = '00'
            if (leftTime >= 0) {
                d = Math.floor(leftTime / 1000 / 60 / 60 / 24).toString().padStart('2', 0);
                h = Math.floor(leftTime / 1000 / 60 / 60 % 24).toString().padStart('2', 0);
                m = Math.floor(leftTime / 1000 / 60 % 60).toString().padStart('2', 0);
                s = Math.floor(leftTime / 1000 % 60).toString().padStart('2', 0);
                ms = Math.floor(leftTime % 1000).toString().padStart('2', 0);
                this.time = [d, h, m, s]
            } else {
                this.time = ['00', '00', '00', '00', '00']
            }
            // console.log(this.time);
            //递归每秒调用countTime方法，显示动态时间效果
            setTimeout(this.countTime, 1000);
        },
        // 计算毫秒
        countMSTime() {
            //获取当前时间
            var date = new Date();
            var now = date.getTime();
            //设置截止时间
            var endDate = new Date(this.detail.time.endTime);
            var end = endDate.getTime();
            //时间差
            var leftTime = end - now;
            //定义变量 d,h,m,s保存倒计时的时间
            let d, h, m, s, ms = '00'
            if (leftTime >= 0) {
                ms = Math.floor(leftTime % 1000).toString().padStart('2', 0);
                this.time[4] =Math.ceil(ms/10) 
            }
            // console.log(this.time)
            this.$forceUpdate()
            setTimeout(this.countMSTime, 100);
        }
    },
}
</script>

<style lang="scss" scoped>
.item-countdown {
    .data {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>